import Stripe from 'stripe';

// https://docs.stripe.com/api/subscriptions/object
export function getPaymentIntentClientSecret(subscription: Stripe.Subscription): string | null {
  if (subscription.latest_invoice && typeof subscription.latest_invoice === 'object') {
    const invoice = subscription.latest_invoice as Stripe.Invoice;

    if (typeof invoice.payment_intent === 'object' && invoice.payment_intent)
      return invoice.payment_intent.client_secret;
  }
  return null;
}

export function getProductName(plan: Stripe.Plan): string | null {
  if (plan.product && typeof plan.product === 'object' && 'name' in plan.product) {
    return plan.product.name;
  }
  return null;
}

export function getProductID(plan: Stripe.Plan): string | null {
  if (plan.product && typeof plan.product === 'object') {
    return plan.product.id;
  }
  return null;
}

export function getCustomerName(customer: string | Stripe.Customer | Stripe.DeletedCustomer): string | null {
  if (typeof customer === 'object' && 'name' in customer) {
    return customer.name || null;
  }
  return null;
}