import { apiURL } from 'api/api';
import { GroupType } from 'types/group';

export interface GetGroupTypesResponse {
  groupTypes: GroupType[];
}

export interface GetGroupTypeResponse {
  groupType: GroupType;
}

export const getGroupTypesRoute = () => apiURL(`/group-types`);

export const getAllGroupTypesRoute = () => apiURL(`/group-types/all`);
export const getFeaturedGroupTypesRoute = () => apiURL(`/group-types/featured`);

export interface UpdateGroupTypeRequest {
  id: number;
  stripeProductID: string;
  name: string;
  trackedServerLimit: number | null;
  memberLimit: number | null;
  usdCost: string | undefined;
  isPublic: boolean;
  showAds: boolean;
  featured: boolean;
  trialPeriodInDays: number;
}

export const updateGroupTypeRoute = () => apiURL(`/group-types/update`);
