import { Image, Col, QRCode, Row } from 'antd';
import { BitcoinIcon, RailwayIcon } from 'assets/SVGIcons/Icons';
import ExternalLink from 'components/shared/ExternalLink';

export default function Support() {
  const bitcoinAddress = 'bc1pnh6agekj60g9nxtt7xtx95km7snyafsk78shwh6stw63wtzxrm3qgtdv7f';

  return (
    <Row className="content">
      <Col>
        <h1>Support Me!</h1>
        <p>
          Currently I'm the sole developer for this project. I do it because I love programming and
          DST. If you find this website useful and would like to support me you can do so in the
          following ways.
        </p>
        <div className="support">
          <h2>
            With Bitcoin <BitcoinIcon />
          </h2>
          <p>Bitcoin adddress (BIP86): {bitcoinAddress}</p>
          <QRCode size={256} iconSize={50} value={bitcoinAddress} icon="svgs/bitcoin-icon.svg" />
        </div>
        <div className="support">
          <h2>Ko-fi </h2>
          <Image
            className="clickable"
            width={250}
            src="/images/misc/kofi_button_red.png"
            preview={false}
          />
          <p>
            <i>coming soon</i>
          </p>
        </div>
        <div className="support">
          <h2>
            Railway <RailwayIcon />
          </h2>
          I currently host this application on Railway. If you're interested in hosting your own web
          application you can use render using my link and I'll get a $5 credit! You have to sign up
          for at least the hobby account in order for me to get the kickback.
          <p>
            <ExternalLink
              url="https://railway.app?referralCode=BB-8"
              text="https://railway.app?referralCode=BB-8"
            />
          </p>
        </div>
      </Col>
    </Row>
  );
}
