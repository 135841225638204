import React, { useState } from 'react';
import CustomTable, { CustomColumnType } from 'components/shared/CustomTable';
import { renderBoolean } from 'components/shared/TableRenders';
import { InfinityIcon } from 'assets/SVGIcons/Icons';
import GroupTypeModal from './Modals/GroupTypeModal';
import { GroupType } from 'types/group';
import { Button, Col, Row } from 'antd';

const GroupTypesTable: React.FC<{
  groupTypes: GroupType[];
  setGroupTypes: React.Dispatch<React.SetStateAction<GroupType[]>>;
}> = ({ groupTypes, setGroupTypes }) => {
  const [selectedGroupType, setSelectedGroupType] = useState<GroupType | null>(null);
  const [modalOpen, setModalOpen] = useState(false);

  const columns: CustomColumnType<GroupType>[] = [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      width: 200,
    },
    {
      title: 'Tracked Limit',
      key: 'tracked',
      dataIndex: 'trackedServerLimit',
      render: (limit: number | null) => (limit !== null ? limit : <InfinityIcon />),
      width: 100,
    },
    {
      title: 'Member Limit',
      key: 'tracked',
      dataIndex: 'memberLimit',
      render: (limit: number | null) => (limit !== null ? limit : <InfinityIcon />),
      width: 100,
    },
    {
      title: 'Cost',
      key: 'usdCost',
      dataIndex: 'usdCost',
      render: (record: string) => `$${Number(record).toLocaleString()} USD`,
      width: 100,
    },
    {
      title: 'Public',
      key: 'isPublic',
      dataIndex: 'isPublic',
      render: renderBoolean,
      width: 50,
    },
    {
      title: 'Show Ads',
      key: 'showAds',
      dataIndex: 'showAds',
      render: renderBoolean,
      width: 50,
    },
    {
      title: 'Featured',
      key: 'featured',
      dataIndex: 'featured',
      render: renderBoolean,
      width: 50,
    },
    {
      title: 'Trial period',
      key: 'trialPeriodInWeeks',
      dataIndex: 'trialPeriodInWeeks',
      render: (limit: number) => <>{limit} days</>,
      width: 100,
    },
  ];

  return (
    <>
      <Row className="action-btn" justify="end">
        <Col>
          <Button onClick={() => setModalOpen(true)}>New Group Type</Button>
        </Col>
      </Row>
      <CustomTable
        rowClassName="clickable"
        rowKey={(record: GroupType) => record.id}
        columns={columns}
        dataSource={groupTypes}
        onRow={(groupType: GroupType) => {
          return {
            onClick: () => {
              setSelectedGroupType(groupType);
              setModalOpen(true);
            },
          };
        }}
      />
      <GroupTypeModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        groupType={selectedGroupType}
        setGroupType={setSelectedGroupType}
        setGroupTypes={setGroupTypes}
      />
    </>
  );
};
export default GroupTypesTable;
