import { ReactNode, useContext, useEffect, useState } from 'react';
import { ErrorResponse } from '@remix-run/router';
import { UserContext } from 'context/UserContext';
import {
  Alert,
  Button,
  Col,
  Dropdown,
  MenuProps,
  message,
  Progress,
  Row,
  Table,
  Tooltip,
} from 'antd';
import { isErrorResponse, useAxiosAuth, useAxiosPublic } from 'api/api';
import CreateGroupModal from 'components/Groups/Modals/CreateGroupModal';
import { ColumnsType } from 'antd/es/table';
import { useAuthCheck } from 'hooks/use_auth_check';
import {
  getGroupRoute,
  GetGroupRouteResponse,
  getGroupRolesRoute,
  GetGroupRolesResponse,
  removeGroupMemberRoute,
  RemoveGroupMemberRequest,
} from 'services/groups';
import { Group, GroupMemberViewModel, GroupRole, GroupType } from 'types/group';
import { EllipsisOutlined, ExclamationCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { MenuInfo } from 'rc-menu/lib/interface';
import InviteMemberModal from './Modals/InviteMemberModal';
import { getInviteableUsersRoute, GetUsersResponse } from 'services/users';
import { GetTrackedServerResponse, getTrackedServersRoute } from 'services/servers';
import { GetGroupTypesResponse, getGroupTypesRoute } from 'services/grouptypes';
import ConfirmationModal from 'components/shared/ConfirmationModal';
import { SettingsIcon, StarFilledIcon } from 'assets/SVGIcons/Icons';
import { renderBoolean } from 'components/shared/TableRenders';
import GroupSettingsModal from './Modals/GroupSettingsModal';
import { GroupOwnerRole } from 'lib/constants';
import { _primaryPurple } from 'lib/colors';
import GroupWarnings from './GroupWarnings';
import { useNavigate } from 'react-router';
import { Server } from 'types/server';
import { User } from 'types/user';
import dayjs from 'dayjs';
import './style.scss';

export default function GroupProfile() {
  useAuthCheck(false);
  const { authUser } = useContext(UserContext);
  const navigate = useNavigate();

  const axiosPublic = useAxiosPublic();
  const axiosAuth = useAxiosAuth();

  const [createGroupVisible, setCreateGroupVisible] = useState(false);
  const [inviteMemberVisible, setInviteMemberVisible] = useState(false);
  const [groupSettingsVisible, setGroupSettingsVisible] = useState(false);
  const [selectedGroupMember, setSelectedGroupMember] = useState<GroupMemberViewModel | null>(null);

  const [invitableUsers, setInvitableUsers] = useState<User[]>([] as User[]);
  const [groupTypes, setGroupTypes] = useState<GroupType[]>([] as GroupType[]);
  const [groupRoles, setGroupRoles] = useState<GroupRole[]>([] as GroupRole[]);
  const [group, setGroup] = useState<Group | null>(null);
  const [trackedServers, setTrackedServers] = useState<Server[]>([] as Server[]);

  const [confirmMsg, setConfirmMsg] = useState<ReactNode>('');
  const [confirmMethod, setConfirmMethod] = useState<(() => void) | null>(null);

  useEffect(() => {
    if (authUser && authUser.selectedGroup) {
      fetchSelectedGroup();
      fetchTrackedServers();
      fetchInvitableUsers();
    }

    if (authUser) {
      fetchGroupTypes();
      fetchGroupRoles();
    }
  }, [authUser]);

  const fetchSelectedGroup = async () => {
    let res = await axiosAuth.get<never, GetGroupRouteResponse>(
      getGroupRoute(authUser!.selectedGroup!.groupID)
    );
    if (isErrorResponse(res)) {
      message.error(res.data);
      console.warn(res.data);
      return;
    }

    setGroup(res.group);
  };

  const fetchGroupTypes = async () => {
    let res = await axiosPublic.get<never, GetGroupTypesResponse>(getGroupTypesRoute());
    if (isErrorResponse(res)) {
      return;
    }

    setGroupTypes(res.groupTypes);
  };

  const fetchGroupRoles = async () => {
    let res = await axiosAuth.get<never, GetGroupRolesResponse>(getGroupRolesRoute());
    if (isErrorResponse(res)) {
      return;
    }

    setGroupRoles(res.groupRoles);
  };

  const fetchInvitableUsers = async () => {
    let res = await axiosAuth.get<never, GetUsersResponse>(getInviteableUsersRoute());
    if (isErrorResponse(res)) {
      return;
    }

    setInvitableUsers(res.users);
  };

  const fetchTrackedServers = async () => {
    let res = await axiosAuth.get<never, GetTrackedServerResponse>(getTrackedServersRoute());
    if (isErrorResponse(res)) {
      return;
    }

    setTrackedServers(res.trackedServers);
  };

  const renderActionColumn = (groupMember: GroupMemberViewModel) => {
    if (groupMember.groupOwnerID === groupMember.userID) return null;

    let removeMember = 'Remove Member';
    if (!groupMember.dateJoined.Valid) removeMember = 'Rescind invite';

    const items: MenuProps['items'] = [
      {
        label: removeMember,
        key: 'removeMember',
        danger: true,
      },
    ];

    if (groupMember.inviteAccepted && authUser?.id === groupMember.groupOwnerID)
      items.push({
        label: 'Make Group Owner',
        key: 'changeGroupOwner',
        danger: true,
      });

    return (
      <Dropdown
        className="table-actions"
        menu={{
          items,
          onClick: (e: MenuInfo) => handleMenuClick(e, groupMember),
        }}
        trigger={['click']}
        placement="bottomRight"
        arrow
      >
        <Button type="text" onClick={(e) => e.stopPropagation()}>
          <EllipsisOutlined />
        </Button>
      </Dropdown>
    );
  };

  const renderStatusIcons = (record: GroupMemberViewModel) => {
    let icons = (
      <>
        {record.userID === record.groupOwnerID && <StarFilledIcon className="groupOwner" />}

        {!record.kleiID.Valid && (record.listDedicated || record.listHosted) && (
          <Tooltip
            placement="topRight"
            title={
              "This member hasn't verified their Klei ID. This is required for their servers to show up as apart of your community."
            }
            arrow={false}
          >
            <WarningOutlined style={{ fontSize: '20px', marginRight: '5px', color: 'orange' }} />
          </Tooltip>
        )}
      </>
    );

    return (
      <>
        {icons}
        {record.username}
      </>
    );
  };

  const columns: ColumnsType<GroupMemberViewModel> = [
    {
      title: 'Username',
      render: renderStatusIcons,
    },
    {
      title: 'Role',
      dataIndex: 'groupRole',
      width: 100,
    },
    {
      title: 'List Dedicated',
      dataIndex: 'listDedicated',
      render: renderBoolean,
      width: 150,
    },
    {
      title: 'List Hosted',
      dataIndex: 'listHosted',
      render: renderBoolean,
      width: 150,
    },
    {
      title: 'Date Joined',
      width: 250,
      render: (record: GroupMemberViewModel) => {
        if (record.dateJoined.Valid)
          return dayjs.utc(record.dateJoined.Time).format('MMMM D, YYYY');

        return <i>Invite sent at {dayjs.utc(record.inviteSentAt).format('MMMM D, YYYY')}</i>;
      },
    },
    {
      title: '',
      key: 'actions',
      width: 100,
      render: renderActionColumn,
    },
  ];

  const getDeleteMessage = (groupMember: GroupMemberViewModel) => {
    if (!groupMember.inviteAccepted)
      return (
        <>
          Are you sure you with to rescind <b>{groupMember.username}</b>'s invite from{' '}
          <b>{groupMember.groupName}</b>? They can be re-invited later.
        </>
      );
    else if (groupMember.userID === authUser!.id)
      return (
        <>
          Are you sure you with to remove <b>yourself</b> from <b>{groupMember.groupName}</b>? This
          action cannot be undone and you'll need to recieve an invite to rejoin the group.
        </>
      );
    else
      return (
        <>
          Are you sure you wish to remove <b>{groupMember.username}</b> from{' '}
          <b>{groupMember.groupName}</b>? You can invite them again at a later time.
        </>
      );
  };

  const getChangeOwnershipMessage = (groupMember: GroupMemberViewModel) => {
    return (
      <>
        Are you sure you with to make <b>{groupMember.username}</b> the primary owner of{' '}
        <b>{groupMember.groupName}</b>? This action cannot be undone.
      </>
    );
  };

  const handleMenuClick = (e: MenuInfo, groupMember: GroupMemberViewModel) => {
    e.domEvent.stopPropagation();
    switch (e.key) {
      case 'removeMember':
        setConfirmMsg(getDeleteMessage(groupMember));
        setConfirmMethod(() => async () => handleRemoveMember(groupMember));
        break;
      case 'changeGroupOwner':
        setConfirmMsg(getChangeOwnershipMessage(groupMember));
        setConfirmMethod(() => async () => handleChangeOwner(groupMember));
        break;
    }
  };

  const handleRemoveMember = async (memberToHandle: GroupMemberViewModel) => {
    if (!memberToHandle) return;

    let req: RemoveGroupMemberRequest = {
      userID: memberToHandle.userID,
    };

    let res = await axiosAuth.post<never, ErrorResponse>(removeGroupMemberRoute(), req);
    if (isErrorResponse(res)) {
      if (res.data === '') message.error(`There was an issue removing ${memberToHandle.username}`);
      else message.error(res.data);
      return;
    }

    message.success(`Removed ${memberToHandle.username}`);
    setConfirmMethod(null);

    // Update list
    if (group)
      setGroup((prev) => ({
        ...prev!,
        groupMembers: prev!.groupMembers.filter((gm) => gm.userID !== memberToHandle.userID),
      }));
  };

  const handleChangeOwner = async (memberToHandle: GroupMemberViewModel) => {
    if (!memberToHandle) return;
    setConfirmMethod(null);
  };

  const userInvited = (invitedUser: GroupMemberViewModel) => {
    if (group === null) return;

    // Update user list
    if (group) {
      setGroup((prev) => ({
        ...prev!,
        groupMembers: prev!.groupMembers.concat(invitedUser),
      }));
    }

    setInvitableUsers((prev) => prev.filter((p) => p.id !== invitedUser.userID));
  };

  if (!authUser) return null;
  return (
    <section className="page group">
      <Row className="page-header" gutter={[8, 8]}>
        <GroupWarnings group={group} trackedServers={trackedServers} />
        <Col span={16}>
          {group && (
            <h1>
              {group.name}
              {authUser.selectedGroup?.groupRole === GroupOwnerRole && (
                <Button
                  type="link"
                  icon={<SettingsIcon style={{ fontSize: '22px', color: 'black' }} />}
                  onClick={() => setGroupSettingsVisible(true)}
                />
              )}
            </h1>
          )}
        </Col>
        <Col span={8} className="action-btns">
          {group && group.ownerUserID === authUser.id && (
            <Button type="primary" ghost onClick={() => navigate('/groupprofile/subscription')}>
              {group.groupType.name === 'Default' ? 'Upgrade' : 'Manage Subscription'}
            </Button>
          )}
          {group &&
            authUser.selectedGroup &&
            group.groupMembers.length < (group.groupType.memberLimit || 0) && (
              <Button type="primary" onClick={() => setInviteMemberVisible(true)}>
                Invite Member
              </Button>
            )}
          <Button type="primary" onClick={() => setCreateGroupVisible(true)}>
            Create Group
          </Button>
        </Col>
      </Row>

      {group && (
        <>
          {group.groupType.memberLimit !== null ? (
            <Row gutter={5}>
              <Col span={3}>Member Count</Col>
              <Col xl={{ span: 15 }} xs={{ span: 20 }}>
                <Progress
                  percent={(group.groupMembers.length / group.groupType.memberLimit) * 100}
                  strokeColor="#391085"
                  success={{
                    percent:
                      (group.groupMembers.filter((g) => g.inviteAccepted).length /
                        group.groupType.memberLimit) *
                      100,
                    strokeColor: _primaryPurple,
                  }}
                  showInfo={false}
                />
              </Col>
              <Col span={1}>
                {group.groupMembers.length}/{group.groupType.memberLimit}
              </Col>
            </Row>
          ) : (
            <>{group.groupMembers.length} / infinite group members</>
          )}
          {group.groupType.trackedServerLimit !== null ? (
            <Row gutter={5}>
              <Col span={3}>Tracked Servers</Col>
              <Col xl={{ span: 15 }} xs={{ span: 20 }}>
                <Progress
                  percent={(trackedServers.length / group.groupType.trackedServerLimit) * 100}
                  strokeColor={_primaryPurple}
                  showInfo={false}
                />
              </Col>
              <Col span={1}>
                {trackedServers.length}/{group.groupType.trackedServerLimit}
              </Col>
            </Row>
          ) : (
            <>Currently tracking {trackedServers.length} / infinite servers</>
          )}
          <Row>
            <Col span={24}>
              <Table
                rowKey={(record: GroupMemberViewModel) => record.userID}
                dataSource={group.groupMembers}
                columns={columns}
                className="clickable"
                onRow={(record: GroupMemberViewModel) => ({
                  onClick: () => {
                    if (authUser.selectedGroup?.groupRole !== GroupOwnerRole) return;

                    setSelectedGroupMember(record);
                    setInviteMemberVisible(true);
                  },
                })}
                pagination={{
                  pageSize: undefined,
                  hideOnSinglePage: true,
                }}
              />
            </Col>
          </Row>
        </>
      )}

      <CreateGroupModal
        visible={createGroupVisible}
        setVisible={setCreateGroupVisible}
        groupTypes={groupTypes}
        setGroupTypes={setGroupTypes}
      />
      {group && (
        <>
          <InviteMemberModal
            group={group}
            invitableUsers={invitableUsers}
            groupMember={selectedGroupMember}
            setGroup={setGroup}
            setGroupMember={setSelectedGroupMember}
            visible={inviteMemberVisible}
            setVisible={setInviteMemberVisible}
            userInvited={userInvited}
            groupRoles={groupRoles}
          />
          <GroupSettingsModal
            visible={groupSettingsVisible}
            setVisible={setGroupSettingsVisible}
            group={group}
            setGroup={setGroup}
          />
        </>
      )}

      <ConfirmationModal
        description={confirmMsg}
        open={confirmMethod !== null}
        onOk={async () => {
          // Call the method passed into confirmMethod
          if (typeof confirmMethod === 'function') await confirmMethod();
        }}
        onCancel={() => setConfirmMethod(null)}
        okButtonProps={{ danger: true }}
      />
    </section>
  );
}
