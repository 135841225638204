import React, { useState } from 'react';
import { Button, Col, InputNumber, Modal, Row, Select, message } from 'antd';
import { Group } from 'types/group';
import { isErrorResponse, useAxiosAuth } from 'api/api';
import {
  EndGroupSubscriptionRequest,
  ExtendGroupSubscriptionRequest,
  endGroupSubscriptionRoute,
  extendGroupSubscriptionRoute,
} from 'services/groups';
import ConfirmationModal from 'components/shared/ConfirmationModal';
import { StarFilledIcon, InfinityIcon } from 'assets/SVGIcons/Icons';
import { WarningOutlined } from '@ant-design/icons';
import { getContainer } from 'lib/rendering';
import dayjs from 'dayjs';

interface Props {
  group: Group | null;
  setGroup: React.Dispatch<React.SetStateAction<Group | null>>;
}

type IncreaseTypes = 'day' | 'week' | 'month';

export default function GroupModal({ group, setGroup }: Props) {
  const axiosAuth = useAxiosAuth();

  const [increaseAmount, setIncreaseAmount] = useState(1);
  const [increaseType, setIncreaseType] = useState<IncreaseTypes>('day');
  const [endConfirmOpen, setEndConfirmOpen] = useState(false);

  const handleClose = () => {
    setGroup(null);
    setIncreaseAmount(1);
    setIncreaseType('day');
  };

  const extendSubscription = async () => {
    if (!group) return;

    let data: ExtendGroupSubscriptionRequest = {
      groupID: group.id,
      amount: increaseAmount,
      period: increaseType,
    };

    let res = await axiosAuth.post(extendGroupSubscriptionRoute(), data);
    if (isErrorResponse(res)) {
      message.error(res.data);
      console.warn(res.data);
      return;
    }

    message.success('Subscription extended');
  };

  const endSubscription = async () => {
    if (!group) return;

    let data: EndGroupSubscriptionRequest = {
      groupID: group.id,
    };

    let res = await axiosAuth.post(endGroupSubscriptionRoute(), data);
    if (isErrorResponse(res)) {
      message.error(res.data);
      console.warn(res.data);
      return;
    }

    message.success('Subscription ended');
    setEndConfirmOpen(false);
  };

  return (
    <Modal
      title={`Viewing ${group?.name}`}
      open={group !== null}
      onCancel={handleClose}
      footer={null}
      width={1000}
      getContainer={getContainer('admin-settings')}
    >
      <Row gutter={[8, 16]}>
        <Col span={24}>
          <h4>Subscription Ends:</h4>{' '}
          {group?.subscriptionEnds.Valid ? (
            dayjs.utc(group?.subscriptionEnds.Time).format('LLL')
          ) : (
            <InfinityIcon />
          )}
        </Col>
      </Row>
      <Row gutter={[8, 16]}>
        <Col span={24}>
          <h4>Group Members</h4>
          {group?.groupMembers.map((gm) => (
            <Row key={gm.userID}>
              <Col span={4}>
                {gm.groupOwnerID === gm.userID && <StarFilledIcon className='groupOwner' />} {gm.username}
              </Col>
              <Col span={20}>
                {gm.dateJoined.Valid ? (
                  <>{dayjs.utc(gm.dateJoined.Time).format('MMMM D, YYYY')}</>
                ) : (
                  <i>invite sent</i>
                )}
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
      <h4>Extend Subscription</h4>
      <Row gutter={[8, 16]}>
        <Col span={4}>
          <InputNumber
            style={{ width: '100%' }}
            min={1}
            value={increaseAmount}
            onChange={(v) => v && setIncreaseAmount(v)}
          />
        </Col>
        <Col span={4}>
          <Select
            style={{ width: '100%' }}
            onChange={(e) => setIncreaseType(e)}
            value={increaseType}
            options={[
              {
                label: 'Days',
                value: 'day',
              },
              {
                label: 'Weeks',
                value: 'week',
              },
              {
                label: 'Months',
                value: 'month',
              },
            ]}
          />
        </Col>
        <Col>
          <Button type="primary" onClick={extendSubscription}>
            Add
          </Button>
        </Col>
        <Col>
          <Button danger onClick={() => setEndConfirmOpen(true)}>
            End Subscription
          </Button>
        </Col>
      </Row>
      <ConfirmationModal
        description={
          <>
            <WarningOutlined style={{ color: 'red', fontSize: '22px' }} /> Are you sure you want to
            end {group?.name}
            's subscription?
          </>
        }
        open={endConfirmOpen}
        onOk={endSubscription}
        onCancel={() => setEndConfirmOpen(false)}
        okButtonProps={{ danger: true }}
      />
    </Modal>
  );
}
