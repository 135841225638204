import { ColumnFilterItem } from "antd/es/table/interface";

export interface PaginatedSearchInfo {
  paginateInfo?: PaginateInfo;
  searchItems?: SearchItem[];
}

interface PaginateInfo {
  page: number;
  pageSize: number;
  totalRows?: number; // used only when the server returns results from a search
}

interface SearchItem {
  dbColumnName: string;
  searchValues: string[];
  exactValue: boolean;
}

export const seasonFilterValues = [
  {
    value: 'autumn',
    text: 'Autumn',
  },
  {
    value: 'winter',
    text: 'Winter',
  },
  {
    value: 'spring',
    text: 'Spring',
  },
  {
    value: 'summer',
    text: 'Summer',
  }
] as ColumnFilterItem[]