import React, { useEffect, useState } from 'react';
import CustomTable, { CustomColumnType } from 'components/shared/CustomTable';
import { AccountTokenViewModel } from 'types/accounttoken';
import { NullTime } from 'types/nullables';
import { Button, Col, Row, message } from 'antd';
import AccountTokenModal from './Modals/AccountTokenModal';
import { GroupType } from 'types/group';
import { isErrorResponse, useAxiosAuth } from 'api/api';
import { AccountTokensResponse, getAccountTokensRoute } from 'services/accounttoken';
import dayjs from 'dayjs';

const AccountTokensTable: React.FC<{ groupTypes: GroupType[] }> = ({ groupTypes }) => {
  const axiosAuth = useAxiosAuth();
  const [accountTokens, setAccountTokens] = useState<AccountTokenViewModel[]>([] as AccountTokenViewModel[]);

  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    fetchAccountTokens();
  }, []);

  const fetchAccountTokens = async () => {
    let res = await axiosAuth.get<any, AccountTokensResponse>(getAccountTokensRoute());
    if (isErrorResponse(res)) {
      message.error(res.data)
      console.warn(res.data)
      return
    }
    setAccountTokens(res.accountTokens);
  };

  const columns: CustomColumnType<AccountTokenViewModel>[] = [
    {
      title: 'Token',
      key: 'token',
      dataIndex: ['accountToken', 'token'],
    },
    {
      title: 'Used By',
      key: 'usedBy',
      dataIndex: 'usedBy',
      render: (record: string) => record === '' ? '-' : record
    },
    {
      title: 'Date Used',
      key: 'dateUsed',
      dataIndex: ['accountToken', 'dateUsed'],
      render: (record: NullTime) => (record.Valid ? dayjs.utc(record.Time).format('LLL') : '-'),
    },
    {
      title: 'Group Type',
      key: 'groupType',
      dataIndex: 'groupType',
      render: (record: string) => record === '' ? '-' : record
    },
    {
      title: 'Group Created',
      key: 'groupCreated',
      dataIndex: 'groupCreated',
      render: (record: string) => record === '' ? '-' : record,
    },
    {
      title: 'Date Group Created',
      key: 'dateGroupCreated',
      dataIndex: ['accountToken', 'dateGroupCreated'],
      render: (record: NullTime) => (record.Valid ? dayjs.utc(record.Time).format('LLL') : '-'),
    },
    {
      title: 'Expires',
      key: 'expiryDate',
      dataIndex: ['accountToken', 'expiryDate'],
      render: (record: NullTime) => (record.Valid ? dayjs.utc(record.Time).format('LLL') : '-'),
    },
  ];

  return (
    <>
      <Row className="action-btn" justify="end">
        <Col>
          <Button onClick={() => setModalOpen(true)}>New Account Token</Button>
        </Col>
      </Row>
      <CustomTable
        rowKey={(record: AccountTokenViewModel) => record.accountToken.token}
        columns={columns}
        dataSource={accountTokens}
        pagination={{
          defaultPageSize: 10,
          hideOnSinglePage: true,
        }}
      />
      <AccountTokenModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        setAccountTokens={setAccountTokens}
        groupTypes={groupTypes}
      />
    </>
  );
};
export default AccountTokensTable;
