import { FC, useState } from 'react';
import { useStripe, useElements, Elements, PaymentElement } from '@stripe/react-stripe-js';
import { Appearance, loadStripe } from '@stripe/stripe-js';
import { Button } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

interface CheckoutProps {
  clientSecret: string;
}

export default function Checkout({ clientSecret }: CheckoutProps) {
  const stripeKey = process.env.REACT_APP_STRIPE_PRIVATE_KEY;

  if (!stripeKey) return <p className="error">There was an issue connecting to stripe...</p>;
  if (clientSecret === '') return <p className="error">The client secret is empty...</p>;

  const appearance = {
    theme: 'stripe',
    labels: 'floating',
  } as Appearance;

  return (
    <Elements stripe={loadStripe(stripeKey)} options={{ clientSecret, appearance }}>
      <Com clientSecret={clientSecret} />
    </Elements>
  );
}

const Com: FC<CheckoutProps> = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);
  if (!elements || !stripe) return null;

  const handleSubmit = async () => {
    if (elements == null) return;

    setLoading(true);
    const { error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/groupprofile`,
      },
    });
    setLoading(false);

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <>
      <p>
        Normal Card: <code>4242424242424242</code>
      </p>
      <p>
        Fail on payment: <code>4000000000000341</code>
      </p>
      <p>
        Authorization Card: <code>4000002500003155</code>
      </p>
      <p>
        Insufficient Funds Card: <code>4000000000009995</code>
      </p>
      <p>Valid Zip Code: 91321</p>
      <p>Valid Postal Code: V8W2Y2</p>

      <PaymentElement onChange={() => setErrorMessage(undefined)} />
      <Button
        type="primary"
        disabled={!stripe || !elements || loading}
        icon={loading && <LoadingOutlined />}
        onClick={handleSubmit}
        style={{
          width: '100%',
          marginTop: '2ex',
        }}
      >
        Confirm Subscription
      </Button>
      {errorMessage && <div style={{ color: 'red', fontWeight: 'bold' }}>{errorMessage}</div>}
    </>
  );
  // return null
};
