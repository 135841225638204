import React, { useEffect, useState } from 'react';
import { Checkbox, Col, message, Modal, Row, Select } from 'antd';
import { isErrorResponse, useAxiosAuth } from 'api/api';
import {
  InviteGroupMemberRequest,
  InviteGroupMemberResponse,
  inviteGroupMemberRoute,
  updateGroupMemberRoute,
} from 'services/groups';
import { Group, GroupMemberViewModel, GroupRole } from 'types/group';
import { User } from 'types/user';

type Props = {
  group: Group;
  groupMember: GroupMemberViewModel | null;
  setGroupMember: React.Dispatch<React.SetStateAction<GroupMemberViewModel | null>>;
  setGroup: React.Dispatch<React.SetStateAction<Group | null>>;
  visible: boolean;
  invitableUsers: User[];
  groupRoles: GroupRole[];
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  userInvited: (invitedUser: GroupMemberViewModel) => void;
};

export default function InviteMemberModal(props: Props) {
  const axiosAuth = useAxiosAuth();

  const [userID, setUserID] = useState<number | null>(null);
  const [roleID, setRoleID] = useState<number | null>(null);
  const [listDedicated, setListDedicated] = useState(false);
  const [listHosted, setListHosted] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setUserID(props.groupMember?.userID || null);
    setRoleID(props.groupMember?.groupRoleID || null);
    setListDedicated(props.groupMember?.listDedicated || false);
    setListHosted(props.groupMember?.listHosted || false);
  }, [props.groupMember]);

  const closeModal = () => {
    setUserID(null);
    setRoleID(null);
    props.setGroupMember(null);
    props.setVisible(false);
  };

  const inviteMember = async () => {
    if (!userID || !roleID) {
      message.error('Select a user and role');
      return;
    }

    const reqData: InviteGroupMemberRequest = {
      userID,
      roleID,
      listDedicated,
      listHosted,
    };

    setLoading(true);
    if (props.groupMember) {
      // Update user
      let res = await axiosAuth.post<never, InviteGroupMemberResponse>(
        updateGroupMemberRoute(),
        reqData
      );
      setLoading(false);
      if (isErrorResponse(res)) {
        message.error(res.data);
        return;
      }

      props.setGroup(
        (prev) =>
          prev && {
            ...prev,
            groupMembers: prev.groupMembers.map((m) =>
              m.userID === userID ? { ...m, roleID, listDedicated, listHosted } : m
            ),
          }
      );
    } else {
      // Invite user
      let res = await axiosAuth.post<never, InviteGroupMemberResponse>(
        inviteGroupMemberRoute(),
        reqData
      );
      setLoading(false);
      if (isErrorResponse(res)) {
        if (res.data === '') message.error('There as an issue inviting this user');
        else message.error(res.data);
        return;
      }

      props.userInvited(res.invitedUser);
    }
    closeModal();
  };

  return (
    <Modal
      title="Invite Group Members"
      onCancel={closeModal}
      onOk={inviteMember}
      width={600}
      open={props.visible}
      className="new-group-modal"
      confirmLoading={loading}
      okText={props.groupMember ? 'Update' : 'Invite'}
    >
      <Row gutter={24}>
        <Col span={12}>
          <Select
            showSearch
            placeholder="Select a user"
            style={{ width: '100%' }}
            filterOption={(input, option) =>
              (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())
            }
            options={props.invitableUsers.map((u) => ({
              value: u.id,
              label: u.username,
            }))}
            disabled={!!props.groupMember}
            value={!!props.groupMember ? props.groupMember.username : userID}
            onChange={(e) => setUserID(Number(e))}
          />
        </Col>
        <Col span={12}>
          <Select
            showSearch
            placeholder="Role"
            style={{ width: '100%' }}
            options={props.groupRoles.map((r) => ({
              value: r.id,
              label: r.role,
            }))}
            value={roleID}
            onChange={(e) => setRoleID(e)}
          />
        </Col>
        <Col span={24}>
          <h4>Should this member's servers be listed on the public community page?</h4>
          {!props.group.isPublic && (
            <i>These are disabled because your group isn't listed as a public community</i>
          )}
          <Checkbox
            disabled={!props.group.isPublic}
            checked={listDedicated}
            onChange={(e) => setListDedicated(e.target.checked)}
          >
            Dedicated server
          </Checkbox>
          <Checkbox
            disabled={!props.group.isPublic}
            checked={listHosted}
            onChange={(e) => setListHosted(e.target.checked)}
          >
            Hosted server
          </Checkbox>
        </Col>
      </Row>
    </Modal>
  );
}
