import { useContext, useEffect, useState } from 'react';
import { Button, Col, Input, Row, Select, Tooltip, message } from 'antd';
import { apiURL, isErrorResponse, useAxiosAuth } from 'api/api';
import { UserContext } from 'context/UserContext';
import { useAuthCheck } from 'hooks/use_auth_check';
import { GetUserGroupsResponse, getUserGroupsRoute } from 'services/users';
import { ErrorResponse, useNavigate } from 'react-router';
import { GroupMemberViewModel } from 'types/group';
import PasswordReset from '../shared/PasswordReset';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import VerifyKleiID from './VerifyKleiID';
import VerifyEmail from './VerifyEmail';
import dayjs from 'dayjs';
import './style.scss';

export default function UserProfile() {
  useAuthCheck(false);
  const navigate = useNavigate();
  const axiosAuth = useAxiosAuth();
  const { authUser } = useContext(UserContext);
  const [defaultGroupUpdated, setDefaultGroupUpdated] = useState<boolean | null>(null);
  const [loadingSubscription, setLoadingSubscription] = useState(false);

  const [userGroups, setUserGroups] = useState<GroupMemberViewModel[]>(
    [] as GroupMemberViewModel[]
  );

  useEffect(() => {
    fetchMiscUserData();
  }, []);

  const fetchMiscUserData = async () => {
    let res = await axiosAuth.get<never, GetUserGroupsResponse>(getUserGroupsRoute());
    if (isErrorResponse(res)) {
      console.warn(res.data);
      return;
    }

    setUserGroups(res.userGroups);
  };

  const updateDefaultGroup = async (groupID: string) => {
    setDefaultGroupUpdated(null);
    if (!groupID) return;

    let res = await axiosAuth.post(apiURL('users/set-default-group'), { groupID });
    if (isErrorResponse(res)) {
      setDefaultGroupUpdated(false);
      if (res.data === '') message.error('there was an issue updating your default group');
      else message.error(res.data);
      return;
    }

    setDefaultGroupUpdated(true);
  };

  const getCustomerProfile = async () => {
    // Get customer portal
    let res = await axiosAuth.get<never, string | ErrorResponse>(apiURL('/users/stripe-portal'));
    if (isErrorResponse(res)) {
      return;
    }

    window.location.href = res;
  };

  const iconStyleCss = (color: string) => {
    return {
      color: color,
      fontSize: '20px',
      marginLeft: '4px',
    };
  };

  if (!authUser) return null;
  return (
    <section className="user-settings" id="user-settings">
      <Row>
        <Col xs={24} sm={16} md={12} lg={8} xl={6}>
          <h1>User Profile: {authUser.username}</h1>
          <div>Member since {dayjs.utc(authUser.dateCreated).format('LLL')}</div>
          {userGroups.length > 0 && (
            <>
              <h4>Default Group</h4>
              <div className="defaut-group grey-space">
                <Select
                  className="ddl"
                  defaultValue={authUser.defaultGroupID}
                  options={userGroups.map((s) => ({
                    value: s.groupID,
                    label: s.groupName,
                  }))}
                  onChange={updateDefaultGroup}
                  style={{ width: '100%' }}
                />
                {defaultGroupUpdated ? (
                  <CheckCircleOutlined style={iconStyleCss('green')} />
                ) : defaultGroupUpdated === false ? (
                  <CloseCircleOutlined style={iconStyleCss('red')} />
                ) : null}
                <small>
                  Update your default group. This won't change your currently selected group
                </small>
              </div>
            </>
          )}

          <PasswordReset />

          <VerifyEmail />

          <h4>
            Request Klei ID
            <Tooltip
              placement="right"
              title="If you're apart of a group that's listed as a community and you host servers for that group entering your Klei ID will ensure your servers show up on the community server list."
            >
              <QuestionCircleOutlined style={{ marginLeft: '5px' }} />
            </Tooltip>
          </h4>
          <Row gutter={[8, 8]}>
            {authUser.kleiID.Valid && (
              <Col span={12}>
                <Input disabled value={authUser.kleiID.String} />
              </Col>
            )}
            <Col span={authUser.kleiID.Valid ? 12 : 24}>
              <VerifyKleiID />
            </Col>
          </Row>

          <Row gutter={[8, 8]} className="stripe-billing">
            <h4>Stripe Billing</h4>
            <Col span={24}>
              <Button
                disabled={loadingSubscription}
                style={{ width: '100%' }}
                onClick={getCustomerProfile}
              >
                Manage Subscriptions/See Invoices
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </section>
  );
}
