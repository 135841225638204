import React from 'react';
import { Tooltip } from 'antd';
import {
  AutumnIcon,
  BetaIcon,
  OutdatedIcon,
  SpringIcon,
  SummerIcon,
  WinterIcon,
} from 'assets/SVGIcons/Icons';
import { _primaryPurple } from 'lib/colors';
import { capitalize } from 'lib/string';
import { LiveVersions } from 'types/liveserver';

export const RenderSeasonIcon: React.FC<{ season: string; showTooltip?: boolean }> = ({
  season,
  showTooltip = true,
}) => {
  const renderIcon = () => {
    switch (season) {
      case 'autumn':
        return <AutumnIcon />;
      case 'winter':
        return <WinterIcon />;
      case 'spring':
        return <SpringIcon />;
      case 'summer':
        return <SummerIcon />;
      default:
        return null;
    }
  };

  return showTooltip ? (
    <Tooltip placement="top" title={capitalize(season)} color={_primaryPurple}>
      {renderIcon()}
    </Tooltip>
  ) : (
    renderIcon()
  );
};

export const VersionIcon: React.FC<{ version: number; versions: LiveVersions }> = ({
  version,
  versions,
}) => {
  const getIcon = () => {
    // Release
    if (version === versions.release[0]) return null;

    let tooltipText = '';
    let icon = null;

    // Outdated Release
    if (versions.release.includes(version)) {
      icon = <OutdatedIcon className="version-icon" style={{ color: 'red' }} />;
      tooltipText = 'Outdated';
    }

    // Beta
    else if (version === versions.updatebeta[0]) {
      icon = <BetaIcon style={{ color: 'blue' }} />;
      tooltipText = 'Beta';
    }

    // Outdated Beta
    else if (versions.updatebeta.includes(version)) {
      icon = <BetaIcon style={{ color: 'red' }} />;
      tooltipText = 'Outdated Beta';
    }

    return <Tooltip title={tooltipText}>{icon}</Tooltip>;
  };

  return getIcon();
};
