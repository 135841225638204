import { useContext, useEffect, useState } from 'react';
import { apiURL, isErrorResponse, useAxiosAuth, useAxiosPublic } from 'api/api';
import { UserContext } from 'context/UserContext';
import { useAuthCheck } from 'hooks/use_auth_check';
import { Button, Input, Modal, Select, message, Image } from 'antd';
import { ErrorResponse } from '@remix-run/router';
import { GetPlatformsResponse, getPlatformsRoute } from 'services/games';
import { Platform } from 'types/platform';
import { getRandomWords } from 'lib/misc';
import { LoadingOutlined } from '@ant-design/icons';

export default function VerifyKleiID() {
  useAuthCheck(false);
  const { authUser, setAuthUser } = useContext(UserContext);
  const [isOpen, setIsOpen] = useState(false);
  const [startVerification, setStartVerification] = useState(false);
  const [platforms, setPlatforms] = useState([] as Platform[]);
  const [loading, setLoading] = useState(false);

  const [platformID, setPlatformID] = useState<number | undefined>(undefined);
  const [kleiID, setKleiID] = useState('');
  const [serverName, setServerName] = useState('');

  const axiosAuth = useAxiosAuth();
  const axiosPublic = useAxiosPublic();

  useEffect(() => {
    fetchPlatforms();
  }, []);

  const fetchPlatforms = async () => {
    let res = await axiosPublic.get<never, GetPlatformsResponse | ErrorResponse>(
      getPlatformsRoute()
    );
    if (isErrorResponse(res)) {
      return;
    }

    setPlatforms(res.platforms);
  };

  const createVerificationData = async () => {
    if (!platformID) return;
    if (!kleiID.startsWith('KU_') || kleiID.length !== 11) {
      message.error(
        "You're Klei ID doesn't look right. Should have the following format: KU_xxxxxxxx"
      );
      return;
    }

    let serverName = getRandomWords(3).join(' ');
    setServerName(serverName);

    setLoading(true);
    let res = await axiosAuth.post(apiURL('users/request-kleiid'), {
      expectedKleiID: kleiID,
      expectedServerName: serverName,
      expectedPlatformID: platformID,
    });
    setLoading(false);

    if (isErrorResponse(res)) {
      message.error('There was an issue starting the verification process. Please try again later');
      return;
    }

    setStartVerification(true);
  };

  const verifyKleiID = async () => {
    setLoading(true);
    let res = await axiosAuth.post(apiURL('users/confirm-kleiid'), {
      expectedKleiID: kleiID,
      expectedServerName: serverName,
      expectedPlatformID: platformID,
    });
    setLoading(false);

    if (isErrorResponse(res)) {
      message.error(res.data, 10);
      return;
    }

    setAuthUser((prev) => {
      if (!prev) return null;
      let n = { ...prev };
      n.kleiID = {
        String: kleiID,
        Valid: true,
      };
      return n;
    });

    message.success('Klei ID verified!');

    setIsOpen(false);
    setStartVerification(false);
    setPlatformID(undefined);
    setKleiID('');
    setServerName('');
  };

  if (!authUser) return null;
  return (
    <>
      <Button type="primary" onClick={() => setIsOpen(true)} style={{ width: '100%' }}>
        {authUser.kleiID.Valid ? 'Update KleiID' : 'Set KleiID'}
      </Button>
      <Modal
        getContainer={() => document.getElementById('user-settings') || document.body}
        className="verify-modal"
        title="Verify your Klei ID"
        width={startVerification ? 1000 : 600}
        open={isOpen}
        footer={false}
        onCancel={() => setIsOpen(false)}
        maskClosable={false}
      >
        {startVerification ? (
          <Verify serverName={serverName} loading={loading} verifyKleiID={verifyKleiID} />
        ) : (
          <VerificationInstructions
            platforms={platforms}
            platformID={platformID}
            setPlatformID={setPlatformID}
            kleiID={kleiID}
            setKleiID={setKleiID}
            createVerificationData={createVerificationData}
            loading={loading}
          />
        )}
      </Modal>
    </>
  );
}

const VerificationInstructions: React.FC<{
  platforms: Platform[];
  platformID: number | undefined;
  setPlatformID: React.Dispatch<React.SetStateAction<number | undefined>>;
  kleiID: string;
  setKleiID: React.Dispatch<React.SetStateAction<string>>;
  createVerificationData: () => void;
  loading: boolean;
}> = (props) => {
  return (
    <>
      <p>
        In order to verify your Klei ID you need be able to temperarely host a server. If you're
        ready, type in your Klei ID, select the platform you're going to use, and click{' '}
        <b>start verification</b>. You will have 30 minutes to complete the process once you start.
      </p>
      <span className="host-img">
        <Image src="/images/klei_verification/host_server.png" />
      </span>
      <Input
        value={props.kleiID}
        placeholder="Klei ID: eg. KU_xxxxxxxx"
        style={{ width: '49%' }}
        onChange={(e) => props.setKleiID(e.target.value)}
      />
      <Select
        placeholder="Select platform"
        value={props.platformID}
        options={props.platforms.map((p) => ({
          value: p.id,
          label: p.platform,
        }))}
        onChange={(e) => props.setPlatformID(e)}
        style={{ width: '49%', float: 'right' }}
      />
      <span className="start-verification">
        <Button
          type="primary"
          disabled={!props.platformID || props.kleiID === '' || props.loading}
          onClick={() => props.createVerificationData()}
          icon={props.loading && <LoadingOutlined />}
        >
          Start Verification
        </Button>
      </span>
    </>
  );
};

const Verify: React.FC<{
  serverName: string;
  loading: boolean;
  verifyKleiID: () => void;
}> = ({ serverName, loading, verifyKleiID }) => {
  return (
    <>
      <p>
        <b>Please follow the following instructions:</b>
      </p>
      <ol>
        <li>Start DST and make sure you're connected to the internet.</li>
        <li>
          Select host game and create a server (if your slots are all filled up you can use a
          created world).
        </li>
        <span className="instructions-img">
          <img alt="" src="/images/klei_verification/host_server.png" />
          <img alt="" src="/images/klei_verification/create_server.png" />
        </span>
        <li>Select any play style.</li>
        <li>
          Make sure the server is set to public.
          <span className="instructions-img2">
            <img alt="" src="/images/klei_verification/server_public.png" />
          </span>
        </li>
        <li>
          None of the othersettings matter <b>except</b> for what you name the server. You must name
          your server the following:
        </li>
        <span className="server-name">{serverName}</span>
        <span className="instructions-img3">
          <img alt="" src="/images/klei_verification/server_name.png" />
        </span>
        <li>Start the game (there is no need to join once it's loaded).</li>
        <li>
          Once you're ready click <b>Verify</b> below.
        </li>
      </ol>
      <span className="verify">
        <Button
          type="primary"
          icon={loading && <LoadingOutlined />}
          onClick={() => verifyKleiID()}
          disabled={loading}
        >
          Verify
        </Button>
      </span>
    </>
  );
};
