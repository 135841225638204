export function ellipsize(str: string, maxCharacters: number): string {
  if (maxCharacters < 3) {
    console.warn(
      'The ellipsize function cannot be used with a value for maxCharacters less than 3'
    );
    return '';
  }

  if (str.length > maxCharacters) return `${str.slice(0, maxCharacters - 3)}...`;
  return str;
}

export function capitalize(s: string | null | undefined): string {
  if (!s) return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
}
