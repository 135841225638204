import React, { useContext, useEffect, useState } from 'react';
import { Layout, Menu, Skeleton } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { getMenuPosition, setMenuPosition, setSelectedGroupID } from 'lib/local_storage';
import { UserContext, useSessionRefresh } from 'context/UserContext';
import { Outlet, useLocation } from 'react-router-dom';
import { NavigationItems } from './NavigationItems';
import { isErrorResponse } from 'api/api';
import Header from 'components/Header';
import Footer from 'components/Footer';
import './style.scss';

const { Sider, Content } = Layout;

type ThemeTypes = 'light' | 'dark';

export default function Root() {
  const location = useLocation();
  const { fetchSession } = useSessionRefresh();
  const { setAuthUser, setImpersonatedUser } = useContext(UserContext);

  const [loadingUser, setLoadingUser] = useState(true);
  const [collapsed, setCollapsed] = useState(getMenuPosition());
  const [menuKey, setMenuKey] = useState<string[]>(); // To do, set this properly on load
  const [theme, setTheme] = useState<ThemeTypes>('dark');

  useEffect(() => {
    refreshSession();
  }, []);

  useEffect(() => {
    // Set menu key
    let key = location.pathname.split('/');

    // Highlight home page when loaded there
    if (key.length === 2 && key[1] === '') key = ['', 'liveservers'];

    setMenuKey(key);
  }, [location.pathname]);

  const refreshSession = async () => {
    let res = await fetchSession();
    setLoadingUser(false);

    if (isErrorResponse(res)) {
      return;
    }

    if (res.user?.selectedGroup) setSelectedGroupID(res.user.selectedGroup.groupID);
    setAuthUser(res.user);
    setImpersonatedUser(res.impersonatedUser);
  };


  return (
    <Layout>
      <Sider theme={theme} className="menu-slider" trigger={null} collapsible collapsed={collapsed}>
        {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
          className: `trigger ${theme}`,
          onClick: () => {
            setCollapsed(!collapsed);
            setMenuPosition(collapsed);
          },
        })}
        <Menu
          theme={theme}
          selectedKeys={menuKey}
          mode="inline"
          items={NavigationItems()}
        />
      </Sider>
      <Layout className="site-layout" style={{ marginLeft: collapsed ? 80 : 200 }}>
        <Header />
        <Content className="site-content" style={{ overflow: 'initial', padding: '2ex' }}>
          {/* Make sure context has attempted to load. Otherwise auth user will be redirected to home page */}
          {loadingUser ? (
            <Skeleton active={true} paragraph={{ rows: 3, width: '100%' }} title={false} />
          ) : (
            <>
              <Outlet />
              <Footer />
            </>
          )}
        </Content>
      </Layout>
    </Layout>
  );
}
