import { useEffect, useState } from 'react';
import { getLiveServersRoute, LiveServersResponse } from 'services/liveservers';
import { PaginatedSearchInfo } from 'components/shared/TableInterfaces';
import { isErrorResponse, useAxiosPublic } from 'api/api';
import { ErrorResponse } from '@remix-run/router';
import ServerTable from 'components/ServerTable';
import { LiveServer } from 'types/liveserver';
import dayjs from 'dayjs';
import './style.scss';

export default function LiveServers() {
  const axiosPublic = useAxiosPublic();
  const [liveServers, setLiveServers] = useState<LiveServer[] | null>(null);
  const [totalServers, setTotalServers] = useState(0);

  useEffect(() => {
    fetchLiveServerData({
      paginateInfo: {
        page: 1,
        pageSize: 25,
      },
    });
  }, []);

  const fetchLiveServerData = async (paginatedSearchInfo: PaginatedSearchInfo) => {
    let res = await axiosPublic.post<never, LiveServersResponse | ErrorResponse>(
      getLiveServersRoute(),
      { paginatedSearchInfo }
    );
    if (isErrorResponse(res)) {
      return;
    }

    setLiveServers(res.liveServers);
    setTotalServers(res.totalServers);
  };

  const getTimeDiff = () => {
    if (!liveServers || liveServers.length === 0) return 0;
    return dayjs().diff(dayjs(liveServers[0].timestamp), 'minutes');
  };

  const getTimestamp = () => {
    if (!liveServers || liveServers.length === 0) return '';
    return dayjs(liveServers[0].timestamp).format('lll');
  };

  return (
    <section>
      <div>
        {totalServers} total servers found! Live servers recorded ~{getTimeDiff()} min(s) ago at{' '}
        {getTimestamp()}.
      </div>
      <ServerTable
        totalServerCount={totalServers}
        servers={liveServers}
        loadTableData={fetchLiveServerData}
      />
    </section>
  );
}
