import axios, { AxiosHeaders, isAxiosError } from 'axios';
import { ErrorResponse } from '@remix-run/router';
import { useContext, useEffect, useMemo } from 'react';
import { UserContext } from 'context/UserContext';
import { notification } from 'antd';
import { deleteSelectedGroupID } from 'lib/local_storage';

export function apiURL(path: string) {
  const { hostname } = window.location;
  const rootPath = path[0] === '/' ? path : `/${path}`;

  if (hostname === 'localhost') return `http://localhost:9922${rootPath}`;

  // this ensures we route to hostname/api/whatever
  return `/api${rootPath}`;
}

//// Axios Public
export const axiosPublic = axios.create({
  validateStatus: (status: number) => {
    return status >= 200 && status < 300; // default (200 - 299);
  },
  headers: {
    Accept: `application/json`,
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

let publicResponseIntercept: number | null = null;

const clearPublicInterceptors = (resInterceptor: number | null) => {
  if (resInterceptor || resInterceptor === 0)
    axiosPublic.interceptors.response.eject(resInterceptor);
};

export const useAxiosPublic = () => {
  useEffect(() => {
    clearPublicInterceptors(publicResponseIntercept);
    publicResponseIntercept = axiosPublic.interceptors.response.use(
      (res) => res.data,
      (error) => error.response
    );

    return () => {
      if (publicResponseIntercept) axiosPublic.interceptors.response.eject(publicResponseIntercept);
    };
  }, []);

  return axiosPublic;
};

//// Axios Private
const axiosAuth = axios.create({
  validateStatus: (status: number) => {
    return status >= 200 && status < 300; // default (200 - 299);
  },
  headers: {
    Accept: `application/json`,
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

let authRequestIntercept: number | null = null;
let authResponseIntercept: number | null = null;

const clearAuthInterceptors = (reqInterceptor: number | null, resInterceptor: number | null) => {
  if (reqInterceptor || reqInterceptor === 0) axiosAuth.interceptors.request.eject(reqInterceptor);
  if (resInterceptor || resInterceptor === 0) axiosAuth.interceptors.response.eject(resInterceptor);
};

// This is so multiple requests to get a refresh token can't be made at the same time
let refreshing_token: null | Promise<any> = null;
export const useAxiosAuth = () => {
  const { authUser, setAuthUser, setImpersonatedUser } = useContext(UserContext);
  const refresh = useRefreshToken();

  const stableAuthUser = useMemo(() => authUser, [authUser]);
  const stableSetAuthUser = useMemo(() => setAuthUser, [setAuthUser]);
  const stableRefresh = useMemo(() => refresh, [refresh]);

  useEffect(() => {
    clearAuthInterceptors(authRequestIntercept, authResponseIntercept);

    authRequestIntercept = axiosAuth.interceptors.request.use(
      (config) => {
        if (!config.headers.Authorization) {
          config.headers.Authorization = `Bearer ${stableAuthUser?.accessToken}`;
        }

        if (stableAuthUser?.selectedGroup) {
          (config.headers as AxiosHeaders).set(
            'Selected-Group',
            `${stableAuthUser?.selectedGroup.groupID}`
          );
        }

        return config;
      },
      (error) => Promise.reject(error.response)
    );

    // Use refresh token to get new access token
    authResponseIntercept = axiosAuth.interceptors.response.use(
      (res) => res.data, // if the request succeeds, pass back data.
      // if the auth request fails, try to get a new auth token
      async (error) => {
        const originalRequest = error.config;

        // If the error isn't a 401 or we've already tried to get the access token handle error normally
        if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;

          // This ensures only one new access token is fetched at a time
          refreshing_token = refreshing_token ? refreshing_token : stableRefresh();
          let res = await refreshing_token;
          refreshing_token = null;

          // If refreshToken() returns an axios error the refresh probably failed
          // Reject the request
          if (isAxiosError(res)) {
            notification.info({
              message: '¯\\_(ツ)_/¯',
              description: 'Your session ended. Please log in again.',
              duration: 7,
              key: 1, // this ensure only one notification is displayed
            });

            deleteSelectedGroupID();
            stableSetAuthUser(null); // logging out
            setImpersonatedUser(null);
            // TODO: actually log user out??
            return res.response;
          }

          stableSetAuthUser((prev) => {
            if (prev) return { ...prev, accessToken: res.accessToken };
            return null;
          });

          if (res.accessToken) {
            originalRequest.headers.Authorization = `Bearer ${res.accessToken}`;
          }

          // Resubmit the initial request with the new access token
          return axiosAuth(originalRequest);
        }

        return error.response;
      }
    );

    return () => {
      clearAuthInterceptors(authRequestIntercept, authResponseIntercept);
    };
  }, [stableAuthUser, stableRefresh, stableSetAuthUser]);

  return axiosAuth;
};

const useRefreshToken = () => {
  const refresh = async () => {
    const res = await axios
      .get(apiURL('/auth/refresh'), { withCredentials: true })
      .then((res) => res.data)
      .catch((err) => err);
    return res;
  };
  return refresh;
};

//// isErrorResponse
export function isErrorResponse<T>(err: T | ErrorResponse): err is ErrorResponse {
  return (
    err != null &&
    (err as ErrorResponse).status != null &&
    (err as ErrorResponse).statusText != null
  );
}
