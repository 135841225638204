import { ArrowLeftOutlined } from '@ant-design/icons';
import { Breadcrumb, Card, Col, DatePicker, Empty, Row, Skeleton } from 'antd';
import { isErrorResponse, useAxiosAuth } from 'api/api';
import ExternalLink from 'components/shared/ExternalLink';
import { RenderSeasonIcon } from 'components/shared/ServerIcons';
import { UserContext } from 'context/UserContext';
import dayjs, { Dayjs } from 'dayjs';
import { useAuthCheck } from 'hooks/use_auth_check';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { CategoricalChartState } from 'recharts/types/chart/types';
import {
  getTrackedServerDataRoute,
  TrackedServerDataResponse,
} from 'services/servers';
import { Game } from 'types/game';
import { TrackedServerViewModel } from 'types/server';
import PlayerHeatmapData from './PlayerHeatmapData';
import { colorBlock } from 'components/shared/ColorBlock';
import { _primaryPurple } from 'lib/colors';
import './style.scss';

const { RangePicker } = DatePicker;
const { Meta } = Card;

export default function ServerData() {
  useAuthCheck(false);
  const { authUser } = useContext(UserContext);
  const axiosAuth = useAxiosAuth();
  const navigate = useNavigate();
  let { serverID } = useParams();

  const [loading, setLoading] = useState(true);
  const [trackedData, setTrackedData] = useState<TrackedServerViewModel | null>(null);
  const [selectedGameMoment, setSelectedGameMoment] = useState<Game | undefined>(undefined);

  const [startDate, setStartDate] = useState(dayjs.utc().startOf('day').valueOf());
  const [endDate, setEndDate] = useState(dayjs.utc().endOf('day').valueOf());

  useEffect(() => {
    if (!authUser || !authUser.selectedGroup) navigate('/trackedservers', { replace: true });

    fetchTrackedServerData();
  }, [authUser, startDate, endDate]);

  const fetchTrackedServerData = async () => {
    if (!serverID) return;

    let res = await axiosAuth.get<never, TrackedServerDataResponse>(
      getTrackedServerDataRoute(serverID, startDate, endDate)
    );
    setLoading(false);
    if (isErrorResponse(res)) {
      return;
    }

    setTrackedData(res.trackedServerData);
  };

  const data = () => {
    return trackedData?.gameData.map((g) => ({
      day: g.day,
      playerCount: g.playerCount,
      timestamp: dayjs.utc(g.timestamp).format('LLL'),
    }));
  };

  const handleChartClick = (t: CategoricalChartState) => {
    setSelectedGameMoment(trackedData?.gameData[t.activeTooltipIndex!]);
  };

  const breadcrumbsItems = [
    {
      title: (
        <Link to="/trackedservers">
          <ArrowLeftOutlined />
          Tracked Servers
        </Link>
      ),
    },
  ];

  const onRangeChange = (dates: null | (Dayjs | null)[], dateStrings: string[]) => {
    if (dates) {
      if (dates[0]) setStartDate(dates[0].utc().startOf('day').valueOf());
      else setStartDate(0);

      if (dates[1]) setEndDate(dates[1].utc().endOf('day').valueOf());
      else setEndDate(0);
    } else {
      setStartDate(0);
      setEndDate(0);
    }
  };

  return (
    <section className="tracked-server">
      <Breadcrumb items={breadcrumbsItems} />
      <Row>
        <Col span={24}>
          <h2>{trackedData?.name}</h2>
        </Col>
      </Row>

      {loading ? (
        <Skeleton />
      ) : (
        <>
          <div className="date-picker">
            Showing from{' '}
            <RangePicker
              placement="bottomLeft"
              format="MMM D, YYYY"
              value={[dayjs(startDate), dayjs(endDate)]}
              onChange={onRangeChange}
            />
          </div>
          {trackedData && trackedData.gameData.length > 0 ? (
            <>
              <Row>
                <Col span={20}>
                  <ResponsiveContainer width="100%" height={400}>
                    <LineChart data={data()} onClick={(e) => handleChartClick(e)}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="timestamp" tick={<></>} />
                      <YAxis
                        yAxisId="left"
                        label={{ value: 'Day', offset: -5, position: 'insideLeft' }}
                      />
                      <YAxis
                        yAxisId="right"
                        orientation="right"
                        label={{
                          value: 'Player Count',
                          angle: 90,
                          offset: 10,
                          position: 'insideRight',
                        }}
                      />
                      <Tooltip />
                      <Legend />
                      <Line
                        yAxisId="left"
                        type="monotone"
                        dataKey="day"
                        stroke={_primaryPurple}
                        name="Day"
                      />
                      <Line
                        yAxisId="right"
                        type="monotone"
                        dataKey="playerCount"
                        stroke="green"
                        name="Player Count"
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </Col>
                <Col span={4}>
                  <GameInfo game={selectedGameMoment} />
                </Col>
              </Row>
              <PlayerHeatmapData />
            </>
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </>
      )}
    </section>
  );
}

const GameInfo: React.FC<{ game: Game | undefined }> = ({ game }) => {
  if (!game) return null;

  const renderTitle = () => (
    <>
      {dayjs.utc(game.timestamp).format('LLL')}
      <Meta description={game.session} />
    </>
  );

  return (
    <Card className="game-info" size="small" title={renderTitle()}>
      <span className="icons">
        <RenderSeasonIcon season={game.season} />
      </span>
      <p>
        <b>Day:</b> {game.day}
      </p>
      <p>
        <b>Players</b> ({game.playerCount})
      </p>
      <div className="players">
        {game.players.map((p) =>
          p.steamID === '' ? (
            <span>
              {colorBlock(p.color)}
              {p.player}
              <small>{p.character}</small>
            </span>
          ) : (
            <span>
              {colorBlock(p.color)}
              <ExternalLink
                url={`https://steamcommunity.com/profiles/${p.steamID}`}
                text={p.player}
              />
              <small>{p.character}</small>
            </span>
          )
        )}
      </div>
      <Meta description={`v: ${game.version}`} />
    </Card>
  );
};
