import { isErrorResponse, useAxiosPublic } from 'api/api';
import { GetGroupTypesResponse, getAllGroupTypesRoute } from 'services/grouptypes';
import { useAuthCheck } from 'hooks/use_auth_check';
import { useEffect, useState } from 'react';
import { GroupType } from 'types/group';
import GroupTypesTable from './GroupTypesTable';
import { Tabs, TabsProps } from 'antd';
import AccountTokensTable from './AccountTokensTable';
import { useSearchParams } from 'react-router-dom';
import Groups from './Groups';
import './style.scss';

export default function AdminSettings() {
  const [searchParams, setSearchParams] = useSearchParams();
  const isAuthorized = useAuthCheck(true);
  const axiosPublic = useAxiosPublic();

  const [groupTypes, setGroupTypes] = useState<GroupType[]>([] as GroupType[]);

  useEffect(() => {
    if (isAuthorized) {
      fetchGroupTypes();
    }
  }, [isAuthorized]);

  const fetchGroupTypes = async () => {
    let res = await axiosPublic.get<never, GetGroupTypesResponse>(getAllGroupTypesRoute());
    if (isErrorResponse(res)) {
      return;
    }
    setGroupTypes(res.groupTypes);
  };

  const items: TabsProps['items'] = [
    {
      key: 'groups',
      label: `Groups`,
      children: <Groups />,
    },
    {
      key: 'group-types',
      label: `Group Types`,
      children: <GroupTypesTable groupTypes={groupTypes} setGroupTypes={setGroupTypes} />,
    },
    {
      key: 'account-tokens',
      label: `Account Tokens`,
      children: <AccountTokensTable groupTypes={groupTypes} />,
    },
  ];

  return (
    <section className="page admin-settings" id="admin-settings">
      <Tabs
        items={items}
        activeKey={searchParams.get('tab') || 'groups'}
        onChange={(key) => setSearchParams({ tab: key })}
        centered
      />
    </section>
  );
}
