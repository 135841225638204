import { getDefaultSavedTab, setDefaultSavedTab } from 'lib/local_storage';
import { useAuthCheck } from 'hooks/use_auth_check';
import SavedServers from './SavedServers';
import SavedGroups from './SavedGroups';
import { Tabs } from 'antd';

export default function SavedServersTabs() {
  useAuthCheck(false);

  const tabs = [
    {
      label: 'Saved Servers',
      key: 'saved-servers',
      children: <SavedServers />,
    },
    {
      label: 'Saved Communities',
      key: 'saved-communities',
      children: <SavedGroups />,
    },
  ];

  return (
    <section className="page">
      <Tabs
        items={tabs}
        defaultActiveKey={getDefaultSavedTab()}
        onChange={(e) => setDefaultSavedTab(e)}
      />
    </section>
  );
}
