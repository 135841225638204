import { apiURL } from 'api/api';
import { AccountTokenViewModel } from 'types/accounttoken';

export interface AccountTokensResponse {
  accountTokens: AccountTokenViewModel[];
}
export const getAccountTokensRoute = () => apiURL('/accounttokens');

export interface AccountTokenResponse {
  accountToken: AccountTokenViewModel;
}
export const generateAccountTokenRoute = () => apiURL('/accounttokens');