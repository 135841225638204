export const colorBlock = (color: string) => (
  <span
    style={{
      width: '12px',
      height: '12px',
      display: 'inline-block',
      backgroundColor: `#${color}`,
      marginRight: '5px',
    }}
  />
);
