import { useContext, useEffect, useState } from 'react';
import { Button, Dropdown, MenuProps, message } from 'antd';
import { LiveServer, LiveVersions } from 'types/liveserver';
import ServerModal from 'components/ServerTable/ServerModal';
import { EllipsisOutlined } from '@ant-design/icons';
import { MenuInfo } from 'rc-menu/lib/interface';
import { DSTCharacter } from 'types/dstcharacter';
import { Platform } from 'types/platform';
import { UserContext } from 'context/UserContext';
import { apiURL, isErrorResponse, useAxiosAuth, useAxiosPublic } from 'api/api';
import {
  GetTrackedServerResponse,
  getTrackedServersRoute,
  RemoveSavedServerRequest,
  removeSavedServerRoute,
  saveServerRoute,
  ServerRequest,
  ServerResponse,
  stopTrackingServerRoute,
  trackServerRoute,
} from 'services/servers';
import { getMiscServerDataRoute, MiscServerDataResponse } from 'services/liveservers';
import { ErrorResponse } from '@remix-run/router';
import { Server } from 'types/server';
import CustomTable, { CustomColumnType } from 'components/shared/CustomTable';
import { PaginatedSearchInfo, seasonFilterValues } from 'components/shared/TableInterfaces';
import { useSearchParams } from 'react-router-dom';
import { parseServerLink } from 'lib/misc';
import './style.scss';
import { VersionIcon } from 'components/shared/ServerIcons';

interface Props {
  servers: LiveServer[] | null;
  totalServerCount: number;

  loadTableData?: (info: PaginatedSearchInfo) => void;
  removeSavedServer?: (serverID: string) => void;
}

export default function ServerTable(props: Props) {
  const { authUser, setAuthUser } = useContext(UserContext);
  const axiosPublic = useAxiosPublic();
  const axiosAuth = useAxiosAuth();

  // Misc data
  const [versions, setVersions] = useState<LiveVersions | null>(null);
  const [dstCharacters, setDSTCharacters] = useState<DSTCharacter[] | null>(null);
  const [platforms, setPlatforms] = useState<Platform[] | null>(null);

  const [trackedServers, setTrackedServers] = useState<Server[]>([] as Server[]);

  const [selectedServer, setSelectedServer] = useState<LiveServer | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    findServer();
  }, [props.servers]);

  useEffect(() => {
    fetchMiscServerData();
  }, []);

  const fetchTrackedServers = async () => {
    let res = await axiosAuth.get<never, GetTrackedServerResponse>(getTrackedServersRoute());
    if (isErrorResponse(res)) {
      return;
    }

    setTrackedServers(res.trackedServers);
  };

  const fetchMiscServerData = async () => {
    let res = await axiosPublic.get<never, MiscServerDataResponse | ErrorResponse>(
      getMiscServerDataRoute()
    );
    if (isErrorResponse(res)) {
      return;
    }

    setVersions(res.liveVersions);
    setDSTCharacters(res.dstCharacters);
    setPlatforms(res.platforms);
  };

  const findServer = async () => {
    if (!props.loadTableData) return;

    const serverID = searchParams.get('id');
    if (props.servers && serverID) {
      let encode = parseServerLink(serverID);
      if (encode === '') {
        searchParams.delete('id');
        setSearchParams(searchParams);
        message.info('Server not found');
        return;
      }

      let res = await axiosPublic.get<never, { liveServer: LiveServer }>(
        apiURL(`/liveservers/${serverID}`)
      );
      if (isErrorResponse(res)) {
        message.info('Server not found');
        searchParams.delete('id');
        setSearchParams(searchParams);
        return;
      }

      setSelectedServer(res.liveServer);
    }
  };

  const handleNameCol = (name: string, record: LiveServer) => {
    if (!versions) return name;

    return (
      <>
        <VersionIcon version={record.version} versions={versions} /> {name}
      </>
    );
  };

  const columns = () => {
    const columns: CustomColumnType<LiveServer>[] = [
      {
        title: 'Server Name',
        key: 'name',
        dataIndex: 'name',
        render: handleNameCol,
        width: 200,
        searchable: props.loadTableData && {
          dbColumnName: 'name',
        },
      },
      {
        title: 'Day',
        key: 'day',
        dataIndex: 'day',
        width: 75,
      },
      {
        title: 'Season',
        key: 'season',
        dataIndex: 'season',
        width: 100,
        filters: props.loadTableData ? undefined : seasonFilterValues,
        onFilter: props.loadTableData
          ? undefined
          : (value, record) => record.season.indexOf(value as string) === 0,
        searchable: props.loadTableData && {
          dbColumnName: 'season',
          exactValue: true,
          filterOptions: seasonFilterValues,
        },
      },
      {
        title: 'Mode',
        key: 'mode',
        dataIndex: 'mode',
        width: 100,
        searchable: props.loadTableData && {
          dbColumnName: 'mode',
        },
      },
      {
        title: 'Players',
        key: 'playerCount',
        dataIndex: 'playerCount',
        render: (playerCount: string, record: LiveServer) => `${playerCount}/${record.playerMax}`,
        width: 50,
      },
    ];

    // Add if user is part of a tracking group
    if (authUser && authUser.selectedGroup) {
      columns.push({
        title: '',
        key: 'actions',
        width: 100,
        render: renderActionColumn,
      });
    }

    return columns;
  };

  const renderActionColumn = (liveServer: LiveServer) => {
    const items: MenuProps['items'] = [];

    if (!isServerSaved(liveServer)) {
      items.push({
        label: 'Save Server',
        key: 'saveServer',
      });
    } else {
      items.push({
        label: 'Remove Saved Server',
        key: 'unsaveServer',
        danger: true,
      });
    }

    if (authUser?.selectedGroup) {
      if (!isServerTracked(liveServer)) {
        items.push({
          label: 'Track Server',
          key: 'trackServer',
        });
      } else {
        items.push({
          label: 'Stop Tracking Server',
          key: 'stopTrackingServer',
          danger: true,
        });
      }
    }

    return (
      <Dropdown
        className="table-actions"
        menu={{
          items,
          onClick: (e) => handleMenuClick(e, liveServer),
        }}
        trigger={['click']}
        placement="bottomRight"
        arrow
      >
        <Button
          type="link"
          size="small"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <EllipsisOutlined />
        </Button>
      </Dropdown>
    );
  };

  const isServerSaved = (ls: LiveServer) => {
    if (!authUser?.savedServers || authUser.savedServers.length === 0) return false;
    return (
      authUser.savedServers.findIndex(
        (ss) => ss.name === ls.name && ss.host === ls.host && ss.platformID === ls.platformID
      ) !== -1
    );
  };

  const isServerTracked = (ls: LiveServer) => {
    if (trackedServers.length === 0) return false;
    return (
      trackedServers.findIndex(
        (ss) => ss.name === ls.name && ss.host === ls.host && ss.platformID === ls.platformID
      ) !== -1
    );
  };

  const handleMenuClick = (e: MenuInfo, liveServer: LiveServer) => {
    e.domEvent.stopPropagation();
    e.domEvent.preventDefault();

    switch (e.key) {
      case 'saveServer':
        handleSaveServer(liveServer);
        break;
      case 'unsaveServer':
        removeSavedServer(getServerIDByIdentifiers(liveServer));
        break;
      case 'trackServer':
        handleTrackServer(liveServer);
        break;
      case 'stopTrackingServer':
        handleStopTrackServer(liveServer);
        break;
    }
  };

  const getServerIDByIdentifiers = (ls: LiveServer) => {
    if (!authUser) return '';
    let index = authUser.savedServers.findIndex(
      (ss) => ss.name === ls.name && ss.host === ls.host && ss.platformID === ls.platformID
    );
    if (index === -1) return '';
    return authUser.savedServers[index].id;
  };

  const handleSaveServer = async (liveServer: LiveServer) => {
    let res = await axiosAuth.post<never, ServerResponse>(saveServerRoute(), {
      name: liveServer.name,
      host: liveServer.host,
      platformID: liveServer.platformID,
    } as ServerRequest);

    if (isErrorResponse(res)) {
      message.error(res.data);
      console.warn(res.data);
      return;
    }

    message.success('Server added to saved list');
    setTimeout(
      () =>
        setAuthUser(
          (prev) => prev && { ...prev, savedServers: [...prev.savedServers, res.server] }
        ),
      200
    );
  };

  const removeSavedServer = async (serverID: string) => {
    let res = await axiosAuth.post(removeSavedServerRoute(), {
      serverID,
    } as RemoveSavedServerRequest);
    if (isErrorResponse(res)) {
      message.error('There was an issue removing the saved server');
      console.warn(res.data);
      return;
    }

    if (props.removeSavedServer) props.removeSavedServer(serverID);
    message.success('Removed server from saved list');
    setTimeout(
      () =>
        setAuthUser(
          (prev) =>
            prev && { ...prev, savedServers: prev.savedServers.filter((p) => p.id !== serverID) }
        ),
      200
    );
  };

  const handleTrackServer = async (liveServer: LiveServer) => {
    let res = await axiosAuth.post<never, ServerResponse>(trackServerRoute(), {
      name: liveServer.name,
      host: liveServer.host,
      platformID: liveServer.platformID,
    } as ServerRequest);
    if (isErrorResponse(res)) {
      message.error(res.data);
      console.warn(res.data);
      return;
    }

    message.success(`Started tracking ${liveServer.name}`);
    setTimeout(() => setTrackedServers((prev) => [...prev, res.server]), 200);
  };

  const handleStopTrackServer = async (liveServer: LiveServer) => {
    let res = await axiosAuth.post<never, { serverID: string }>(stopTrackingServerRoute(), {
      name: liveServer.name,
      host: liveServer.host,
      platformID: liveServer.platformID,
    } as ServerRequest);
    if (isErrorResponse(res)) {
      message.error(res.data);
      console.warn(res.data);
      return;
    }

    message.success('Removed server from tracked list');
    setTimeout(() => setTrackedServers((prev) => prev.filter((p) => p.id !== res.serverID)), 200);
  };

  return (
    <>
      <CustomTable
        className="server-table"
        rowClassName="server-table-row clickable"
        rowKey={(record: LiveServer) => record.rowID}
        columns={columns()}
        loadTableData={props.loadTableData}
        dataSource={props.servers || []}
        totalRows={props.totalServerCount}
        onRow={(record: LiveServer) => {
          return {
            onClick: () => setSelectedServer(record),
          };
        }}
      />
      {versions && dstCharacters && platforms && (
        <ServerModal
          server={selectedServer}
          versions={versions}
          dstCharacters={dstCharacters}
          platforms={platforms}
          setSelectedServer={setSelectedServer}
        />
      )}
    </>
  );
}
