import { Card, Col, ColProps, Row } from 'antd';
import { StarFilledIcon, InfinityIcon } from 'assets/SVGIcons/Icons';
import { GroupType } from 'types/group';
import './styles.scss';

interface Props extends ColProps {
  groupType: GroupType;
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
  selected?: boolean;
  showTrials?: boolean;
}

const GroupTypeCard: React.FC<Props> = ({
  groupType,
  onClick,
  selected,
  showTrials = true,
  ...restOfProps
}) => {
  const cardHeader = () => {
    return (
      <>
        {groupType.name}
        {groupType.accountToken.Valid && (
          <span className="subHeader">
            {' '}
            - Group type from token {groupType.accountToken.String}
          </span>
        )}
        {groupType.featured && <StarFilledIcon className="featured" />}
      </>
    );
  };

  const getCardClass = () => {
    let cssClass = '';

    if (onClick !== undefined) cssClass += ' select-group';
    if (groupType.accountToken.Valid) cssClass += ' token-group';
    if (selected) cssClass += ' group-selected';

    return cssClass;
  };

  return (
    <Col {...restOfProps} key={groupType.id} className="group-type-card">
      <Card title={cardHeader()} className={getCardClass()} onClick={onClick}>
        <Row>
          <Col span={16}>Tracked Server Limit</Col>
          <Col className="center" span={8}>
            {groupType.trackedServerLimit !== null ? (
              groupType.trackedServerLimit
            ) : (
              <InfinityIcon />
            )}
          </Col>
          <Col span={16}>Member Limit</Col>
          <Col className="center" span={8}>
            {groupType.memberLimit !== null ? groupType.memberLimit : <InfinityIcon />}
          </Col>
          <Col span={16}>Monthly Price</Col>
          <Col className="center" span={8}>
            ${Number(groupType.usdCost).toFixed(2)} (USD)
          </Col>
          <Col span={16}>Has Ads</Col>
          <Col className="center" span={8}>
            {groupType.showAds ? 'Yes' : 'No'}
          </Col>
          {showTrials && (
            <>
              <Col span={16}>Trial Period</Col>
              <Col className="center" span={8}>
                {groupType.trialPeriodInDays} days
              </Col>
            </>
          )}
        </Row>
      </Card>
    </Col>
  );
};
export default GroupTypeCard;
