export const GroupOwnerRole = 'Owner';
export const GroupAdminRole = 'Admin';
export const GroupViewerRole = 'Viewer';

export const GroupLinkDiscord = 'Discord';
export const GroupLinkSteam = 'Steam';
export const GroupLinkTwitch = 'Twitch';

export const ValidGroupLinkTypes = [GroupLinkDiscord, GroupLinkSteam, GroupLinkTwitch];

export const CommunityRegions = [
  'North America',
  'Central America',
  'South America',
  'Europe',
  'Oceania',
  'Africa',
  'Asia',
  'South East Asia',
  'Middle East',
];

export const ValidPrivacyPolicyRoutes = [
  '/privacy-policy',
  '/yaaaaaaaaannnnnnnnnnnn',
  '/boooooooooooooooorrrring',
  '/youre-not-even-going-to-read-this-are-you',
  '/is-this-url-changing',
  '/blah-blah-blah',
];
