import React, { useState } from 'react';
import { AccountTokenResponse, generateAccountTokenRoute } from 'services/accounttoken';
import { DatePicker, Modal, Row, Select, message } from 'antd';
import { AccountTokenViewModel } from 'types/accounttoken';
import GroupTypeCard from 'components/shared/GroupTypeCard';
import { isErrorResponse, useAxiosAuth } from 'api/api';
import { getContainer } from 'lib/rendering';
import { GroupType } from 'types/group';
import dayjs from 'dayjs';

interface Props {
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setAccountTokens: React.Dispatch<React.SetStateAction<AccountTokenViewModel[]>>;
  groupTypes: GroupType[];
}

export default function AccountTokenModal(props: Props) {
  const axiosAuth = useAxiosAuth();

  const [expiryDate, setExpiryDate] = useState<dayjs.Dayjs | null>(null);
  const [groupTypeID, setGroupTypeID] = useState<number | undefined>(undefined);

  const generateAccountToken = async () => {
    let body = {
      groupTypeID: groupTypeID,
      expiryDate: expiryDate?.startOf('second').valueOf() || 0,
    };

    let res = await axiosAuth.post<never, AccountTokenResponse>(generateAccountTokenRoute(), body);
    if (isErrorResponse(res)) {
      message.error(res.data);
      console.error(res.data);
      return;
    }

    props.setAccountTokens((p) => {
      let prev = [...p];
      prev.unshift(res.accountToken);
      return prev;
    });

    setExpiryDate(null);
    setGroupTypeID(undefined);
    props.setModalOpen(false);
  };

  const displayGroupTypeCard = () => {
    let gt = props.groupTypes.find((g) => g.id === groupTypeID);

    if (gt)
      return (
        <Row>
          <GroupTypeCard groupType={gt} span="24" />
        </Row>
      );

    return null;
  };

  return (
    <Modal
      title="Generate Account Token"
      open={props.modalOpen}
      onCancel={() => props.setModalOpen(false)}
      className="update-modal"
      getContainer={getContainer('admin-settings')}
      onOk={generateAccountToken}
    >
      <DatePicker
        placeholder="Expiry date"
        format="YYYY-MM-DD HH:mm:ss"
        value={expiryDate}
        style={{ width: '100%' }}
        showTime={{ defaultValue: dayjs('00:00:00', 'HH:mm:ss') }}
        onChange={(date) => setExpiryDate(date)}
      />
      <Select
        showSearch
        allowClear
        placeholder="Select a group type"
        style={{ width: '100%' }}
        value={groupTypeID}
        filterOption={(input, option) =>
          (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())
        }
        options={props.groupTypes.map((gt) => ({
          value: gt.id,
          label: gt.name,
        }))}
        onChange={(e) => setGroupTypeID(e)}
      />
      {displayGroupTypeCard()}
    </Modal>
  );
}
