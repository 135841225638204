import randomWords from 'assets/random_words.json';
import { Buffer } from 'buffer';

const deliminator = '|';

export function getServerLink(host: string, platformID: number, name: string) {
  const s = [host, platformID, name].join(deliminator);
  const utf8Bytes = Buffer.from(s, 'utf8');
  return Buffer.from(utf8Bytes).toString('base64');
}

export function parseServerLink(b64Encode: string) {
  const decodedBytes = Buffer.from(b64Encode, 'base64');
  const s = decodedBytes.toString('utf8');

  const arr = s.split(deliminator);
  if (arr.length < 3) return '';

  const host = arr[0];
  const platformID = arr[1];
  const name = arr.slice(2).join(deliminator);

  return [host, Number(platformID), name];
}

export function getRandomWords(count: number) {
  const selectedWords: string[] = [];
  for (let i = 0; i < count; i++) {
    const randomIndex: number = Math.floor(Math.random() * randomWords.length);
    selectedWords.push(randomWords[randomIndex]);
  }
  return selectedWords;
}

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};
