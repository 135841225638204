import { useContext } from 'react';
import { MenuProps } from 'antd';
import {
  BinocularsIcon,
  GroupIcon,
  SavedServersIcon,
  ServerIcon,
  StatisticsIcon,
  TrackedServersIcon,
} from 'assets/SVGIcons/Icons';
import { UserContext } from 'context/UserContext';
import { Link } from 'react-router-dom';

export const NavigationItems = () => {
  const { authUser } = useContext(UserContext);

  const items: MenuProps['items'] = [
    {
      label: <Link to={'/'}>Live Servers</Link>,
      key: 'liveservers',
      icon: <ServerIcon />,
    },
    {
      label: <Link to={'/livestatistics'}>Live Statistics</Link>,
      key: 'livestatistics',
      icon: <StatisticsIcon />,
    },
    {
      label: <Link to={'/communities'}>Communities</Link>,
      key: 'communities',
      icon: <BinocularsIcon />,
    },
  ];

  if (authUser) {
    items.push(
      {
        label: <Link to={'/savedservers'}>Saved Servers</Link>,
        key: 'savedservers',
        icon: <SavedServersIcon />,
      },
      {
        label: <Link to={'/trackedservers'}>Tracked Servers</Link>,
        key: 'trackedservers',
        icon: <TrackedServersIcon />,
      },
      {
        label: <Link to={'/groupprofile'}>Group Profile</Link>,
        key: 'groupprofile',
        icon: <GroupIcon />,
      }
    );
  }

  return items;
};
