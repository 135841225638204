import { apiURL } from 'api/api';
import { LiveServer } from 'types/liveserver';
import { PlayerHeatmap, Server, TrackedServerViewModel } from 'types/server';

export interface ServerRequest {
  name: string;
  host: string;
  platformID: number;
}

export interface ServerResponse {
  server: Server;
}

//// Saved server stuff
export const saveServerRoute = () => apiURL('/servers/save');

export interface GetSavedServerResponse {
  savedServers: Server[];
  liveServers: LiveServer[];
}
export const getSavedServersRoute = () => apiURL('/servers/saved');

export interface RemoveSavedServerRequest {
  serverID: string;
}
export const removeSavedServerRoute = () => apiURL('/servers/remove-saved');

//// Tracked server stuff
export interface GetTrackedServerResponse {
  trackedServers: Server[];
  previouslyTrackedServers: Server[];
}
export const getTrackedServersRoute = () => apiURL('/servers/tracked');

export const trackServerRoute = () => apiURL('/servers/track');
export const stopTrackingServerRoute = () => apiURL('/servers/track/stop');

export interface TrackedServerDataResponse {
  trackedServerData: TrackedServerViewModel;
}

export const getTrackedServerDataRoute = (serverID: string, startDate: number, endDate: number) =>
  apiURL(`/servers/tracked/${serverID}/${startDate}/${endDate}`);

export interface PlayerHeatmapResponse {
  playerHeatmap: PlayerHeatmap[];
}
export const getPlayerHeatmapRoute = (serverID: string, year: number) =>
  apiURL(`/servers/tracked/heatmap/${serverID}/${year}`);
