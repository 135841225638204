import { Col, Collapse, CollapseProps, Row } from 'antd';
import ExternalLink from 'components/shared/ExternalLink';

export default function FAQ() {
  const faqItems = [
    // {
    //   label: 'Why are there advertisements?',
    //   children: (
    //     <>
    //       It costs a decent amount of money to keep this site operational. I have only placed a few
    //       unintrusive ads on the site to help me pay for hosting costs.
    //     </>
    // },
    {
      label: 'What is the difference between a saved server and a tracked server?',
      children: (
        <>
          <p>
            A <b>saved server</b> is a server that you've pinned so you can easily see it later
            without having to search through thousands of servers. Saved servers are private per
            user. No one else can see them, even if you're apart of one more group. Anyone with an
            account can save servers. They provide no functionality beyond ease of access.
          </p>
          <p>
            A <b>tracked server</b> is a server that you wish to record historical data for (eg.
            people who played on the server and when). Tracked servers are per group. Only the
            members of that group can see which servers that group is tracking. Any group can track
            any server. You'll only see historical data for the period you tracked that server. For
            example if you track a server from March 2024 - April 2024, you'll only see historical
            data for that period.
          </p>
        </>
      ),
    },
    {
      label: 'How are the public communities sorted?',
      children: (
        <>
          Every community is given a ranking. The higher the ranking the higher their community is
          shown on the list. This is determined by a variety of factors including how active their{' '}
          <b>public</b> servers are. Group rankings are calculated and updated every Sunday.
        </>
      ),
    },
    {
      label: 'What characters skins are used?',
      children: (
        <>
          If you're logged in you'll notice a random character skin head will show up in place of
          the generic login icon. The ones that are displayed are my favorites!
        </>
      ),
    },
    {
      label: 'What is Revolt and why should I join?',
      children: (
        <>
          Revolt is a Discord competitor. My Revolt server is the official community for this web
          application. You should join the Revolt server if you're interested in any the following:
          <ul>
            <li>You've found a bug you'd like to report</li>
            <li>You'd like to stay informed on future plans/changes</li>
            <li>You'd like to suggest potential features</li>
            <li>You'd like to vote on potential features</li>
            <li>You need support of some kind</li>
            <li>You want to offer support of some kind</li>
            <li>You just want to drop in and say hello 👋</li>
          </ul>
        </>
      ),
    },
    {
      label: 'Why do you use Revolt instead of Discord?',
      children: (
        <>
          While I love Discord and I'm an admin over at DST Survivors (a Discord group) the
          community for this web application is on Revolt. One reason is that Discord is a major
          data collector. They collect an enormous amount of data. Secondly Discord over the years
          has made changes that have moved it from a community for gamers (and the alike) to more of
          a social network. The biggest grievance (imo) is the changes to{' '}
          <ExternalLink url="https://discord.com/blog/usernames" text="usernames" />. On top of this
          Discord has no real competition. Because of this I want to support a new chat client. I
          chose Revolt because it is more privacy focused, open sourced, and has many free features
          that you'd have to pay for on Discord (via Nitro). I can also self host it in the future
          if I desire. Revolt is in active development and I'm excited to see it grow over the
          coming years.
          <h4>Revolt Links</h4>
          <ul>
            <li>
              <ExternalLink url="https://revolt.chat/" text="Their website" />
            </li>
            <li>
              <ExternalLink url="https://github.com/revoltchat" text="Their Github" />
            </li>
            <li>
              <ExternalLink
                url="https://trello.com/b/4e2O7tge/revolt-roadmap"
                text="Their roadmap"
              />
            </li>
          </ul>
        </>
      ),
    },
    {
      label: 'Why am I being asked to verify my Klei ID?',
      children: (
        <>
          Verifying your Klei ID is totally optional and only provides benefits if you're apart of a
          group. Verifying your Klei ID has the following benefits.
          <ol>
            <li>
              If your group is listed as a community group and you host servers, your live servers
              will be listed on the community tab. The members of the group must validate their
              KleiID before their servers are listed.
            </li>
            <li>
              In order to a group's ranking to be properly evaluated you group members (that host
              servers) must have verified their KleiID.
            </li>
          </ol>
        </>
      ),
    },
  ];

  const items: CollapseProps['items'] = faqItems.map((item, index) => {
    return {
      key: index,
      label: item.label,
      children: item.children,
    };
  });

  return (
    <Row className="content">
      <Col span={24}>
        <h1>FAQ</h1>
        <Collapse size="large" items={items} />
      </Col>
    </Row>
  );
}
