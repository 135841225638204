import React, { useEffect, useState } from 'react';
import { getLiveServerRoute, LiveServerResponse } from 'services/liveservers';
import { LiveServer, LiveVersions, Mod } from 'types/liveserver';
import { isErrorResponse, useAxiosPublic } from 'api/api';
import { Alert, Button, Col, Divider, Image, Modal, Row, Spin, Tag, Tooltip, message } from 'antd';
import {
  DedicatedServerIcon,
  HostedServerIcon,
  LinkIcon,
  PasswordProtectedIcon,
  ServerPausedIcon,
  SteamIcon,
} from 'assets/SVGIcons/Icons';
import ExternalLink from 'components/shared/ExternalLink';
import { Platform } from 'types/platform';
import { DSTCharacter } from 'types/dstcharacter';
import { ErrorResponse } from '@remix-run/router';
import { RenderSeasonIcon, VersionIcon } from 'components/shared/ServerIcons';
import { getServerLink } from 'lib/misc';
import { useSearchParams } from 'react-router-dom';

interface Props {
  server: LiveServer | null;
  setSelectedServer: React.Dispatch<React.SetStateAction<LiveServer | null>>;

  versions: LiveVersions;
  dstCharacters: DSTCharacter[];
  platforms: Platform[];
}

export default function ServerModal(props: Props) {
  const axiosPublic = useAxiosPublic();
  const [liveServer, setLiveServer] = useState<LiveServer | null>(null);
  const [serverNotFound, setSeverNotFound] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    setSeverNotFound(false);
    getLiveServerData();
  }, [props.server]);

  const getLiveServerData = async () => {
    if (props.server) {
      let res = await axiosPublic.get<never, LiveServerResponse | ErrorResponse>(
        getLiveServerRoute(props.server.kleiLobby, props.server.rowID)
      );
      if (isErrorResponse(res)) {
        setSeverNotFound(true);
        return;
      }

      setLiveServer(res.liveServer);
    }
  };

  const copyPermalink = () => {
    if (!props.server) return;

    if (navigator.clipboard && typeof navigator.clipboard.writeText === 'function') {
      const serverKey = getServerLink(
        props.server.host,
        props.server.platformID,
        props.server.name
      );
      const url = window.location.origin + '/liveservers?id=' + serverKey;

      navigator.clipboard.writeText(url).then(() => {
        message.success('Permalink copied to clipboard!', 2);
      });
    }
  };

  const modalTitle = () => {
    if (!props.server) return;

    return (
      <div className="modal-header">
        <Row justify="space-between" align="middle">
          <Col className="server-name">
            {props.server.name} {getHeaderIcons()}
          </Col>
          <Col className="version">
            v. {props.server.version}
            <Tooltip placement="left" title="Copy permalink">
              <Button onClick={copyPermalink} className="perma-btn" type="link">
                <LinkIcon className="clickable" />
              </Button>
            </Tooltip>
          </Col>
        </Row>
        <span className="description">{props.server.description}</span>
      </div>
    );
  };

  const getHeaderIcons = () => {
    if (!liveServer) return;

    return (
      <div className="server-icons">
        <Tooltip placement="top" title={liveServer.dedicated ? 'Dedicated' : 'Hosted'}>
          {liveServer.dedicated ? <DedicatedServerIcon /> : <HostedServerIcon />}
        </Tooltip>
        {liveServer.platformID === 1 && (
          <Tooltip placement="top" title="Steam">
            <SteamIcon />
          </Tooltip>
        )}
        <RenderSeasonIcon season={liveServer.season} />
        {liveServer.passwordProtected && (
          <Tooltip placement="top" title="Password Protected">
            <PasswordProtectedIcon />
          </Tooltip>
        )}
        {liveServer.paused && (
          <Tooltip placement="top" title="Server Paused">
            <ServerPausedIcon />
          </Tooltip>
        )}
        <VersionIcon version={liveServer.version} versions={props.versions} />
      </div>
    );
  };

  const renderPlayersList = () => {
    if (liveServer?.players.length === 0) return <i>No players</i>;

    return (
      <div className="players">
        {liveServer?.players.map((player, i) => (
          <Row key={i} gutter={4} align="middle">
            <Col className="name">{player.name}</Col>
            <Col>
              {player.prefab === '' ? (
                <i>In lobby</i>
              ) : (
                <>
                  {isModCharacter(player.prefab) ? (
                    <>[{player.prefab}]</>
                  ) : (
                    <Tooltip title={getDSTCharacterFromPrefab(player.prefab)}>
                      <Image
                        preview={false}
                        width={35}
                        src={`/images/dst_characters/default/${player.prefab}.png`}
                        alt={player.prefab}
                      />
                    </Tooltip>
                  )}
                </>
              )}
            </Col>
            <Col>
              <div className="color-box" style={{ backgroundColor: '#' + player.color }}>
                {player.color}
              </div>
            </Col>
            <Col>
              <ExternalLink
                text="Steam Profile"
                url={`https://steamcommunity.com/profiles/${player.steamID}`}
              />
            </Col>
          </Row>
        ))}
      </div>
    );
  };

  const isModCharacter = (prefab: string) => {
    let character = props.dstCharacters.filter((c) => c.prefab === prefab);
    return character.length === 0;
  };

  const getDSTCharacterFromPrefab = (prefab: string) => {
    let character = props.dstCharacters.filter((c) => c.prefab === prefab);
    if (character.length > 0) return character[0].character;
    return prefab;
  };

  const renderModsList = () => {
    if (liveServer?.mods.length === 0) {
      return <i>No mods</i>;
    }

    return (
      <ul>
        {liveServer?.mods.map((mod, i) => (
          <li key={i}>
            <ExternalLink url={getModUrl(mod)}>{mod.name}</ExternalLink>
            <span className="modV">[{mod.version}]</span>
          </li>
        ))}
      </ul>
    );
  };

  const getModUrl = (mod: Mod) => {
    let validModID = getModID(mod.id);
    if (liveServer?.platformID === 1 && validModID)
      return `https://steamcommunity.com/sharedfiles/filedetails?id=${validModID}`;
    return `https://steamcommunity.com/workshop/browse/?appid=322330&searchtext=${mod.name}`;
  };

  const getModID = (id: string) => {
    let idArr = id.split(' ');
    let nID = parseInt(idArr[0]);
    if (!Number.isNaN(nID)) return nID;
    return undefined;
  };

  return (
    <Modal
      className="server-details"
      title={modalTitle()}
      open={!!props.server}
      footer={false}
      closable={false}
      width={1500}
      onCancel={() => {
        props.setSelectedServer(null);
        setLiveServer(null);
        searchParams.delete('id');
        setSearchParams(searchParams);
      }}
    >
      {serverNotFound ? (
        <Alert message="Server not found" type="info" />
      ) : !liveServer ? (
        <div className="loading-details">
          <Spin />
        </div>
      ) : (
        <div>
          <Row>
            <Col span={24}>Day: {liveServer.day}</Col>
            <Col span={24}>
              Steam Group:{' '}
              {liveServer.steamGroupID !== '' && (
                <ExternalLink
                  text={liveServer.steamGroupID}
                  url={`https://steamcommunity.com/gid/${liveServer.steamGroupID}`}
                />
              )}
            </Col>
            <Col span={24}>
              {liveServer.kleiLobby} — {liveServer.rowID}
            </Col>
          </Row>
          <Divider />
          <Row className="details" gutter={32}>
            <Col span={8}>
              <h2>
                Players
                <span className="sub-header">
                  ({liveServer.playerCount}/{liveServer.playerMax})
                </span>
              </h2>
              {renderPlayersList()}
            </Col>
            <Col span={8} className="borders">
              <h2>
                Mods
                <span className="sub-header">
                  {liveServer.mods.length > 0 ? `(${liveServer.mods.length})` : null}
                </span>
              </h2>
              {renderModsList()}
            </Col>
            <Col span={8}>
              <h2>Tags</h2>
              <ul>
                {liveServer.tags.split(',').map((t, i) => (
                  <Tag key={i}>{t}</Tag>
                ))}
              </ul>
            </Col>
          </Row>
        </div>
      )}
    </Modal>
  );
}
