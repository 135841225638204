import { Col, Row } from 'antd';

export function ErrorPage() {
  const names = ['boss', 'chief', 'user'];
  const randomIndex = Math.floor(Math.random() * names.length);

  return (
    <section className="page page404">
      <Row>
        <Col span={24}>
          <div className="text404">~404~</div>
          <div className="desc">Not sure what you're looking for there {names[randomIndex]}</div>
          {/* <img src='/images/404_images/Willow_lulpoint.png' />
        <small>Artwork by:</small> */}
        </Col>
      </Row>
    </section>
  );
}
