import { useContext, useEffect, useState } from 'react';
import { Button, Col, Input, Row, message } from 'antd';
import { apiURL, isErrorResponse, useAxiosAuth, useAxiosPublic } from 'api/api';
import { UserContext } from 'context/UserContext';
import { AxiosResponse } from 'axios';
import { useSearchParams } from 'react-router-dom';

export default function PasswordReset() {
  const [getParams] = useSearchParams();

  const { authUser } = useContext(UserContext);
  const axiosPublic = useAxiosPublic();
  const axiosAuth = useAxiosAuth();

  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [errMsg, setErrMsg] = useState('');

  // Hide error message while typing
  useEffect(() => {
    // don't show if they have not clicked submit
    if (errMsg === '') return;
    if (password === passwordConfirmation) setErrMsg('');
  }, [password, passwordConfirmation]);

  const resetPassword = async () => {
    setErrMsg('');
    if (password !== passwordConfirmation) {
      setErrMsg("Passwords don't match");
      return;
    }
    if (password === '') {
      setErrMsg('Please enter a new password');
      return;
    }

    // Check if user is logged in
    let res: AxiosResponse<any, any>;
    if (authUser) {
      res = await axiosAuth.post(apiURL('/auth/reset-password'), {
        password,
        passwordConfirmation,
      });
    } else {
      let userUUID = getParams.get('uid');
      let token = getParams.get('token');

      if (!userUUID || !token) {
        message.error('email or token not provided');
        return;
      }

      res = await axiosPublic.post(apiURL('/auth/reset-password'), {
        userUUID,
        hexToken: token,
        password,
        passwordConfirmation,
      });
    }

    if (isErrorResponse(res)) {
      if (res.data === '') message.error('there was an issue updating your password');
      else message.error(res.data);
      return;
    }
    message.success('Password updated');

    setPassword('');
    setPasswordConfirmation('');
  };

  return (
    <>
      <h4>Reset Password</h4>
      <Row gutter={[0, 8]} className='grey-space'>
        <Col span={24}>
          <Input.Password
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="password"
          />
        </Col>
        <Col span={24}>
          <Input.Password
            value={passwordConfirmation}
            onChange={(e) => setPasswordConfirmation(e.target.value)}
            placeholder="confirm password"
          />
        </Col>
        {errMsg !== '' && <span className="err">{errMsg}</span>}
        <Col span={24}>
          <Button type="primary" onClick={resetPassword} style={{ width: '100%' }}>
            Reset Password
          </Button>
        </Col>
      </Row>
    </>
  );
}
