import { apiURL } from 'api/api';
import { Community, Group, GroupMemberViewModel, GroupRole } from 'types/group';
import { LiveServer } from 'types/liveserver';

export interface GetGroupRouteResponse {
  group: Group;
}
export function getGroupRoute(groupID: string) {
  return apiURL(`/group/${groupID}`);
}

export interface GetCommunitiesResponse {
  communities: Community[];
  totalRows: number
}

export interface GetCommunityServerResponse {
  liveServers: LiveServer[];
}

export interface GetGroupsRouteResponse {
  groups: Group[];
}
export const getGroupsRoute = () => apiURL(`/groups`);

export interface ExtendGroupSubscriptionRequest {
  groupID: string
  amount: number
  period: string
}
export const extendGroupSubscriptionRoute = () => apiURL(`/group/extend-subscription`);

export interface EndGroupSubscriptionRequest {
  groupID: string
}
export const endGroupSubscriptionRoute = () => apiURL(`/group/end-subscription`);

export interface GetGroupRolesResponse {
  groupRoles: GroupRole[];
}
export function getGroupRolesRoute() {
  return apiURL(`/group/roles`);
}

export interface CreateGroupRequest {
  name: string;
  isPublic: boolean
}
export interface CreateGroupResponse {
  group: Group;
}
export function createGroupRoute() {
  return apiURL(`/group/create`);
}

export interface InviteGroupMemberRequest {
  userID: number;
  roleID: number;
  listDedicated: boolean;
  listHosted: boolean;
}

export interface InviteGroupMemberResponse {
  invitedUser: GroupMemberViewModel;
}
export function inviteGroupMemberRoute() {
  return apiURL(`/group/invite-member`);
}

export function updateGroupMemberRoute() {
  return apiURL(`/group/update-member`);
}

export interface RemoveGroupMemberRequest {
  userID: number;
}

export function removeGroupMemberRoute() {
  return apiURL(`/group/remove-member`);
}

export interface AcceptRejectGroupInviteRequest {
  groupID: string;
}

export interface AcceptGroupInviteResponse {
  group: GroupMemberViewModel;
}

export const acceptGroupInviteRoute = () => apiURL(`/group/accept-invite`);

export const rejectGroupInviteRoute = () => apiURL(`/group/reject-invite`);
