import React, { ReactNode, useEffect, useRef } from 'react';

interface Props {
  // Whatever html element that when clicked will open this box
  linkedRef: React.Ref<HTMLInputElement>;
  // Method that will be called when closing
  closePopUpBox: () => void;
  // Whatever you want to show in the pop-up box
  children: ReactNode;

  boxWidth?: string;
}

// PopUpBox will open when the passed in reference is clicked and will close when
// that reference is clicked again or anywhere else on the screen is clicked
export default function PopUpBox(props: Props) {
  const popUpBox = useRef(null);

  useEffect(() => {
    window.addEventListener('mousedown', closeLoginBox);
  }, []);

  const closeLoginBox = (e: any) => {
    if (
      popUpBox.current && // @ts-ignore
      !popUpBox.current.contains(e.target) && // @ts-ignore
      props.linkedRef.current && // @ts-ignore
      !props.linkedRef.current.contains(e.target)
    ) {
      props.closePopUpBox();
      window.removeEventListener('mousedown', closeLoginBox);
    }
  };

  return (
    <div className="pop-up-box" style={{ width: props.boxWidth || '275px' }} ref={popUpBox}>
      {props.children}
    </div>
  );
}
