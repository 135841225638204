import { apiURL } from 'api/api';
import { GroupMemberViewModel } from 'types/group';
import { User } from 'types/user';

export interface GetUsersResponse {
  users: User[];
}

export const getUsersRoute = () => apiURL('/users');

export const getInviteableUsersRoute = () => apiURL('/users/inviteableusers');

export interface GetUserNotificationsResponse {
  groupInvites: GroupMemberViewModel[];
}

export const getUserNotificationsRoute = () => apiURL('/users/notifications');

export interface GetUserGroupsResponse {
  userGroups: GroupMemberViewModel[];
}
export const getUserGroupsRoute = () => apiURL('/group');
