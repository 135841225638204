import { useState } from 'react';
import { apiURL, isErrorResponse, useAxiosAuth } from 'api/api';
import { Button, Col, Row, message, notification } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import Input from 'antd/es/input/Input';

export default function VerifyEmail() {
  const axiosAuth = useAxiosAuth();

  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const requestEmail = async () => {
    if (email === '') {
      message.error('Please enter your email');
      return;
    } else if (!email.includes('@') || !email.includes('.')) {
      message.error('Please enter a valid email');
      return;
    }

    setLoading(true)
    let res = await axiosAuth.post(apiURL(`/users/request-email`), { email });
    setLoading(false)
    if (isErrorResponse(res)) {
      message.error(res.data);
      console.warn(res.data);
      return;
    }

    notification.success({
      message: 'Email requested',
      description: 'Please check your inbox (including spam folder) for an email verification link',
      duration: 10,
    });
  };

  return (
    <>
      <h4>Verify Email</h4>
      <Row gutter={[0, 8]} className='grey-space'>
        <Col span={24}>
          <Input placeholder="email" value={email} onChange={(e) => setEmail(e.target.value)} />
        </Col>
        <Col span={24}>
          <Button
            type="primary"
            disabled={loading}
            onClick={requestEmail}
            icon={loading && <LoadingOutlined />}
            style={{ width: '100%' }}
          >
            Request Email
          </Button>
        </Col>
      </Row>
    </>
  );
}
