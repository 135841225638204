import Communities from 'components/Communities';
import { useAuthCheck } from 'hooks/use_auth_check';

export default function SavedGroups() {
  useAuthCheck(false);
  
  return (
    <section className="saved-groups">
      <Communities onlySaved />
    </section>
  );
}
