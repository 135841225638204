import { MehOutlined } from '@ant-design/icons';
import { Col, Popover, Row } from 'antd';
import { AboutIcon, HeartFilledIcon, SettingsIcon } from 'assets/SVGIcons/Icons';
import { UserContext } from 'context/UserContext';
import { _primaryPurple } from 'lib/colors';
import { ValidPrivacyPolicyRoutes } from 'lib/constants';
import { scrollToTop } from 'lib/misc';
import { useContext } from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {
  const { authUser } = useContext(UserContext);

  const getContent = () => {
    const arr = [
      'love',
      'blood 🩸, sweat 💦, & tears 😭',
      'starving',
      'the power of friendship',
      'magic 🧙‍♂️',
      '🌈 & 🦄',
      '🦾',
      'flower power 🌸',
      'aliens 👽',
      'sunshine 🌞 and stardust 🌚',
    ];

    return arr[Math.floor(Math.random() * arr.length)];
  };

  const getFooterMsg = () => {
    let hiddenMsg = getContent();
    let preMgs = 'with';
    if (hiddenMsg === 'starving') preMgs = 'while';

    return (
      <>
        Made {preMgs + ' '}
        <Popover content={hiddenMsg}>
          <HeartFilledIcon style={{ color: _primaryPurple }} />
        </Popover>{' '}
        by 𝐗-𝐥𝐞𝐦
      </>
    );
  };

  const getPrivacyPolicyUrl = () =>
    ValidPrivacyPolicyRoutes[Math.floor(Math.random() * ValidPrivacyPolicyRoutes.length)];

  return (
    <footer className="footer">
      <div className="love">{getFooterMsg()}</div>

      <Row className="bottom-links" justify="space-around">
        <Col className="links">
          <h4>
            <AboutIcon /> Site info
          </h4>
          <ul>
            <li>
              <Link to={'/site-info?tab=about'} onClick={() => scrollToTop()}>
                About
              </Link>
            </li>
            <li>
              <Link to={'/site-info?tab=faq'} onClick={() => scrollToTop()}>
                FAQ
              </Link>
            </li>
            <li>
              <Link to={'/site-info?tab=support'} onClick={() => scrollToTop()}>
                Support Me!
              </Link>
            </li>
          </ul>
        </Col>
        <Col className="links">
          <h4>
            <MehOutlined /> Misc.
          </h4>
          <ul>
            <li>
              <Link to={getPrivacyPolicyUrl()} onClick={() => scrollToTop()}>
                Privacy Policy
              </Link>
            </li>
          </ul>
        </Col>
        {authUser && authUser.isSiteAdmin && (
          <Col className="links">
            <h4>
              <SettingsIcon /> Admin Settings
            </h4>
            <ul>
              <li>
                <Link to={'/admin-settings'} onClick={() => scrollToTop()}>
                  Admin Settings
                </Link>
              </li>
            </ul>
          </Col>
        )}
      </Row>
    </footer>
  );
}
