import { useContext, useEffect, useState } from 'react';
import { Button, message, Select } from 'antd';
import { UserContext } from 'context/UserContext';
import { User } from 'types/user';
import { GetUsersResponse, getUsersRoute } from 'services/users';
import { apiURL, isErrorResponse, useAxiosAuth } from 'api/api';
import { ErrorResponse } from '@remix-run/router';
import { deleteSelectedGroupID, setSelectedGroupID } from 'lib/local_storage';

export default function ImpersonationBar() {
  const axiosAuth = useAxiosAuth();
  const { authUser, setAuthUser, impersonatedUser, setImpersonatedUser } = useContext(UserContext);
  const [users, setUsers] = useState<User[]>([] as User[]);
  const [userID, setUserID] = useState<number | null>(null);
  const [loadingUsers, setLoadingUsers] = useState(true);

  useEffect(() => {
    if (authUser && authUser.isSiteAdmin) {
      fetchUsers();

      if (impersonatedUser) setUserID(authUser.id);
    }
  }, [authUser]);

  const fetchUsers = async () => {
    // fetch all users
    let res = await axiosAuth.get<never, GetUsersResponse>(getUsersRoute());
    setLoadingUsers(false);
    if (isErrorResponse(res)) {
      console.warn('Issue getting users', res.data);
      return;
    }

    // Filter self and/or user currently being impersonated
    if (impersonatedUser) setUsers(res.users.filter((u) => u.id !== impersonatedUser.id));
    else setUsers(res.users.filter((u) => u.id !== authUser?.id));
  };

  const impersonateUser = async (userID: number) => {
    let res = await axiosAuth.post<never, { impersonatedUser: User } | ErrorResponse>(
      apiURL('/auth/impersonate-user'),
      { userID: userID }
    );
    if (isErrorResponse(res)) {
      return;
    }

    setUserID(userID);
    deleteSelectedGroupID();

    setImpersonatedUser(authUser); // Set site admin user to impersonated user
    setAuthUser(res.impersonatedUser); // set impersonated user to auth user

    // Add impersonated default group
    if (res.impersonatedUser.selectedGroup)
      setSelectedGroupID(res.impersonatedUser.selectedGroup.groupID);
  };

  const cancelImpersonation = async () => {
    if (!impersonatedUser) return;

    // Delete impersonators refresh toekn
    let res = await axiosAuth.post(apiURL('/auth/cancel-impersonation'));
    if (isErrorResponse(res)) {
      message.error(res.data);
      console.warn(res);
      // don't return, we still need to clean up
    }

    setUserID(null);
    setAuthUser(impersonatedUser);

    setImpersonatedUser(null);
    deleteSelectedGroupID();

    if (impersonatedUser.selectedGroup) setSelectedGroupID(impersonatedUser.selectedGroup.groupID);
  };

  if (!authUser || (!authUser.isSiteAdmin && impersonatedUser === null)) return null;
  return (
    <div className="impersonation-bar">
      <Select
        showSearch
        loading={loadingUsers}
        disabled={impersonatedUser !== null}
        placeholder="Impersonate..."
        style={{ width: '250px' }}
        options={users.map((u) => ({
          value: u.id,
          label: u.username,
        }))}
        onChange={(e) => impersonateUser(e)}
        value={userID}
      />
      {impersonatedUser && (
        <Button type="primary" danger onClick={cancelImpersonation}>
          Stop Impersonation
        </Button>
      )}
    </div>
  );
}
