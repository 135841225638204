import { Button, Card, Col, Modal, Row, Spin, Table, TableColumnsType, message } from 'antd';
import { apiURL, isErrorResponse, useAxiosAuth } from 'api/api';
import GroupTypeCard from 'components/shared/GroupTypeCard';
import { ErrorResponse, useNavigate } from 'react-router';
import { InfinityIcon } from 'assets/SVGIcons/Icons';
import { Group, GroupType } from 'types/group';
import { getProductName } from 'lib/stripe';
import { capitalize } from 'lib/string';
import { useState } from 'react';
import Stripe from 'stripe';
import dayjs from 'dayjs';

interface Props {
  group: Group;
  stripeSubscription: Stripe.Subscription;
  groupTypes: GroupType[];
  getCustomerProfile: () => Promise<void>;
  setStripeSubscription: React.Dispatch<React.SetStateAction<Stripe.Subscription | null>>
}

export default function ActiveSubscription({
  group,
  stripeSubscription,
  groupTypes,
  getCustomerProfile,
  setStripeSubscription,
}: Props) {
  const axiosAuth = useAxiosAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingInvoice, setLoadingInvoice] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);

  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [newGroupType, setNewGroupType] = useState<GroupType | null>(null);

  const [invoicePreviews, setInvoicePreviews] = useState<Record<number, Stripe.Invoice> | null>(
    null
  );

  const getCost = (cents: number | null) => (cents ? (cents / 100).toFixed(2) : 'na');

  const handleSubscriptionCancel = async () => {
    setLoading(true);
    let res = await axiosAuth.post(apiURL('group/cancel-subscription'));
    setLoading(false);

    if (isErrorResponse(res)) {
      message.error(res.data);
      console.warn(res.data);
      return;
    }

    message.success('Subscription cancelled!');
    navigate('/groupprofile');
  };

  const handleSubscriptionUpdate = async () => {
    if (!newGroupType) return;

    setLoading(true);
    let res = await axiosAuth.post(apiURL('group/update-subscription'), {
      groupTypeID: newGroupType.id,
      previewUpdate: false,
    });
    setLoading(false);

    if (isErrorResponse(res)) {
      message.error(res.data);
      console.warn(res.data);
      return;
    }

    message.success('Subscription updated!');
    navigate('/groupprofile');
  };

  const handlePreviewSubscriptionUpdate = async (groupType: GroupType) => {
    if (invoicePreviews) {
      let existing = invoicePreviews[groupType.id];
      if (existing) return;
    }

    setLoadingInvoice(true);
    let res = await axiosAuth.post<never, Stripe.Invoice | ErrorResponse>(
      apiURL('group/update-subscription'),
      {
        groupTypeID: groupType.id,
        previewUpdate: true,
      }
    );
    setLoadingInvoice(false);

    if (isErrorResponse(res)) {
      message.error(res.data);
      console.warn(res.data);
      return;
    }

    let invoice = res; // Have to do this because ts is dumb
    setInvoicePreviews((p) => {
      if (p === null) return { [groupType.id]: invoice };
      return { ...p, [groupType.id]: invoice };
    });
  };

  const renderCancelModal = () => {
    return (
      <Modal
        title="Cancel Subscription?"
        open={openCancelModal}
        onOk={handleSubscriptionCancel}
        onCancel={() => setOpenCancelModal(false)}
        okButtonProps={{ type: 'primary', danger: true }}
        okText="Cancel Subscription"
        confirmLoading={loading}
        centered
      >
        <p>
          Are you sure you wish to cancel your subscription? It will continue until{' '}
          <b>{dayjs.unix(stripeSubscription.current_period_end).format('MMM DD, hh:mm A')}</b> at
          which point your servers will no longer be tracked. You'll still be able to view any
          historical data.
        </p>
      </Modal>
    );
  };

  const getInvoicePreview = () => {
    if (!newGroupType || !invoicePreviews) return null;
    return invoicePreviews[newGroupType.id];
  };

  const columns: TableColumnsType<Stripe.InvoiceLineItem> = [
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Amount',
      key: 'amount',
      dataIndex: 'amount',
      render: (amount: number, record: Stripe.InvoiceLineItem) =>
        `$ ${(amount / 100).toFixed(2)} ${record.currency}`,
      width: 400,
    },
  ];

  const displayInvoicePreview = () => {
    let invoice = getInvoicePreview();
    if (!invoice) return null;

    const invoiceTotal = invoice.total / 100;
    const periodStart = dayjs.unix(invoice.period_start);
    const periodEnd = dayjs.unix(invoice.period_end);
    let nextPaymentAttempt: dayjs.Dayjs | null = null;
    if (invoice.next_payment_attempt) nextPaymentAttempt = dayjs.unix(invoice.next_payment_attempt);

    return (
      <Table
        size="small"
        columns={columns}
        dataSource={invoice.lines.data}
        pagination={false}
        footer={() => (
          <Row>
            <Col offset={17} span={7}>
              <b>
                Total: ${invoiceTotal.toFixed(2)} {invoice!.currency}
              </b>
            </Col>
            <Col offset={17} span={7}>
              <b>Next payment:</b>{' '}
              {nextPaymentAttempt ? (
                nextPaymentAttempt.format('MMM DD, hh:mm A')
              ) : (
                <i>Unable to determine</i>
              )}
            </Col>
            <Col offset={17} span={7}>
              <b>Next period:</b> {periodStart.format('MMM DD, hh:mm A')} -{' '}
              {periodEnd.format('MMM DD, hh:mm A')}
            </Col>
          </Row>
        )}
      />
    );
  };

  const renderChangeModal = () => {
    return (
      <Modal
        open={openUpdateModal}
        onOk={handleSubscriptionUpdate}
        onCancel={() => {
          setOpenUpdateModal(false);
          setNewGroupType(null);
        }}
        okButtonProps={{ type: 'primary', disabled: !newGroupType }}
        okText="Update Subscription"
        className="change-subscription"
        confirmLoading={loading}
        width={1400}
        centered
      >
        <Row gutter={[8, 16]}>
          <Col span={6}>
            <b>Current Subscription</b>
          </Col>
          <Col span={18}>
            <b>Select a new subscription</b>
          </Col>
          <Col span={6}>
            <GroupTypeCard key={group.groupTypeID} groupType={group.groupType} />
          </Col>
          {groupTypes
            .filter((gt) => gt.id !== group.groupTypeID)
            .map((gt) => (
              <GroupTypeCard
                key={gt.id}
                groupType={gt}
                span={6}
                selected={gt.id === newGroupType?.id}
                onClick={() => {
                  setNewGroupType(gt);
                  handlePreviewSubscriptionUpdate(gt);
                }}
              />
            ))}
          <Col span={24}>
            <Spin spinning={loadingInvoice}>
              {loadingInvoice ? null : !getInvoicePreview() ? (
                <>
                  When you select a new subscription a preview of what your approximate next invoice
                  will look like
                </>
              ) : (
                displayInvoicePreview()
              )}
            </Spin>
          </Col>
          <Col span={24} className="details">
            <b>
              Upon clicking <i>Update Subscription</i> your subscription will be updated and your
              payment method will be charged on the date shown above.
            </b>
            <p>
              If you are downgrading your subscription you'll have a short period of time to remove
              any members or stop tracking your servers that exceed your new limit. Failure to do so
              will result in the system automatically doing so for you.
            </p>
          </Col>
        </Row>
      </Modal>
    );
  };

  return (
    <Row justify="center">
      <Col>
        <p>
          <b>Subscription Start:</b>{' '}
          {dayjs.unix(stripeSubscription.start_date).format('MMM DD, hh:mm A')}
        </p>
        <p>
          <b>Current Subscription Period:</b>{' '}
          {dayjs.unix(stripeSubscription.current_period_start).format('MMM DD, hh:mm A')} -{' '}
          {dayjs.unix(stripeSubscription.current_period_end).format('MMM DD, hh:mm A')}
        </p>
        <p>
          <b>Subscription Status:</b>{' '}
          <span className={`sub-status ${stripeSubscription.status}`}>
            {capitalize(stripeSubscription.status)}
          </span>
        </p>
        <h3>Subscription Items</h3>
        {stripeSubscription.items.data.map((item) => (
          <Card key={item.id} title={getProductName(item.plan)}>
            <Row justify="center">
              <Col span={16}>Tracked Server Limit</Col>
              <Col className="center" span={8}>
                {group.groupType.trackedServerLimit !== null ? (
                  group.groupType.trackedServerLimit
                ) : (
                  <InfinityIcon />
                )}
              </Col>
              <Col span={16}>Member Limit</Col>
              <Col className="center" span={8}>
                {group.groupType.memberLimit !== null ? (
                  group.groupType.memberLimit
                ) : (
                  <InfinityIcon />
                )}
              </Col>
              <Col span={16}>Monthly Price</Col>
              <Col span={8}>
                ${getCost(item.price.unit_amount)} {item.price.currency.toUpperCase()}
              </Col>
            </Row>
          </Card>
        ))}
        {stripeSubscription.status !== 'canceled' ? (
          <>
            <Button
              className="sub-btn"
              disabled={loading}
              type="primary"
              onClick={() => setOpenUpdateModal(true)}
            >
              Update Subscription
            </Button>
            <Button
              disabled={loading}
              className="sub-btn"
              type="primary"
              danger
              onClick={() => setOpenCancelModal(true)}
            >
              Cancel Subscription
            </Button>
          </>
        ) : (
          <Button className="sub-btn" type='primary' onClick={() => setStripeSubscription(null)}>New Subscription</Button>
        )}

        <Button
          className="sub-btn"
          disabled={loading}
          onClick={() => {
            setLoading(true);
            getCustomerProfile();
          }}
        >
          Update Billing
        </Button>
      </Col>
      {renderCancelModal()}
      {renderChangeModal()}
    </Row>
  );
}
