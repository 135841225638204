import AdminSettings from 'components/AdminSettings';
import Communities from 'components/Communities';
import { ErrorPage } from 'components/ErrorPage';
import GroupProfile from 'components/Groups';
import GroupSubscription from 'components/Groups/Subscriptions';
import Info from 'components/Info';
import PrivacyPolicy from 'components/Info/PrivacyPolicy';
import LiveServers from 'components/LiveServers';
import LiveStatistics from 'components/LiveStatistics';
import SavedServersTabs from 'components/SavedServers';
import TrackedServers from 'components/TrackedServers';
import ServerData from 'components/TrackedServers/ServerData';
import UserProfile from 'components/UserProfile';
import EmailConfirmation from 'components/shared/EmailConfirmation';
import PasswordReset from 'components/shared/PasswordReset';
import { ValidPrivacyPolicyRoutes } from 'lib/constants';

export const GetRoutes = () =>
  PublicRoutes.concat(PrivateRoutes).concat(PrivacyPolicyRoutes).concat(AdminRoutes);

const PublicRoutes = [
  {
    path: '/',
    element: <LiveServers />,
  },
  {
    path: '/liveservers',
    element: <LiveServers />,
  },
  {
    path: '/livestatistics',
    element: <LiveStatistics />,
  },
  {
    path: '/communities',
    element: <Communities />,
  },
  {
    path: '/site-info',
    element: <Info />,
  },
  {
    path: '/reset-password',
    element: (
      <section>
        <PasswordReset />
      </section>
    ),
  },
  {
    path: '/confirm-email',
    element: <EmailConfirmation />,
  },
  {
    path: '/404',
    element: <ErrorPage />,
  },
];

const PrivacyPolicyRoutes = ValidPrivacyPolicyRoutes.map((route) => ({
  path: route,
  element: <PrivacyPolicy />,
}));

const PrivateRoutes = [
  {
    path: '/savedservers',
    element: <SavedServersTabs />,
  },
  {
    path: '/trackedservers',
    element: <TrackedServers />,
  },
  {
    path: '/trackedservers/:serverID',
    element: <ServerData />,
  },
  {
    path: '/groupprofile',
    element: <GroupProfile />,
  },
  {
    path: '/groupprofile/subscription',
    element: <GroupSubscription />,
  },
  {
    path: '/userprofile',
    element: <UserProfile />,
  },
];

const AdminRoutes = [{ path: '/admin-settings', element: <AdminSettings /> }];
