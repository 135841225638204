import { useRef, useState } from 'react';
import { FilterFilled, SearchOutlined } from '@ant-design/icons';
import { Button, Checkbox, Input, InputRef, Space, Table, TableProps } from 'antd';
import {
  ColumnFilterItem,
  ColumnType,
  FilterDropdownProps,
  TablePaginationConfig,
} from 'antd/es/table/interface';
import { PaginatedSearchInfo } from './TableInterfaces';
import { _primaryPurple } from 'lib/colors';

// SearchValues is one of the variables used in the CustomColumnType
interface Searchable {
  dbColumnName: string;
  exactValue?: boolean;
  filterOptions?: ColumnFilterItem[];
}

// This is the custom column type for each table row
export interface CustomColumnType<T> extends ColumnType<T> {
  searchable?: Searchable;
}

interface Props<T> extends TableProps<T> {
  columns: CustomColumnType<T>[];
  loadTableData?: (info: PaginatedSearchInfo) => void;
  totalRows?: number; //also total items
}

const searchMap = new Map<string, { value: string[]; exactValue: boolean }>();

export default function CustomTable<T extends object = any>(props: Props<T>) {
  const searchInput = useRef<InputRef>(null);
  const [pageSize, setPageSize] = useState(25);
  const [pageNumber, setPageNumber] = useState(1);

  const handleColumnReset = (clearFilters: () => void, confirm: () => void) => {
    clearFilters();
    confirm();
    handleApiSearch();
  };

  const handleColumnSearch = (
    column: CustomColumnType<T>,
    selectedKeys: React.Key[] | undefined,
    confirm: (() => void) | undefined
  ) => {
    if (column.searchable && selectedKeys) {
      if (selectedKeys[0])
        searchMap.set(column.searchable.dbColumnName, {
          value: selectedKeys as string[],
          exactValue: !!column.searchable.exactValue,
        });
      else searchMap.delete(column.searchable?.dbColumnName);
    }

    if (confirm) confirm();
    handleApiSearch();
  };

  const handleApiSearch = () => {
    let searchInfo: PaginatedSearchInfo = {
      paginateInfo: {
        page: 1, // Always jump back to page 1 when searching
        pageSize,
      },
      searchItems: Array.from(searchMap).map(([k, v]) => {
        return { dbColumnName: k, searchValues: v.value, exactValue: v.exactValue };
      }),
    };

    props.loadTableData && props.loadTableData(searchInfo);
  };

  const handlePaginationChange = (p: number, ps: number) => {
    let searchInfo: PaginatedSearchInfo = {
      paginateInfo: {
        page: p,
        pageSize: ps,
      },
      searchItems: Array.from(searchMap).map(([k, v]) => {
        return { dbColumnName: k, searchValues: v.value, exactValue: v.exactValue };
      }),
    };

    props.loadTableData && props.loadTableData(searchInfo);
  };

  const handleCheckboxChange = (
    item: string,
    selectedKeys: React.Key[],
    setSelectedKeys: (selectedKeys: React.Key[]) => void
  ) => {
    let updatedCheckedItems: React.Key[] = [...selectedKeys];

    // Add or remove based on selection
    if (!selectedKeys.includes(item)) updatedCheckedItems.push(item);
    else updatedCheckedItems = updatedCheckedItems.filter((checkedItem) => checkedItem !== item);

    // Set selected keys for given column
    setSelectedKeys(updatedCheckedItems);
  };

  const getColumnSearchProps = (column: CustomColumnType<T>) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: FilterDropdownProps) => {
      if (column.searchable?.filterOptions) {
        return (
          <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <Space direction="vertical">
              {column.filters?.map((f, i) => (
                <Checkbox
                  key={i}
                  onChange={() =>
                    handleCheckboxChange(f.value as string, selectedKeys, setSelectedKeys)
                  }
                  checked={selectedKeys.includes(f.value as string)}
                >
                  {f.text}
                </Checkbox>
              ))}
              <Space>
                <Button
                  onClick={() => {
                    column.searchable && searchMap.delete(column.searchable?.dbColumnName);
                    clearFilters && handleColumnReset(clearFilters, confirm);
                  }}
                  size="small"
                  style={{ width: 100 }}
                >
                  Reset
                </Button>
                <Button
                  type="primary"
                  onClick={() => handleColumnSearch(column, selectedKeys, confirm)}
                  icon={<SearchOutlined />}
                  size="small"
                  style={{ width: 100 }}
                >
                  Search
                </Button>
              </Space>
            </Space>
          </div>
        );
      } else {
        return (
          <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <Input
              ref={searchInput}
              placeholder={`Search ${column.title}`}
              value={selectedKeys[0]}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleColumnSearch(column, selectedKeys, confirm)}
              style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                onClick={() => {
                  column.searchable && searchMap.delete(column.searchable?.dbColumnName);
                  clearFilters && handleColumnReset(clearFilters, confirm);
                }}
                size="small"
                style={{ width: 100 }}
              >
                Reset
              </Button>
              <Button
                type="primary"
                onClick={() => handleColumnSearch(column, selectedKeys, confirm)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 100 }}
              >
                Search
              </Button>
            </Space>
          </div>
        );
      }
    },
    filterIcon: (filtered: boolean) => {
      if (column.searchable?.filterOptions)
        return <FilterFilled style={{ color: filtered ? _primaryPurple : '#000' }} />;
      else return <SearchOutlined style={{ color: filtered ? _primaryPurple : '#000' }} />;
    },
    // onFilter: (value: boolean | React.Key, record: T) => {
    // record[column.dataIndex?.toString() || '']
    // console.log('value', value, 'record', record);
    // console.log('column', column.searchable);

    //   if (column.searchable) {
    //     searchMap.set(column.searchable.dbColumnName, {
    //       value: value as string,
    //       exactValue: !!column.searchable.exactValue,
    //     });
    //   }

    //   return false;
    // },
    // filterMultiple: true,
    // onFilter: (value: boolean | React.Key, record: RecordType) => {
    // return record[column.dataIndex || '']
    //     ? record[column.dataIndex]
    //         .toString()
    //         .toLowerCase()
    //         .includes(value.toLowerCase())
    // },
    onFilterDropdownOpenChange: (visible: boolean) => {
      // This handles auto selecting of the input when filter is opened
      if (visible) setTimeout(() => searchInput.current?.select(), 100);
    },
    // render: (text) => text, // For custom rendering
  });

  const getBasicColumnSearchProps = (column: ColumnType<T>) => ({
    filterIcon: (filtered: boolean) => {
      if (column.filters) return <FilterFilled style={{ color: filtered ? _primaryPurple : '#000' }} />;
      else return <SearchOutlined style={{ color: filtered ? _primaryPurple : '#000' }} />;
    },
  });

  const columnDefinitions = props.columns.map((c) => {
    if (c.searchable?.filterOptions) {
      c = {
        ...c,
        filters: c.searchable.filterOptions,
      };
    }

    if (c.searchable) {
      c = {
        ...c,
        ...getColumnSearchProps(c),
      };
    } else {
      c = {
        ...c,
        ...getBasicColumnSearchProps(c),
      };
    }

    return c;
  });

  return (
    <Table
      {...props}
      columns={columnDefinitions}
      pagination={
        {
          total: props.totalRows,
          current: pageNumber,
          pageSize: pageSize,
          hideOnSinglePage: true,
          showSizeChanger: true,
          pageSizeOptions: [25, 50, 100, 250],
          onChange: (page: number, pSize: number) => {
            // If no change has occurred bail
            if (page === pageNumber && pSize === pageSize) return;

            if (pSize !== pageSize) {
              setPageNumber(1);
              setPageSize(pSize);
              handlePaginationChange(1, pSize);
            } else {
              setPageNumber(page);
              handlePaginationChange(page, pSize);
            }
          },
        } as TablePaginationConfig
      }
    />
  );
}
