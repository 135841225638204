import { useContext } from 'react';
import { Button, Col, message, Row, Select } from 'antd';
import { UserContext } from 'context/UserContext';
import { apiURL, isErrorResponse, useAxiosAuth, useAxiosPublic } from 'api/api';
import { deleteSelectedGroupID, setSelectedGroupID } from 'lib/local_storage';
import { GroupMemberViewModel } from 'types/group';
import {
  AcceptGroupInviteResponse,
  acceptGroupInviteRoute,
  AcceptRejectGroupInviteRequest,
  rejectGroupInviteRoute,
} from 'services/groups';
import { ErrorResponse } from '@remix-run/router';
import { User } from 'types/user';
import { Link } from 'react-router-dom';

interface Props {
  userGroups: GroupMemberViewModel[];
  groupInvites: GroupMemberViewModel[];
  removeGroupInvite: (groupID: string) => void;
  closeMenu: () => void;
}

const UserPanel = (props: Props) => {
  const axiosPublic = useAxiosPublic();
  const axiosAuth = useAxiosAuth();
  const { authUser, setAuthUser, setImpersonatedUser } = useContext(UserContext);

  const logout = async () => {
    let res = await axiosPublic.get(apiURL('auth/logout'));

    setAuthUser(null);
    setImpersonatedUser(null);
    deleteSelectedGroupID();
    props.closeMenu();
  };

  const acceptGroupInvite = async (group: GroupMemberViewModel) => {
    let req: AcceptRejectGroupInviteRequest = {
      groupID: group.groupID,
    };

    let res = await axiosAuth.post<never, AcceptGroupInviteResponse | ErrorResponse>(
      acceptGroupInviteRoute(),
      req
    );
    if (isErrorResponse(res)) {
      return;
    }

    let g = res.group;
    // add to their default group if it's empty
    if (authUser && !authUser.selectedGroup) {
      setAuthUser((prev) => {
        if (prev) return { ...prev, selectedGroup: g };
        return null;
      });
      setSelectedGroupID(g.groupID);
    }

    props.removeGroupInvite(group.groupID);
  };

  const rejectGroupInvite = async (group: GroupMemberViewModel) => {
    let req: AcceptRejectGroupInviteRequest = {
      groupID: group.groupID,
    };

    let res = await axiosAuth.post(rejectGroupInviteRoute(), req);
    if (isErrorResponse(res)) {
      message.error(res.data);
      console.warn(res.data);
      return;
    }

    props.removeGroupInvite(group.groupID);
  };

  const getOtherUserGroups = () => {
    if (!authUser?.selectedGroup) return props.userGroups;
    return props.userGroups.filter((g) => g.groupID !== authUser?.selectedGroup?.groupID);
  };

  const handleChange = async (groupID: string) => {
    // change selected group
    let res = await axiosAuth.get<never, { user: User }>(
      apiURL(`/group/get-selected-group/${groupID}`)
    );
    if (isErrorResponse(res)) {
      message.error('unable to switch groups');
      return;
    }

    setAuthUser(res.user);
    if (res.user.selectedGroup) setSelectedGroupID(res.user.selectedGroup.groupID);

    props.closeMenu();
  };

  if (!authUser) return null;
  return (
    <div className="userPanel">
      <p className="username">{authUser.username}</p>

      <div id="selected-group" className="selected-group">
        {props.userGroups.length === 0 ? (
          <>
            You are not apart of any groups.{' '}
            <Link onClick={() => props.closeMenu()} to="/groupprofile">
              Create one!
            </Link>
          </>
        ) : getOtherUserGroups().length === 0 ? (
          <div>
            <span>Selected group: </span>
            {authUser.selectedGroup?.groupName}
          </div>
        ) : (
          <>
            {authUser.selectedGroup === null ? <>Select Group</> : <>Selected Group</>}
            <Select
              getPopupContainer={() => document.getElementById('selected-group') || document.body}
              defaultValue={authUser.selectedGroup?.groupName}
              style={{ width: '250px' }}
              options={getOtherUserGroups().map((s) => ({
                value: s.groupID,
                label: s.groupName,
              }))}
              onChange={handleChange}
            />
          </>
        )}
      </div>

      {props.groupInvites.length > 0 && (
        <div className="group-invites">
          <div className="title">Group Invites</div>
          {props.groupInvites.map((i) => (
            <div className="invite" key={i.groupID}>
              <span className="group-name">{i.groupName}</span>
              <div className="action-btns">
                <Button className="accept" type="link" onClick={() => acceptGroupInvite(i)}>
                  Accept
                </Button>
                <Button type="link" danger onClick={() => rejectGroupInvite(i)}>
                  Reject
                </Button>
              </div>
            </div>
          ))}
        </div>
      )}

      <div className="panel-footer">
        <Row justify={'space-between'}>
          <Col>
            <Button type="primary" onClick={() => props.closeMenu()}>
              <Link to={'/userprofile'}>User Profile</Link>
            </Button>
          </Col>
          <Col>
            <Button danger type="link" onClick={logout}>
              Logout
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default UserPanel;
