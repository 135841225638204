import { useContext, useEffect, useRef, useState } from 'react';
import { UserIcon } from 'assets/SVGIcons/Icons';
import { UserContext } from 'context/UserContext';
import { Badge } from 'antd';
import { isErrorResponse, useAxiosAuth } from 'api/api';
import {
  getUserNotificationsRoute,
  GetUserNotificationsResponse,
  getUserGroupsRoute,
  GetUserGroupsResponse,
} from 'services/users';
import PopUpBox from 'components/shared/PopUpBox';
import LoginRegisterPanel from './LoginRegisterPanel';
import UserPanel from './UserPanel';
import DSTIcon from './DSTIcon';
import { GroupMemberViewModel } from 'types/group';
import './style.scss';

export default function AccountPanel() {
  const { authUser } = useContext(UserContext);
  const axiosAuth = useAxiosAuth();
  const [notifications, setNotifications] = useState<GroupMemberViewModel[]>(
    [] as GroupMemberViewModel[]
  );
  const [userGroups, setUserGroups] = useState<GroupMemberViewModel[]>(
    [] as GroupMemberViewModel[]
  );

  const [isOpen, setIsOpen] = useState(false);
  const icon = useRef(null);

  useEffect(() => {
    if (authUser) {
      fetchUserNotifications();
      fetchUserGroups();
    }
  }, [authUser]);

  const fetchUserNotifications = async () => {
    let res = await axiosAuth.get<never, GetUserNotificationsResponse>(getUserNotificationsRoute());
    if (isErrorResponse(res)) {
      console.warn(res.data);
      return;
    }

    setNotifications(res.groupInvites);
  };

  const fetchUserGroups = async () => {
    let res = await axiosAuth.get<never, GetUserGroupsResponse>(getUserGroupsRoute());
    if (isErrorResponse(res)) {
      console.warn(res.data);
      return;
    }

    setUserGroups(res.userGroups);
  };

  const toggleModal = () => setIsOpen(!isOpen);

  const removeGroupInvite = (groupID: string) => {
    setNotifications(prev => {
      let n = prev.filter(p => p.groupID !== groupID)
      return n
    })
  }

  return (
    <div className="login-account">
      <div onClick={toggleModal} ref={icon} className="dst-icon">
        {authUser ? (
          <Badge count={notifications?.length} color="#3cd300" offset={[0, 15]}>
            <DSTIcon />
          </Badge>
        ) : (
          <UserIcon className="user-icon" style={{ color: 'black' }} />
        )}
      </div>

      {isOpen && (
        <>
          {authUser ? (
            <PopUpBox closePopUpBox={toggleModal} linkedRef={icon} boxWidth="300px">
              <UserPanel
                userGroups={userGroups}
                groupInvites={notifications}
                removeGroupInvite={removeGroupInvite}
                closeMenu={toggleModal}
              />
            </PopUpBox>
          ) : (
            <PopUpBox closePopUpBox={toggleModal} linkedRef={icon}>
              <LoginRegisterPanel closeMenu={toggleModal} />
            </PopUpBox>
          )}
        </>
      )}
    </div>
  );
}
