import { Tabs } from 'antd';
import { useSearchParams } from 'react-router-dom';
import About from './About';
import FAQ from './FAQ';
import Support from './Support';
import './style.scss';

export default function Info() {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <section className="page site-info">
      <Tabs
        activeKey={searchParams.get('tab') || 'site-info'}
        onChange={(key) => setSearchParams({ tab: key })}
        centered
        animated
        items={[
          {
            label: 'About',
            key: 'about',
            children: <About />,
          },
          {
            label: 'FAQ',
            key: 'faq',
            children: <FAQ />,
          },
          {
            label: `Support Me!`,
            key: 'support',
            children: <Support />,
          },
        ]}
      />
    </section>
  );
}
