import { ReactNode } from 'react';
import { ButtonProps, Modal } from 'antd';

const ConfirmationModal: React.FC<{
  description: ReactNode;
  open: boolean;
  onOk: () => void;
  onCancel: () => void;
  okButtonProps?: ButtonProps | undefined
}> = ({ description, open, onOk, onCancel, okButtonProps }) => {
  return (
    <Modal
      centered
      closable={false}
      open={open}
      onOk={onOk}
      okButtonProps={okButtonProps}
      onCancel={onCancel}
      okText="Confirm"
    >
      {description}
    </Modal>
  );
};

export default ConfirmationModal;
