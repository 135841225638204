import { useEffect, useState } from 'react';
import { isErrorResponse, useAxiosAuth } from 'api/api';
import { useAuthCheck } from 'hooks/use_auth_check';
import {
  GetSavedServerResponse,
  getSavedServersRoute,
  RemoveSavedServerRequest,
  removeSavedServerRoute,
} from 'services/servers';
import { Server } from 'types/server';
import { LiveServer } from 'types/liveserver';
import { List, message, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import ServerTable from 'components/ServerTable';
import './style.scss';

export default function SavedServers() {
  const axiosAuth = useAxiosAuth();
  const isAuthorized = useAuthCheck(false);
  const [savedServers, setSavedServers] = useState<Server[] | null>(null);
  const [shutDownServers, setShutDownServers] = useState<Server[]>([] as Server[]);
  const [liveServers, setLiveServers] = useState<LiveServer[] | null>(null);

  useEffect(() => {
    if (isAuthorized) fetchSavedServers();
  }, [isAuthorized]);

  const fetchSavedServers = async () => {
    let res = await axiosAuth.get<never, GetSavedServerResponse>(getSavedServersRoute());

    if (isErrorResponse(res)) {
      message.error('There was an issue fetching saved servers');
      return;
    }

    setSavedServers(res.savedServers);
    setLiveServers(res.liveServers);

    let shutDownServers = res.savedServers.filter(
      (s) =>
        res.liveServers.findIndex(
          (l) => l.name === s.name && l.host === s.host && l.platformID === s.platformID
        ) === -1
    );

    setShutDownServers(shutDownServers);
  };

  const removeSavedServer = async (serverID: string) => {
    let res = await axiosAuth.post(removeSavedServerRoute(), {
      serverID,
    } as RemoveSavedServerRequest);
    if (isErrorResponse(res)) {
      message.error('There was an issue removing the saved server');
      console.warn(res.data);
      return;
    }

    setShutDownServers((prev) => prev.filter((s) => s.id !== serverID));
  };

  const handleRemove = (serverID: string) => {
    let ss = savedServers?.filter((s) => s.id === serverID) || [];

    if (ss.length > 0) {
      setLiveServers((prev) => {
        if (prev) {
          return prev.filter(
            (ls) =>
              !(
                ss[0].name === ls.name &&
                ss[0].host === ls.host &&
                ss[0].platformID === ls.platformID
              )
          );
        }
        return null;
      });
    }
  };

  return (
    <section className="saved-servers">
      {savedServers && (
        <ServerTable
          servers={liveServers}
          totalServerCount={savedServers.length}
          removeSavedServer={handleRemove}
        />
      )}
      {shutDownServers.length > 0 && (
        <List
          className="shut-down-servers"
          header={<b>Saved Servers that are currently not live</b>}
          bordered
          dataSource={shutDownServers}
          renderItem={(server: Server) => (
            <List.Item key={server.id}>
              {server.name}
              <Popconfirm
                placement="left"
                title={'Removed server from your saved list?'}
                trigger="click"
                icon={<DeleteOutlined style={{ color: 'red' }} />}
                okText="Yes"
                cancelText="No"
                onConfirm={() => removeSavedServer(server.id)}
              >
                <span className="rmv-btn">Remove</span>
              </Popconfirm>
            </List.Item>
          )}
        />
      )}
    </section>
  );
}
