import { UserContext } from 'context/UserContext';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

// useAuthCheck sees if the a user is logged in and if not returns them to
// the home page. Can not be used on global components or root page
// Params:
//// mustBeSiteAdmin: the user must be a site admin or they're returned to the home page
export function useAuthCheck(mustBeSiteAdmin: boolean) {
  const { authUser } = useContext(UserContext);
  const navigate = useNavigate();
  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    if (!authUser) navigate('/', { replace: true });
    else if (mustBeSiteAdmin && !authUser.isSiteAdmin) navigate('/', { replace: true });
    else setIsAuthorized(true);
  }, [authUser]);

  return isAuthorized;
}
