import { useEffect, useState } from 'react';
import { isErrorResponse, useAxiosAuth } from 'api/api';
import { InfinityIcon } from 'assets/SVGIcons/Icons';
import CustomTable, { CustomColumnType } from 'components/shared/CustomTable';
import { GetGroupsRouteResponse, getGroupsRoute } from 'services/groups';
import { NullTime } from 'types/nullables';
import GroupModal from './Modals/GroupModal';
import { Group } from 'types/group';
import { message } from 'antd';
import dayjs from 'dayjs';

const Groups: React.FC = () => {
  const axiosAuth = useAxiosAuth();
  const [groups, setGroups] = useState<Group[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<Group | null>(null);

  useEffect(() => {
    fetchGroups();
  }, []);

  const fetchGroups = async () => {
    let res = await axiosAuth.get<any, GetGroupsRouteResponse>(getGroupsRoute());
    if (isErrorResponse(res)) {
      message.error(res.data);
      console.warn(res.data);
      return;
    }

    setGroups(res.groups);
  };

  const columns: CustomColumnType<Group>[] = [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: 'Group Type',
      key: 'groupType',
      dataIndex: ['groupType', 'name'],
    },
    {
      title: 'Members',
      key: 'groupMembers',
      render: (record: Group) => `${record.groupMembers.length}/${record.groupType.memberLimit}`,
      width: 100,
    },
    {
      title: 'Subscription Ends',
      key: 'subscriptionEnds',
      dataIndex: 'subscriptionEnds',
      render: (record: NullTime) =>
        record.Valid ? dayjs.utc(record.Time).format('LLL') : <InfinityIcon />,
    },
    {
      title: 'Date Created',
      key: 'dateCreated',
      dataIndex: 'dateCreated',
      render: (record: string) => dayjs.utc(record).format('LLL'),
    },
  ];

  return (
    <>
      <CustomTable
        className="clickable"
        rowKey={(record: Group) => record.id}
        columns={columns}
        dataSource={groups}
        onRow={(group: Group) => {
          return {
            onClick: () => setSelectedGroup(group),
          };
        }}
        pagination={{
          defaultPageSize: 10,
          hideOnSinglePage: true,
        }}
      />
      <GroupModal group={selectedGroup} setGroup={setSelectedGroup} />
    </>
  );
};
export default Groups;
