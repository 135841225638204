import React from 'react';
import { Checkbox, Col, Input, InputNumber, Modal, Row, message } from 'antd';
import { GroupType } from 'types/group';
import { isErrorResponse, useAxiosAuth } from 'api/api';
import {
  GetGroupTypeResponse,
  UpdateGroupTypeRequest,
  updateGroupTypeRoute,
} from 'services/grouptypes';
import { getContainer } from 'lib/rendering';
import { Form, Formik } from 'formik';

interface Props {
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;

  groupType: GroupType | null;
  setGroupType: React.Dispatch<React.SetStateAction<GroupType | null>>;
  setGroupTypes: React.Dispatch<React.SetStateAction<GroupType[]>>;
}

export default function GroupTypeModal(props: Props) {
  const axiosAuth = useAxiosAuth();

  const closeModal = () => {
    props.setModalOpen(false);
    props.setGroupType(null);
  };

  let initialValues: UpdateGroupTypeRequest = {
    id: props.groupType?.id || 0,
    stripeProductID: props.groupType?.stripeProductID || '',
    name: props.groupType?.name || '',
    trackedServerLimit: props.groupType?.trackedServerLimit || null,
    memberLimit: props.groupType?.memberLimit || null,
    usdCost: props.groupType?.usdCost,
    isPublic: props.groupType?.isPublic || false,
    showAds: props.groupType?.showAds || false,
    featured: props.groupType?.featured || false,
    trialPeriodInDays: props.groupType ? props.groupType.trialPeriodInDays : 0,
  };

  const createUpdateGroupType = async (values: UpdateGroupTypeRequest) => {
    let res = await axiosAuth.post<never, GetGroupTypeResponse>(updateGroupTypeRoute(), {
      groupType: values,
    });
    if (isErrorResponse(res)) {
      message.error(res.data);
      console.error(res.data);
      return;
    }

    if (values.id === 0) {
      props.setGroupTypes((p) => {
        let prev = [...p];
        prev.unshift(res.groupType);
        return prev;
      });
    } else {
      props.setGroupTypes((p) => {
        let prev = [...p];
        for (let i = 0; i < prev.length; i++) {
          const gt = prev[i];
          if (gt.id === values.id) prev[i] = res.groupType;
        }
        return prev;
      });
    }

    closeModal();
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validate={(values: UpdateGroupTypeRequest) => {
        const errors: any = {};
        if (values.name === '') errors.name = 'Required';
        return errors;
      }}
      onSubmit={async (values, { resetForm }) => {
        await createUpdateGroupType(values);
        resetForm();
      }}
    >
      {({ values, handleChange, setFieldValue, submitForm }) => (
        <Modal
          title={props.groupType ? 'Update Group Type' : 'Create Group Type'}
          open={props.modalOpen}
          onCancel={closeModal}
          className="update-modal"
          getContainer={getContainer('admin-settings')}
          onOk={submitForm}
        >
          <Form>
            <span className="label">Group Type Name</span>
            <Input placeholder="Name" name="name" value={values.name} onChange={handleChange} />

            <span className="label">Tracked Server Limit</span>
            <InputNumber
              value={values.trackedServerLimit}
              style={{ width: '100%' }}
              placeholder="Tracked Server Limit"
              onChange={(value) => handleChange({ target: { name: 'trackedServerLimit', value } })}
            />
            <span className="label">Member Limit</span>
            <InputNumber
              value={values.memberLimit}
              style={{ width: '100%' }}
              placeholder="Member Limit"
              onChange={(value) => handleChange({ target: { name: 'memberLimit', value } })}
            />

            <span className="label">Cost</span>
            <InputNumber
              value={values.usdCost}
              style={{ width: '100%' }}
              placeholder="Cost"
              onChange={(value) => handleChange({ target: { name: 'usdCost', value } })}
            />

            <span className="label">Trial period in weeks</span>
            <InputNumber
              value={values.trialPeriodInDays}
              style={{ width: '100%' }}
              placeholder="Trial period"
              onChange={(value) => handleChange({ target: { name: 'trialPeriodInDays', value } })}
              min={0}
            />

            <span className="label">Stripe Product ID</span>
            <Input
              name="stripeProductID"
              value={values.stripeProductID}
              onChange={handleChange}
              disabled
            />

            <Row>
              <Col span={8}>
                <Checkbox
                  checked={values.isPublic}
                  onChange={(e) => setFieldValue('isPublic', e.target.checked)}
                >
                  Public
                </Checkbox>
              </Col>
              <Col span={8}>
                <Checkbox
                  checked={values.showAds}
                  onChange={(e) => setFieldValue('showAds', e.target.checked)}
                >
                  Show Ads
                </Checkbox>
              </Col>
              <Col span={8}>
                <Checkbox
                  checked={values.featured}
                  onChange={(e) => setFieldValue('featured', e.target.checked)}
                >
                  Featured
                </Checkbox>
              </Col>
            </Row>
          </Form>
        </Modal>
      )}
    </Formik>
  );
}
