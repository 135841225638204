import { useEffect, useState } from 'react';
import filenames from 'assets/dst_character_file_names.json';

export default function DSTIcon() {
  const [randomImage, setRandomImage] = useState<string>('');

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * filenames.length);
    const randomImageName = filenames[randomIndex];
    setRandomImage(randomImageName);
  }, []);

  return (
    <div className="dst-icon">
      <img src={`/images/dst_characters/${randomImage}`} alt={randomImage} />
    </div>
  );
}
