import AccountPanel from './AccountPanel';
import ImpersonationBar from './Navigation/ImpersonationBar';
import { Image, Layout, Tooltip } from 'antd';
const { Header: AntdHeader } = Layout;

export default function Header() {
  return (
    <AntdHeader className="site-header site-layout-background">
      <span className="site-name">DST Server Analytics</span>
      <ImpersonationBar />

      <div className="revolt-icon">
        <Tooltip placement="bottom" title="Join our Revolt Server!">
          <Image
            className="clickable"
            onClick={() => {
              window.open('https://rvlt.gg/WKtT9zPZ', '_blank');
            }}
            src="/images/misc/revolt-logo.png"
            width={30}
            preview={false}
          />
        </Tooltip>
      </div>
      <AccountPanel />
    </AntdHeader>
  );
}
