import { useContext, useEffect, useState } from 'react';
import { Datum, ResponsiveCalendar } from '@nivo/calendar';
import { isErrorResponse, useAxiosAuth } from 'api/api';
import { UserContext } from 'context/UserContext';
import { ErrorResponse, useParams } from 'react-router';
import { PlayerHeatmap } from 'types/server';
import { PlayerHeatmapResponse, getPlayerHeatmapRoute } from 'services/servers';
import { groupBy } from 'lib/array';
import dayjs from 'dayjs';
import { Col, InputNumber, Row, Spin, Tooltip } from 'antd';
import ExternalLink from 'components/shared/ExternalLink';
import { colorBlock } from 'components/shared/ColorBlock';
import { InfoCircleOutlined } from '@ant-design/icons';
import { _primaryPurple } from 'lib/colors';

export default function PlayerHeatmapData() {
  const { authUser } = useContext(UserContext);
  const axiosAuth = useAxiosAuth();
  let { serverID } = useParams();

  const [playerHeatmap, setPlayerHeatmap] = useState<PlayerHeatmap[] | null>(null);
  const [selectedGroup, setSelectedGroup] = useState<PlayerHeatmap[] | null>(null);

  const [heatmapYear, setHeatmapYear] = useState<number>(dayjs.utc().year());
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (authUser?.selectedGroup) fetchPlayerHeatmap();
  }, [authUser, heatmapYear]);

  const fetchPlayerHeatmap = async () => {
    if (!serverID) return;

    setLoading(true);
    let res = await axiosAuth.get<never, PlayerHeatmapResponse | ErrorResponse>(
      getPlayerHeatmapRoute(serverID, heatmapYear)
    );
    setLoading(false);
    if (isErrorResponse(res)) {
      return;
    }

    setPlayerHeatmap(res.playerHeatmap);
  };

  const data2 = () => {
    if (!playerHeatmap) return [];

    // Group player heatmap by day
    let grouped = groupBy(playerHeatmap, (p) => p.day);

    return Object.keys(grouped).map((day) => {
      return {
        day: dayjs(day).format('YYYY-MM-DD'),
        value: grouped[day].length,
        group: grouped[day],
      };
    });
  };

  const getDate = () => {
    if (!playerHeatmap || playerHeatmap.length === 0) return '';
    let date = dayjs(playerHeatmap[0].day).format('YYYY-01-02');
    return date;
  };

  const clickCell = (
    datum: Datum | Omit<Datum, 'value' | 'data'>,
    event: React.MouseEvent<SVGRectElement, MouseEvent>
  ) => {
    // If data doesn't exist bail
    if (!('data' in datum)) return;

    // @ts-ignore
    if (datum.data.group) setSelectedGroup(datum.data.group);
  };

  // https://colorkit.co/color-shades-generator/6918ff/
  const tints = [
    '#ededff',
    '#dbdaff',
    '#c9c8ff',
    '#b9b5ff',
    '#a9a1ff',
    '#9a8dff',
    '#8c78ff',
    '#7f61ff',
    '#7345ff',
    _primaryPurple,
  ];

  const renderHeatmap = () => {
    if (!playerHeatmap) return null;

    return (
      <>
        <Col span={20}>
          <div className="cal-wrapper">
            <ResponsiveCalendar
              data={data2()}
              from={getDate()}
              to={getDate()}
              emptyColor="white"
              monthBorderColor="#eeeeee"
              dayBorderColor="#eeeeee"
              colors={tints}
              margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
              onClick={clickCell}
              legends={[
                {
                  anchor: 'center',
                  direction: 'row',
                  itemCount: tints.length,
                  translateY: 90,
                  itemWidth: 42,
                  itemHeight: 36,
                  itemsSpacing: 10,
                  itemDirection: 'right-to-left',
                },
              ]}
            />
          </div>
        </Col>
        {selectedGroup && (
          <Col span={4} className="heatmap-selection">
            <div className="header">
              <b>{dayjs(playerHeatmap[0].day).format('MMM DD, YYYY')} - UTC</b>
              <span>{selectedGroup.length} players</span>
            </div>
            <div className="players">
              {selectedGroup.map((p, i) => (
                <span key={i}>
                  {colorBlock(p.color)}
                  {p.steamID === '' ? (
                    p.player
                  ) : (
                    <ExternalLink
                      url={`https://steamcommunity.com/profiles/${p.steamID}`}
                      text={p.player}
                    />
                  )}
                </span>
              ))}
            </div>
          </Col>
        )}
      </>
    );
  };

  return (
    <Row className="heatmap">
      <Col span={24}>
        <h2>
          Player Heatmap
          <Tooltip title="A heatmap for the selected year. The darker the color the more players played on that day. See legend for range. Click on any cell to see players. This chart is shown specifically in UTC">
            <InfoCircleOutlined style={{ fontSize: '16px', marginLeft: '1ex' }} />
          </Tooltip>
        </h2>
        <InputNumber
          max={dayjs.utc().year()}
          min={2023}
          className="heatmap-year"
          size="large"
          value={heatmapYear}
          onChange={(e) => e && setHeatmapYear(e)}
        />
      </Col>
      {loading ? (
        <Col className='spinner' span={24}>
          <Spin />
        </Col>
      ) : playerHeatmap && playerHeatmap.length > 0 ? (
        renderHeatmap()
      ) : (
        <Col span={24} className="no-data">
          No data for {heatmapYear}
        </Col>
      )}
    </Row>
  );
}
