import { useContext, useEffect, useState } from 'react';
import { useAxiosPublic, useAxiosAuth, isErrorResponse, apiURL } from 'api/api';
import { UserContext } from 'context/UserContext';
import { useAuthCheck } from 'hooks/use_auth_check';
import { GetGroupTypesResponse, getGroupTypesRoute } from 'services/grouptypes';
import {
  CollapseProps,
  Button,
  Input,
  Row,
  Col,
  Collapse,
  message,
  Breadcrumb,
  Alert,
  Skeleton,
} from 'antd';
import { GetGroupRouteResponse, getGroupRoute } from 'services/groups';
import { ArrowLeftOutlined, LoadingOutlined } from '@ant-design/icons';
import GroupTypeCard from 'components/shared/GroupTypeCard';
import { ErrorResponse, useNavigate } from 'react-router';
import { getCustomerName, getPaymentIntentClientSecret, getProductID } from 'lib/stripe';
import ActiveSubscription from './ActiveSubscription';
import Checkout from 'components/Stripe/checkout';
import { Group, GroupType } from 'types/group';
import { Link } from 'react-router-dom';
import Stripe from 'stripe';
import './style.scss';

export default function GroupSubscription() {
  useAuthCheck(false);
  const navigate = useNavigate();
  const { authUser } = useContext(UserContext);
  const axiosPublic = useAxiosPublic();
  const axiosAuth = useAxiosAuth();

  const [selectedGroupType, setSelectedGroupType] = useState<GroupType | null>(null);
  const [group, setGroup] = useState<Group | null>(null);
  const [groupTypes, setGroupTypes] = useState<GroupType[]>([] as GroupType[]);
  const [accountToken, setAccountToken] = useState('');
  const [loading, setLoading] = useState(false);
  const [subscribing, setSubscribing] = useState(false);
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [stripeSubscription, setStripeSubscription] = useState<Stripe.Subscription | null>(null);

  const [fullName, setFullName] = useState('');

  const canAccessPage =
    authUser && authUser.selectedGroup && authUser.selectedGroup.groupOwnerID === authUser.id;

  useEffect(() => {
    if (canAccessPage) {
      fetchGroupTypes();
      fetchGroupSubscription();
      fetchSelectedGroup();
    } else {
      if (!authUser || !authUser.selectedGroup) navigate('/groupprofile', { replace: true });
    }
  }, [authUser]);

  const fetchGroupTypes = async () => {
    let res = await axiosPublic.get<never, GetGroupTypesResponse>(getGroupTypesRoute());
    if (isErrorResponse(res)) {
      return;
    }

    setGroupTypes(res.groupTypes);
  };

  const fetchSelectedGroup = async () => {
    let res = await axiosAuth.get<never, GetGroupRouteResponse>(
      getGroupRoute(authUser!.selectedGroup!.groupID)
    );
    if (isErrorResponse(res)) {
      message.error(res.data);
      console.warn(res.data);
      return;
    }

    setGroup(res.group);
  };

  const fetchGroupSubscription = async () => {
    if (!authUser || !authUser.selectedGroup) return;

    setLoading(true);
    let subscription = await axiosAuth.get<never, Stripe.Subscription | ErrorResponse>(
      apiURL('/group/subscription')
    );

    if (isErrorResponse(subscription)) {
      setLoading(false);
      return;
    }

    setStripeSubscription(subscription);
    if (subscription.status === 'incomplete') {
      // User MUST enter credit card details to complete subscription
      setClientSecret(getPaymentIntentClientSecret(subscription));
      setLoading(false);
    } else if (subscription.status === 'trialing') {
      getCustomerProfile();
    } else {
      setLoading(false);
    }
  };

  const handleAccountTokenSubmit = async () => {
    if (accountToken.length !== 6) {
      message.error('invalid account token');
      return;
    }

    let res = await axiosAuth.post<never, { groupTypes: GroupType[] }>(
      apiURL('users/use-accounttoken'),
      {
        accountToken,
      }
    );
    if (isErrorResponse(res)) {
      message.error(res.data);
      console.warn(res.data);
      return;
    }

    // Set new group types
    setGroupTypes(res.groupTypes);
    setAccountToken('');
    message.success('account token accepted');
  };

  const handleSubscribe = async () => {
    if (!authUser || !selectedGroupType) return;

    if (fullName.length < 5) {
      message.error('Please enter your full name (5 character minimum)');
      return;
    }

    setSubscribing(true);
    let subscription = await axiosAuth.post<never, Stripe.Subscription | ErrorResponse>(
      apiURL('group/initiate-subscription'),
      {
        fullName,
        groupTypeID: selectedGroupType.id,
      }
    );
    setSubscribing(false);

    if (isErrorResponse(subscription)) {
      message.error(subscription.data);
      return;
    }

    setStripeSubscription(subscription);
    if (subscription.status === 'incomplete') {
      setClientSecret(getPaymentIntentClientSecret(subscription));
    } else if (subscription.status === 'trialing') {
      getCustomerProfile();
    } else {
      navigate('/groupprofile');
    }
  };

  const getCustomerProfile = async () => {
    // Get customer portal
    let res = await axiosAuth.get<never, string | ErrorResponse>(apiURL('/users/stripe-portal'));
    if (isErrorResponse(res)) {
      navigate('/groupprofile');
      return;
    }

    window.location.href = res;
  };

  const items: CollapseProps['items'] = [
    {
      key: 1,
      label: (
        <>
          Have an account token for creating a group type that isn't listed below?{' '}
          <Button type="link">Click here</Button>
        </>
      ),
      showArrow: false,
      children: (
        <>
          <Input
            placeholder="Account Token"
            value={accountToken}
            onChange={(e) => setAccountToken(e.target.value)}
            onPressEnter={handleAccountTokenSubmit}
          />
          <small>Press enter to submit</small>
        </>
      ),
    },
  ];

  const renderContent = () => {
    if (!canAccessPage) return null;
    if (!authUser.email.Valid) return renderEmailRequired();
    if (loading) return <Skeleton active />;
    if (
      group &&
      (stripeSubscription?.status === 'active' || stripeSubscription?.status === 'canceled')
    ) {
      return (
        <ActiveSubscription
          group={group}
          stripeSubscription={stripeSubscription}
          groupTypes={groupTypes}
          getCustomerProfile={getCustomerProfile}
          setStripeSubscription={setStripeSubscription}
        />
      );
    }
    if (!clientSecret) return renderNewSubscription();

    if (!stripeSubscription) return null;
    let prodID = getProductID(stripeSubscription.items.data[0].plan);
    let prevSelectedGroup = groupTypes.find((groupType) => groupType.stripeProductID === prodID);
    if (!prevSelectedGroup) return null;
    return (
      <>
        <Row justify="center">
          <GroupTypeCard key={prevSelectedGroup.id} groupType={prevSelectedGroup} span={8} />
        </Row>
        <Row justify="center">
          <Col span={8}>
            <Button
              style={{ width: '100%', margin: '1em 0' }}
              onClick={() => {
                setClientSecret(null);
                setFullName(getCustomerName(stripeSubscription.customer) || '');
              }}
            >
              Change Subscription
            </Button>
          </Col>
        </Row>
        <Row justify="center">
          <Col span={8}>
            <Checkout clientSecret={clientSecret} />
          </Col>
        </Row>
      </>
    );
  };

  const renderEmailRequired = () => {
    return (
      <Alert
        message={
          <>
            You must enter an email address to create a paid group. Go to your{' '}
            <Link to={'/userprofile'}>user profile</Link> to add one.
          </>
        }
        type="error"
      />
    );
  };

  const renderNewSubscription = () => {
    if (!authUser) return null;

    return (
      <>
        <Col span={24}>
          <h2>Information</h2>
        </Col>
        <Col md={12} xs={24}>
          <label>Full Name</label>
          <Input
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            placeholder="Full Name (as shown on card)"
          />
        </Col>
        <Col md={12} xs={24}>
          <label>Email</label>
          <Input disabled value={authUser?.email.String} />
        </Col>
        <Col span={24} className="group-types">
          <h2>Group Types</h2>
        </Col>
        <Col span={24}>
          <Collapse className="collapse" ghost items={items} size="small" />
        </Col>
        <Col span={24}>
          <Row gutter={[8, 8]}>
            {groupTypes.map((gt) => (
              <GroupTypeCard
                key={gt.id}
                groupType={gt}
                onClick={() => setSelectedGroupType(gt)}
                selected={gt.id === selectedGroupType?.id}
                span="8"
                showTrials={!authUser.usedFreeTrial}
              />
            ))}
          </Row>
        </Col>

        <Col span={24}>
          <Row style={{ marginTop: '1em' }} justify="end" align="middle">
            {!authUser.usedFreeTrial &&
              selectedGroupType &&
              selectedGroupType.trialPeriodInDays > 0 && (
                <Col style={{ marginRight: '1em', maxWidth: '55ex' }}>
                  You're starting a free trial. You'll be re-directed to a payment page where you
                  can optionally add your credit card info.
                </Col>
              )}
            <Col>
              <Button
                disabled={!selectedGroupType || subscribing}
                type="primary"
                onClick={handleSubscribe}
                icon={subscribing && <LoadingOutlined />}
              >
                Continue
              </Button>
            </Col>
          </Row>
        </Col>
      </>
    );
  };

  const breadcrumbsItems = [
    {
      title: (
        <Link to="/groupprofile">
          <ArrowLeftOutlined />
          Back to group
        </Link>
      ),
    },
  ];

  if (!canAccessPage) return null;
  return (
    <section className="page group-subscription">
      <Breadcrumb className="breadcrumbs" items={breadcrumbsItems} />
      <Row gutter={[8, 8]} className="group-types">
        <Col span={24}>
          <h1>Group Subscription</h1>
        </Col>
        <Col span={24}>{renderContent()}</Col>
      </Row>
    </section>
  );
}
