import React, { useState } from 'react';
import { CloseCircleOutlined, DeleteOutlined, EditOutlined, SaveFilled } from '@ant-design/icons';
import { Button, Checkbox, Col, Input, Modal, Popconfirm, Row, Select, message } from 'antd';
import { apiURL, isErrorResponse, useAxiosAuth } from 'api/api';
import {
  CommunityRegions,
  GroupLinkDiscord,
  GroupLinkSteam,
  GroupLinkTwitch,
  ValidGroupLinkTypes,
} from 'lib/constants';
import { Group, GroupLink } from 'types/group';
const { TextArea } = Input;

interface Props {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  group: Group;
  setGroup: React.Dispatch<React.SetStateAction<Group | null>>;
}

export default function GroupSettingsModal(props: Props) {
  const axiosAuth = useAxiosAuth();

  const [description, setDescription] = useState(props.group.description);
  const [region, setRegion] = useState(props.group.region);
  const [isPublic, setIsPublic] = useState(props.group.isPublic);
  const [newLink, setNewLink] = useState<GroupLink | null>(null);
  const [editLink, setEditLink] = useState<GroupLink | null>(null);

  const [groupLinks, setGroupLinks] = useState<GroupLink[]>(props.group.groupLinks);
  const [newCount, setNewCount] = useState(0);

  const saveGroupLink = (linkID: number) => {
    if (linkID <= 0 && newLink && validateURL(newLink.link, newLink.linkType)) {
      setGroupLinks((prev) => [...prev, newLink]);
      setNewLink(null);
      setNewCount((p) => p - 1);
    } else if (editLink && validateURL(editLink.link, editLink.linkType)) {
      setGroupLinks((prev) => {
        return prev.map((l) => {
          if (l.id === editLink.id) return editLink;
          return l;
        });
      });
      setEditLink(null);
    }
  };

  const handleLinkDelete = (linkID: number) => {
    setGroupLinks((prev) => prev.filter((l) => l.id !== linkID));
  };

  const validateURL = (link: string, linkType: string) => {
    let isValid = false;
    switch (linkType) {
      case GroupLinkDiscord:
        isValid =
          link.startsWith('https://discord.gg/') || link.startsWith('https://www.discord.gg/');
        break;
      case GroupLinkSteam:
        isValid =
          link.startsWith('https://steamcommunity.com/groups/') ||
          link.startsWith('https://www.steamcommunity.com/groups/');
        break;
      case GroupLinkTwitch:
        isValid =
          link.startsWith('https://twitch.tv/') || link.startsWith('https://www.twitch.tv/');
        break;
    }

    if (!isValid) message.error(`${link} is an invalid ${linkType} link`);
    return isValid;
  };

  const getEditSaveDeleteButtons = (link: GroupLink) => {
    if (!editLink) {
      return (
        <>
          <Col span={1}>
            <Button
              style={{ width: '100%' }}
              type="link"
              icon={<EditOutlined />}
              onClick={() => setEditLink(link)}
            />
          </Col>
          <Col span={1}>
            <Popconfirm
              placement="left"
              title={'Are you sure?'}
              onConfirm={() => handleLinkDelete(link.id)}
              trigger="click"
            >
              <Button
                style={{ width: '100%' }}
                type="link"
                icon={<DeleteOutlined style={{ color: 'red' }} />}
              />
            </Popconfirm>
          </Col>
        </>
      );
    }

    if (editLink && editLink.id === link.id) {
      return (
        <>
          <Col span={1}>
            <Button
              style={{ width: '100%' }}
              type="link"
              icon={<SaveFilled />}
              onClick={() => saveGroupLink(link.id)}
            />
          </Col>
          <Col span={1}>
            <Button
              style={{ width: '100%', color: 'black' }}
              type="link"
              icon={<CloseCircleOutlined />}
              onClick={() => setEditLink(null)}
            />
          </Col>
        </>
      );
    }

    return null;
  };

  const handleUpdateGroup = async () => {
    let res = await axiosAuth.post(apiURL('group/update'), {
      // name: name,
      region,
      description,
      isPublic,
      groupLinks: groupLinks,
    });
    if (isErrorResponse(res)) {
      message.error(res.data);
      console.warn(res.data);
      return;
    }

    props.setGroup((prev) => prev && { ...prev, region, description, isPublic, groupLinks });
    props.setVisible(false);
  };

  return (
    <Modal
      title={<h2>Group Settings</h2>}
      onCancel={() => props.setVisible(false)}
      width={700}
      open={props.visible}
      footer={
        <div className="modal-footer">
          <Button type="primary" onClick={handleUpdateGroup}>
            Save
          </Button>
        </div>
      }
      className="group-settings-modal"
    >
      <Checkbox checked={isPublic} onChange={(e) => setIsPublic(e.target.checked)}>
        Show on Communities page
      </Checkbox>
      <p>
        <strong>Description</strong>
      </p>
      <TextArea rows={4} value={description} onChange={(e) => setDescription(e.target.value)} />
      <p>
        <strong>Primary Region</strong>
      </p>
      <Select
        style={{ width: '100%' }}
        options={CommunityRegions.map((r) => ({ value: r, label: r }))}
        allowClear
        value={region}
        onChange={(r) => setRegion(r)}
      />
      <p>
        <strong>Links</strong>
      </p>
      <Row>
        <Col span={24}>
          <Button
            disabled={!!newLink}
            type="primary"
            style={{ width: '100%' }}
            onClick={() =>
              setNewLink({
                id: newCount,
                groupID: props.group.id,
                link: '',
                linkType: GroupLinkDiscord,
              })
            }
          >
            Add Link
          </Button>
        </Col>
      </Row>
      {newLink && (
        <Row gutter={8} className="group-link">
          <Col span={5}>
            <Select
              value={newLink.linkType}
              style={{ width: '100%' }}
              options={ValidGroupLinkTypes.map((t) => ({ value: t, label: t }))}
              onChange={(e) => setNewLink({ ...newLink, linkType: e })}
            />
          </Col>
          <Col span={17}>
            <Input
              value={newLink.link}
              onChange={(e) => setNewLink({ ...newLink, link: e.target.value })}
            />
          </Col>
          <Col span={1}>
            <Button
              style={{ width: '100%' }}
              type="link"
              icon={<SaveFilled />}
              onClick={() => saveGroupLink(newLink.id)}
            />
          </Col>
          <Col span={1}>
            <Button
              style={{ width: '100%', color: 'black' }}
              type="link"
              icon={<CloseCircleOutlined />}
              onClick={() => setNewLink(null)}
            />
          </Col>
        </Row>
      )}

      {groupLinks.map((l, i) => (
        <Row key={i} className="group-link" gutter={8}>
          <Col span={5}>
            <Select
              disabled={editLink === null || editLink.id !== l.id}
              value={editLink?.id === l.id ? editLink.linkType : l.linkType}
              style={{ width: '100%' }}
              options={ValidGroupLinkTypes.map((t) => ({ value: t, label: t }))}
              onChange={(t) => setEditLink((p) => p && { ...p, linkType: t })}
            />
          </Col>
          <Col span={17}>
            <Input
              value={editLink?.id === l.id ? editLink.link : l.link}
              disabled={editLink === null || editLink.id !== l.id}
              onChange={(e) => setEditLink((p) => p && { ...p, link: e.target.value })}
            />
          </Col>
          {getEditSaveDeleteButtons(l)}
        </Row>
      ))}
    </Modal>
  );
}
