import { useEffect, useState } from 'react';
import CustomTable, { CustomColumnType } from 'components/shared/CustomTable';
import { PaginatedSearchInfo } from 'components/shared/TableInterfaces';
import { MiscServerDataResponse, getMiscServerDataRoute } from 'services/liveservers';
import { apiURL, isErrorResponse, useAxiosAuth, useAxiosPublic } from 'api/api';
import { GetCommunitiesResponse } from 'services/groups';
import { RocketIcon, StarFilledIcon } from 'assets/SVGIcons/Icons';
import { DSTCharacter } from 'types/dstcharacter';
import { LiveVersions } from 'types/liveserver';
import { CommunityRegions } from 'lib/constants';
import CommunityModal from './CommunityModal';
import { ErrorResponse } from 'react-router';
import { _primaryPurple } from 'lib/colors';
import { Platform } from 'types/platform';
import { ellipsize } from 'lib/string';
import { Community } from 'types/group';
import { message } from 'antd';
import './style.scss';

interface Props {
  onlySaved?: boolean;
}

export default function Communities(props: Props) {
  const axiosPublic = useAxiosPublic();
  const axiosAuth = useAxiosAuth();

  const [groups, setGroups] = useState<Community[] | null>(null);
  const [selectedGroup, setSelectedGroup] = useState<Community | null>(null);
  const [totalGroups, setTotalGroups] = useState(0);

  // Misc data
  const [versions, setVersions] = useState<LiveVersions | null>(null);
  const [dstCharacters, setDSTCharacters] = useState<DSTCharacter[] | null>(null);
  const [platforms, setPlatforms] = useState<Platform[] | null>(null);

  useEffect(() => {
    const info = {
      paginateInfo: {
        page: 1,
        pageSize: 50,
      },
    };

    if (props.onlySaved) fetchSavedCommunities(info);
    else fetchCommunitiesData(info);
  }, [props.onlySaved]);

  useEffect(() => {
    fetchMiscServerData();
  }, []);

  const fetchCommunitiesData = async (paginatedSearchInfo: PaginatedSearchInfo) => {
    let res = await axiosPublic.post<never, GetCommunitiesResponse | ErrorResponse>(
      apiURL('/communities'),
      { paginatedSearchInfo }
    );
    if (isErrorResponse(res)) {
      return;
    }

    setGroups(res.communities);
    setTotalGroups(res.totalRows);
  };

  const fetchSavedCommunities = async (paginatedSearchInfo: PaginatedSearchInfo) => {
    let res = await axiosAuth.post<never, GetCommunitiesResponse | ErrorResponse>(
      apiURL('/communities/saved'),
      { paginatedSearchInfo }
    );

    if (isErrorResponse(res)) {
      message.error('There was an issue fetching saved servers');
      return;
    }

    setGroups(res.communities);
    setTotalGroups(res.totalRows);
  };

  const fetchMiscServerData = async () => {
    let res = await axiosPublic.get<never, MiscServerDataResponse | ErrorResponse>(
      getMiscServerDataRoute()
    );
    if (isErrorResponse(res)) {
      return;
    }

    setVersions(res.liveVersions);
    setDSTCharacters(res.dstCharacters);
    setPlatforms(res.platforms);
  };

  const columns = (): CustomColumnType<Community>[] => [
    {
      title: 'Group Name',
      key: 'name',
      dataIndex: 'name',
      searchable: {
        dbColumnName: 'name',
      },
      render: (name: string, community: Community) => {
        if (community.subscriptionStatus === 'active') {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <RocketIcon
                style={{ fontSize: '20px', color: _primaryPurple, marginRight: '5px' }}
              />
              {name}
            </div>
          );
        }

        return name;
      },
    },
    {
      title: 'Description',
      key: 'description',
      dataIndex: 'description',
      render: (description: string) => ellipsize(description, 75),
    },
    {
      title: 'Primary Region',
      key: 'region',
      dataIndex: 'region',
      searchable: {
        dbColumnName: 'region',
        exactValue: true,
        filterOptions: CommunityRegions.map((r) => ({ value: r, text: r })),
      },
    },
  ];

  return (
    <section id="communities" className="communities">
      {!props.onlySaved && <h1>DST Communities</h1>}
      <CustomTable
        rowKey={(record: Community) => record.id}
        loadTableData={(pag: PaginatedSearchInfo) => {
          if (props.onlySaved) fetchSavedCommunities(pag);
          else fetchCommunitiesData(pag);
        }}
        totalRows={totalGroups}
        className="clickable"
        rowClassName={(record: Community) =>
          record.subscriptionStatus === 'active' ? 'active-subscription' : ''
        }
        rowHoverable={false}
        columns={columns()}
        dataSource={groups || []}
        onRow={(record: Community) => {
          return {
            onClick: () => setSelectedGroup(record),
          };
        }}
      />
      <CommunityModal
        community={selectedGroup}
        setGroup={setSelectedGroup}
        versions={versions}
        dstCharacters={dstCharacters}
        platforms={platforms}
      />
    </section>
  );
}
