import React, { useContext, useState } from 'react';
import { Button, Checkbox, Col, Input, message, Modal, Row } from 'antd';
import { isErrorResponse, useAxiosAuth } from 'api/api';
import { UserContext } from 'context/UserContext';
import { OnChangeType } from 'types/unions';
import { CreateGroupRequest, createGroupRoute } from 'services/groups';
import { GroupMemberViewModel, GroupType } from 'types/group';
import { setSelectedGroupID } from 'lib/local_storage';
import { Link } from 'react-router-dom';

interface Props {
  groupTypes: GroupType[];
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setGroupTypes: React.Dispatch<React.SetStateAction<GroupType[]>>;
}

export default function CreateGroupModal(props: Props) {
  const [groupName, setGroupName] = useState('');
  const [isPublic, setIsPublic] = useState(true);

  const axiosAuth = useAxiosAuth();
  const { authUser, setAuthUser } = useContext(UserContext);
  if (!authUser) return null;

  const footer = () => {
    return (
      <Row className="modal-footer" justify={'end'}>
        <Col>
          <Button type="primary" onClick={createGroup}>
            Create Group
          </Button>
        </Col>
      </Row>
    );
  };

  const createGroup = async () => {
    if (groupName === '') {
      message.error('Your group needs a name!');
      return;
    }

    let groupReq: CreateGroupRequest = {
      name: groupName,
      isPublic: isPublic,
    };

    let res = await axiosAuth.post<never, { group: GroupMemberViewModel }>(
      createGroupRoute(),
      groupReq
    );
    if (isErrorResponse(res)) {
      message.error(res.data, 5);
      return;
    }

    // Load group
    let g = res.group;
    message.success(`Group created. Switched to group ${g.groupName}`);
    setSelectedGroupID(g.groupID);
    setAuthUser((prev) => {
      if (prev) return { ...prev, selectedGroup: g };
      return null;
    });

    closeModal();
  };

  const closeModal = () => {
    setGroupName('');
    props.setVisible(false);
  };

  return (
    <Modal
      title={<h2>Create a Group</h2>}
      onCancel={closeModal}
      width={1400}
      open={props.visible}
      footer={footer()}
    >
      <Row>
        <Col span={8}>
          <h3>Group Name</h3>
          <Input
            value={groupName}
            onChange={(e: OnChangeType) => setGroupName(e.target.value)}
            placeholder="ie. Best DST group"
          />
        </Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col span={20}>
          <h3>Are you a public community?</h3>
          <p>
            Public communities are DST groups that have a public presence whether it's on Discord,
            Steam, Twitch, or somewhere else. If you make your group public you'll show up on the{' '}
            <Link to="/communities" target="_blank">
              communities
            </Link>{' '}
            page. Here anyone will be able to see your group name, description, links to your
            community, and even the servers you host (requires KleiID validation). Any group members
            and servers you track (along with their data) is never publicly accessible. This setting
            can be updated later.
          </p>
        </Col>
        <Col span={24}>
          <Checkbox checked={isPublic} onChange={(e) => setIsPublic(e.target.checked)}>
            I want my group to be public
          </Checkbox>
        </Col>
      </Row>
    </Modal>
  );
}
