import { ErrorResponse } from '@remix-run/router';
import { apiURL, useAxiosPublic } from 'api/api';
import { getSelectedGroupID } from 'lib/local_storage';
import { createContext } from 'react';
import { User } from 'types/user';

interface IUserContext {
  authUser: User | null;
  setAuthUser: React.Dispatch<React.SetStateAction<User | null>>;

  // Original user gets stored here while the user being impersonated is stored in authUser
  impersonatedUser: User | null;
  setImpersonatedUser: React.Dispatch<React.SetStateAction<User | null>>;
}

interface RefreshSessionRequest {
  groupID: string | null;
}
interface RefreshSessionResponse {
  user: User | null;
  impersonatedUser: User | null;
}

export const useSessionRefresh = () => {
  const axiosPublic = useAxiosPublic();

  const fetchSession = async () => {
    let data: RefreshSessionRequest = {
      groupID: getSelectedGroupID(),
    };
    return await axiosPublic.post<never, RefreshSessionResponse | ErrorResponse>(
      apiURL('/auth/session'),
      data
    );
  };

  return { fetchSession };
};

export const UserContext = createContext<IUserContext>({
  authUser: null,
  setAuthUser: () => {},
  impersonatedUser: null,
  setImpersonatedUser: () => {},
});
