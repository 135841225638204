import { useContext, useEffect, useState } from 'react';
import { isErrorResponse, useAxiosAuth } from 'api/api';
import { UserContext } from 'context/UserContext';
import { useAuthCheck } from 'hooks/use_auth_check';
import {
  GetTrackedServerResponse,
  ServerRequest,
  getTrackedServersRoute,
  stopTrackingServerRoute,
} from 'services/servers';
import { Server } from 'types/server';
import { useNavigate } from 'react-router-dom';
import CustomTable, { CustomColumnType } from 'components/shared/CustomTable';
import { Button, Popconfirm, message } from 'antd';
import { CancelIcon } from 'assets/SVGIcons/Icons';

export default function TrackedServers() {
  useAuthCheck(false);
  const { authUser } = useContext(UserContext);
  const axiosAuth = useAxiosAuth();
  const navigate = useNavigate();
  const [trackedServers, setTrackedServers] = useState<Server[]>([] as Server[]);

  useEffect(() => {
    if (authUser?.selectedGroup) fetchTrackedServers();
  }, [authUser]);

  const fetchTrackedServers = async () => {
    let res = await axiosAuth.get<never, GetTrackedServerResponse>(getTrackedServersRoute());
    if (isErrorResponse(res)) {
      return;
    }
    setTrackedServers(res.trackedServers);
  };

  const columns: CustomColumnType<Server>[] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Host',
      dataIndex: 'host',
      key: 'host',
    },
    {
      title: '',
      key: 'actions',
      width: 50,
      render: (record: Server) => (
        <Popconfirm
          placement="left"
          title={'Are you sure you want to stop tracking this server?'}
          onConfirm={(e) => {
            e?.stopPropagation();
            handleStopTrackServer(record);
          }}
          trigger="click"
        >
          <Button type='link' icon={<CancelIcon style={{ color: 'red', fontSize: '24px' }} />} onClick={(e) => e.stopPropagation()} />
        </Popconfirm>
      ),
    },
  ];

  const handleStopTrackServer = async (server: Server) => {
    let res = await axiosAuth.post<never, { serverID: string }>(stopTrackingServerRoute(), {
      name: server.name,
      host: server.host,
      platformID: server.platformID,
    } as ServerRequest);
    if (isErrorResponse(res)) {
      message.error(res.data);
      console.warn(res.data);
      return;
    }

    message.success('Removed server from tracked list', 1);
    setTimeout(() => setTrackedServers((prev) => prev.filter((p) => p.id !== res.serverID)), 200);
  };

  return (
    <section>
      <CustomTable
        rowKey={(record: Server) => record.id}
        rowClassName="clickable"
        columns={columns}
        onRow={(server: Server) => {
          return {
            onClick: () => navigate(`/trackedservers/${server.id}`),
          };
        }}
        dataSource={trackedServers}
        size="middle"
      />
    </section>
  );
}
