import { useContext, useEffect, useState } from 'react';
import { CaretRightOutlined } from '@ant-design/icons';
import { Col, Collapse, CollapseProps, Row } from 'antd';
import { isErrorResponse, useAxiosPublic } from 'api/api';
import { DiscordIcon, IconmonstrIcon, KleiIcon } from 'assets/SVGIcons/Icons';
import ExternalLink from 'components/shared/ExternalLink';
import GroupTypeCard from 'components/shared/GroupTypeCard';
import { Link } from 'react-router-dom';
import { GetGroupTypesResponse, getFeaturedGroupTypesRoute } from 'services/grouptypes';
import { GroupType } from 'types/group';
import { isDarkTheme } from 'lib/local_storage';
import { UserContext } from 'context/UserContext';

export default function About() {
  const { authUser } = useContext(UserContext);
  const axiosPublic = useAxiosPublic();
  const [groupTypes, setGroupTypes] = useState<GroupType[]>([] as GroupType[]);

  useEffect(() => {
    fetchGroupTypes();
  }, []);

  const fetchGroupTypes = async () => {
    let res = await axiosPublic.get<never, GetGroupTypesResponse>(getFeaturedGroupTypesRoute());
    if (isErrorResponse(res)) {
      return;
    }
    setGroupTypes(res.groupTypes);
  };

  const renderFeaturedServers = () => {
    return (
      <Row gutter={[8, 8]}>
        {groupTypes.map((groupType) => (
          <GroupTypeCard groupType={groupType} />
        ))}
      </Row>
    );
  };

  let items: CollapseProps['items'] = [
    {
      key: '1',
      label: <b>See our featured group plans</b>,
      children: renderFeaturedServers(),
    },
  ];

  const blackOrWhite = () => {
    return isDarkTheme(authUser) ? 'white' : 'black';
  };

  return (
    <Row className="content">
      <Col span={20}>
        <h1>About This Site</h1>
        <h2>About Me</h2>
        <p>
          Hello World 👋! I'm <span className="goldenText">X-lem</span>, a senior admin over at the
          DST group{' '}
          <ExternalLink url="https://discord.gg/4pY4asf" text="DST Survivors">
            <DiscordIcon style={{ color: '#5865f2', fontSize: '1rem', marginLeft: '5px' }} />
          </ExternalLink>
          .
        </p>
        <p>
          Our group hosts a large number of{' '}
          <ExternalLink
            text="Don't Starve Together"
            url="https://www.klei.com/games/dont-starve-together"
          >
            <KleiIcon style={{ color: '#fd6531', fontSize: '1rem', marginLeft: '2px' }} />
          </ExternalLink>{' '}
          servers and I host several of them. Several of the hosters use{' '}
          <ExternalLink url="https://dstserverlist.appspot.com/" text="dstserverlist" /> to check
          our servers. While it's a very usful application, it's limited in what I wanted to use it
          for. I wanted to be able to look at all the servers I host in a glance. I also wanted to
          track all of our servers to see which were popular, how much they were being played on,
          and which players were the most active. I have been tracking our own for a while now.
          However, I figured that if I wanted to know all this information surely other dedicated
          server hosters would too. Thus I began to work on launching this site.
        </p>
        <p>
          I started this project back in 2019 and have slowly been working on it since (I have a
          full time job and other stuff going on okay). After a ton of time spent on this project
          including an entire rewrite (including a change in programming languages) I'm finally
          ready to release it to the world. This is a passion project for me, so if you find the
          site useful feel free to <Link to={'/info?tab=support'}>support me</Link>!
        </p>
        <h2>What can this site do?</h2>
        <p>Lots of things! Here's a list of what you can do with various levels of accounts.</p>

        <h3>Public</h3>
        <p>
          If you don't want to create a free account no worries! You can still view all of the
          current live DST servers. You can even click on them to bring up more data like who
          exactly is playing, what season it is, do they have a related steam group, etc!
        </p>
        <p>
          You can also view a list of all the DST communities that have signed up on our site.
          Looking for a DST group in a specific region of the world. Search for them here!
        </p>

        <h3>With an account</h3>
        <p>
          If you decide to create a free account you can <i>save</i> servers. Maybe there's a list
          of servers you want to see at a glance. Saving a server allows you to select any number of
          servers to add to your list! Think of them as <i>pinned</i>. With an account you can also
          get invited to be apart of groups (below).
        </p>

        <h3>With a public group</h3>
        <p>
          If you manage a public DST community and you want to list it on our site for others to
          find you can do so for free. Just create an account and create a group and make sure you
          select the Public Group type.
        </p>

        <h3>Server analysis</h3>
        <p>
          When you create a group you can can select a group type that will allow you to track the
          historical data of servers. These group types have a monthly cost. Let's say you host a
          server called <i>My Awesome Server</i>. Maybe you want to know how often it's played on,
          by who and how long they play for. From the moment you start tracking the server we'll
          start collecting data on it. We take data points every 8 minutes because an in game day
          lasts 8 minutes!
        </p>
        <p>Here's some of the data we track:</p>
        <ul>
          <li>General data (day, player count, season, etc)</li>
          <li>The active players (those not in the lobby)</li>
          <li>
            Game session (which can be used to determine when a server was regenerated for example)
          </li>
        </ul>
        <p>
          Groups have various monthly costs depending upon how many servers you want to track and
          how many people you want to be able to access this data. If you're apart of a group you
          won't see any ads. Thank you for supporting the project!
        </p>
        {groupTypes.length > 0 && (
          <p>
            <Collapse
              bordered={false}
              size="small"
              expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
              items={items}
            />
          </p>
        )}

        <h2>Special Thanks</h2>
        <p>
          A special thank you to the following individuals who helped make this project possible:
        </p>
        <ul>
          <li>
            <ExternalLink
              url="https://forums.kleientertainment.com/profile/506701-nome/"
              text="nome"
            />{' '}
            and{' '}
            <ExternalLink
              url="https://forums.kleientertainment.com/profile/552758-carlzalph/"
              text="CarlZalph"
            />{' '}
            for their assistance in getting me started with this project
          </li>
          <li>
            <ExternalLink url="https://iconmonstr.com/" text="iconmonstr">
              <IconmonstrIcon style={{ marginLeft: '5px', color: blackOrWhite() }} />
            </ExternalLink>
            for the use of the beautiful icons
          </li>
        </ul>
      </Col>
    </Row>
  );
}
