import { useMemo, useState } from 'react';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import { UserContext } from './context/UserContext';
import { GetRoutes } from './routes/routes';
import { _primaryPurple } from 'lib/colors';
import Root from 'components/Navigation';
import { ConfigProvider } from 'antd';
import { User } from './types/user';
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localizedFormat from 'dayjs/plugin/localizedFormat';
import advancedFormat from 'dayjs/plugin/advancedFormat'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import localeData from 'dayjs/plugin/localeData'
import isBetween from 'dayjs/plugin/isBetween';
import weekYear from 'dayjs/plugin/weekYear'
import weekday from 'dayjs/plugin/weekday'
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs'

import './scss/App.scss';

dayjs.extend(customParseFormat)
dayjs.extend(localizedFormat);
dayjs.extend(advancedFormat)
dayjs.extend(quarterOfYear)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(isBetween)
dayjs.extend(weekYear)
dayjs.extend(weekday)
dayjs.extend(utc);

export default function App() {
  const [authUser, setAuthUser] = useState<User | null>(null);
  const [impersonatedUser, setImpersonatedUser] = useState<User | null>(null);
  const providerValues = useMemo(
    () => ({ authUser, setAuthUser, impersonatedUser, setImpersonatedUser }),
    [authUser, setAuthUser, impersonatedUser, setImpersonatedUser]
  );

  const router = createBrowserRouter([
    {
      path: '/',
      element: <Root />,
      children: GetRoutes(),
      // errorElement: <RedirectTo404 />,
      errorElement: <Navigate to={'/404'} replace={false} />,
    },
  ]);

  return (
    <div id="dst-app" className="App">
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: _primaryPurple,
            borderRadius: 8,
            fontFamily: 'Open Sans, -apple-system',
            colorLink: _primaryPurple,
            colorLinkHover: _primaryPurple,
            colorLinkActive: _primaryPurple,
            colorBgSpotlight: _primaryPurple, // Tooltip background
          },
        }}
      >
        <UserContext.Provider value={providerValues}>
          <RouterProvider router={router} />
        </UserContext.Provider>
      </ConfigProvider>
    </div>
  );
}
