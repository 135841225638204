import { User } from "types/user";

export function getMenuPosition() {
  const isMenuOpen = localStorage.getItem('isMenuOpen');
  if (isMenuOpen === 'true') return false;
  return true;
}

export function setMenuPosition(isMenuOpen: boolean) {
  localStorage.setItem('isMenuOpen', `${isMenuOpen}`);
}

export function isDarkTheme(authUser: User | null) {
  if (!authUser) return false
  const isDarkTheme = localStorage.getItem('isDarkTheme');
  if (isDarkTheme === 'true') return true;
  return false;
}

const SELECTED_GROUP_KEY = 'selectedGroup';
export function getSelectedGroupID() {
  return localStorage.getItem(SELECTED_GROUP_KEY);
}

export function setSelectedGroupID(uuid: string) {
  localStorage.setItem(SELECTED_GROUP_KEY, uuid);
}

export function deleteSelectedGroupID() {
  localStorage.removeItem(SELECTED_GROUP_KEY)
}

export function getDefaultSavedTab() {
  const defaultSavedTab = localStorage.getItem('defaultSavedTab');
  if (defaultSavedTab) return defaultSavedTab;
  return 'saved-servers';
}

export function setDefaultSavedTab(defaultSavedTab: string) {
  localStorage.setItem('defaultSavedTab', defaultSavedTab);
}