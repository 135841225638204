import { useEffect } from 'react'
import { message } from 'antd';
import { apiURL, isErrorResponse, useAxiosPublic } from 'api/api';
import { useNavigate, useSearchParams } from 'react-router-dom';

export default function EmailConfirmation() {
  const [getParams] = useSearchParams();
  const axiosPublic = useAxiosPublic();
  const navigate = useNavigate();

  useEffect(() => {
    confirmEmail()
  }, [])

  const confirmEmail = async () => {
    let userUUID = getParams.get('uid');
    let token = getParams.get('token');

    let res = await axiosPublic.post(apiURL('/users/confirm-email'), {
      userUUID,
      hexToken: token,
    });

    if (isErrorResponse(res)) {
      if (res.data === '') message.error('there was an issue verifying your email');
      else message.error(res.data);
    }
    message.success('Email confirmed');
    navigate('/', { replace: true })
  }

  return null;
}
