import { apiURL } from 'api/api';
import { DSTCharacter } from 'types/dstcharacter';
import { LiveServer, LiveVersions } from 'types/liveserver';
import { Platform } from 'types/platform';

export interface LiveServersResponse {
  liveServers: LiveServer[];
  totalServers: number
}

export const getLiveServersRoute = () => apiURL('/liveservers');

export interface LiveServerResponse {
  liveServer: LiveServer;
}

export const getLiveServerRoute = (lobby: string, rowID: string) =>
  apiURL(`/liveservers/${lobby}/${rowID}`);

export interface MiscServerDataResponse {
  liveVersions: LiveVersions;
  platforms: Platform[];
  dstCharacters: DSTCharacter[];
}

export const getMiscServerDataRoute = () => apiURL('/liveservers/miscdata');
