import { useContext } from 'react';
import { UserContext } from 'context/UserContext';
import { ExclamationCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { Group } from 'types/group';
import { Col, Alert } from 'antd';
import { Server } from 'types/server';
import dayjs from 'dayjs';

export default function GroupWarnings({
  group,
  trackedServers,
}: {
  group: Group | null;
  trackedServers: Server[];
}) {
  const { authUser } = useContext(UserContext);

  const displaySubscriptionEndWarning = () => {
    if (!group || !group.subscriptionEnds.Valid) return null;

    const now = dayjs().utc();
    const subscriptionEnds = dayjs.utc(group.subscriptionEnds.Time);

    if (
      group.groupTypeID !== 1 &&
      now.isBetween(subscriptionEnds.subtract(7, 'day'), subscriptionEnds)
    ) {
      return (
        <Col span={24}>
          <Alert
            type="error"
            icon={<ExclamationCircleOutlined style={{ fontSize: '18px' }} />}
            showIcon
            message={
              <>
                Your subscription is ending on {subscriptionEnds.local().format('LLL')}! Click on{' '}
                <i>Manage Subscription</i> if you want to continue tracking servers.
              </>
            }
          />
        </Col>
      );
    }

    return null;
  };

  const displayOverLimitWarning = () => {
    if (!group) return null;

    let overMemberLimit = false;
    if (group.groupType.memberLimit !== null)
      overMemberLimit = group.groupMembers.length > group.groupType.memberLimit;

    let overTrackedLimit = false;
    if (group.groupType.trackedServerLimit !== null)
      overTrackedLimit = trackedServers.length > group.groupType.trackedServerLimit;

    let text = '';
    let endText =
      'Please ensure you are within your limit or the system will automatically remove them for you.';

    if (overMemberLimit && overTrackedLimit)
      text = 'You are tracking more servers and have more members than your alloted limit.';
    else if (overTrackedLimit) text = 'You are tracking more servers than your alloted limit.';
    else if (overMemberLimit) text = 'You have more members than your alloted limit.';

    if (text !== '')
      return (
        <Col span={24}>
          {
            <Alert
              message={`${text} ${endText}`}
              type="warning"
              icon={<ExclamationCircleOutlined style={{ fontSize: '18px' }} />}
              showIcon
            />
          }
        </Col>
      );

    return null;
  };

  const displayNoLinksWarning = () => {
    if (!group || !group.isPublic || !authUser) return null;
    if (group.groupLinks.length > 0 || authUser.id !== group.ownerUserID) return null;

    return (
      <Col span={24}>
        <Alert
          type="info"
          showIcon
          icon={<WarningOutlined style={{ fontSize: '18px' }} />}
          closable
          message="Your group is listed as a public community, but you have not added any links. Click the gear icon below to add some"
        />
      </Col>
    );
  };

  return (
    <>
      {displaySubscriptionEndWarning()}
      {displayOverLimitWarning()}
      {displayNoLinksWarning()}
    </>
  );
}
