import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import * as SVG from './Svg';

export const ServerIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={SVG.Server} {...props} />
);

export const StatisticsIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={SVG.Statistics} {...props} />
);

export const UserIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={SVG.User} {...props} />
);

export const QuestionMarkIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={SVG.QuestionMark} {...props} />
);

export const OutdatedIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={SVG.RewindClock} {...props} />
);

export const BetaIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={SVG.PotionFlask} {...props} />
);

export const DedicatedServerIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={SVG.ServerCoud} {...props} />
);

export const HostedServerIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={SVG.Computer} {...props} />
);

export const PasswordProtectedIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={SVG.Lock} {...props} />
);

export const ExternalLinkIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={SVG.OutgoingLink} {...props} />
);

export const ServerPausedIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={SVG.PauseSymbol} {...props} />
);

export const AutumnIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={SVG.Leaf} {...props} />
);

export const WinterIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={SVG.SnowClouds} {...props} />
);

export const SpringIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={SVG.RainClouds} {...props} />
);

export const SummerIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={SVG.Sun} {...props} />
);

export const SettingsIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={SVG.ThickHollowGear} {...props} />
);

export const SavedServersIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={SVG.BookmarkList} {...props} />
);

export const TrackedServersIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={SVG.BarGraphUp} {...props} />
);

export const GroupIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={SVG.TwoPeople} {...props} />
);

export const InfinityIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={SVG.InfinitySymbol} {...props} />
);

export const CheckedBoxIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={SVG.CheckedBox} {...props} />
);
export const UncheckedBoxIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={SVG.UncheckedBox} {...props} />
);

export const AboutIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={SVG.Info} {...props} />
);

export const DiscordIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={SVG.DiscordLogo} {...props} />
);

export const SteamIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={SVG.SteamLogo} {...props} />
);

export const TwitchIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={SVG.TwitchLogo} {...props} />
);

export const KleiIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={SVG.KleiLogo} {...props} />
);

export const BitcoinIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={SVG.BitcoinLogo} {...props} />
);

export const StarFilledIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={SVG.StarFilled} {...props} />
);

export const BadgeIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={SVG.AwardRibbon} {...props} />
);

export const IconmonstrIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={SVG.Iconmonstr} {...props} />
);

export const HeartIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={SVG.Heart} {...props} />
);

export const HeartFilledIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={SVG.HeartFilled} {...props} />
);

export const LinkIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={SVG.Link} {...props} />
);

export const RailwayIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={SVG.Railway} {...props} />
);

export const BinocularsIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={SVG.Binoculars} {...props} />
);

export const CancelIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={SVG.XMark} {...props} />
);

export const EmptyBoxIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={SVG.DashedBox} {...props} />
);

export const RocketIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={SVG.Rocket} {...props} />
);
