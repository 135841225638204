import { ServerStatistics } from 'types/serverhistory';
import { ResponsiveBar } from '@nivo/bar';
import { useEffect, useState } from 'react';
import { _primaryPurple } from 'lib/colors';
import { formatNumber } from 'lib/number';

const Bar: React.FC<{ statistics: ServerStatistics }> = ({ statistics }) => {
  const [data, setData] = useState<any>();

  useEffect(() => {
    formatData();
  }, [statistics]);

  const formatData = () => {
    let d = [
      {
        label: 'Total live',
        Dedicated: formatNumber(statistics.totalDedicated, 2),
        Hosted: formatNumber(statistics.totalLive - statistics.totalDedicated, 2),
      },
      {
        label: 'Total active servers',
        Dedicated: formatNumber(statistics.totalActiveDedicated, 2),
        Hosted: formatNumber(statistics.totalActive - statistics.totalActiveDedicated, 2),
      },
      {
        label: 'Total active players',
        Dedicated: formatNumber(statistics.totalPlayersDedicated, 2),
        Hosted: formatNumber(statistics.totalPlayers - statistics.totalPlayersDedicated, 2),
      },

      {
        label: 'Total modded',
        Dedicated: formatNumber(statistics.totalModdedDedicated, 2),
        Hosted: formatNumber(statistics.totalModded - statistics.totalModdedDedicated, 2),
      },
      {
        label: 'Total PVP',
        Dedicated: formatNumber(statistics.totalPVPDedicated, 2),
        Hosted: formatNumber(statistics.totalPVP - statistics.totalPVPDedicated, 2),
      },
    ];

    setData(d.reverse());
  };

  return (
    <div style={{ height: '500px', width: '90%' }}>
      <ResponsiveBar
        data={data}
        keys={['Dedicated', 'Hosted']}
        indexBy="label"
        groupMode="stacked"
        layout="horizontal"
        colors={['#8c78ff', '#a9a1ff']}
        margin={{ top: 50, right: 130, bottom: 50, left: 80 }}
        padding={0.3}
        labelSkipWidth={25}
        labelTextColor="black"
        axisTop={{
          tickSize: 5,
          tickPadding: 5,
          legendPosition: 'middle',
        }}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          legendPosition: 'middle',
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
        }}
        legends={[
          {
            dataFrom: 'keys',
            anchor: 'right',
            direction: 'column',
            justify: false,
            translateX: 150,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            symbolSize: 20,
          },
        ]}
        tooltip={(d: any) => {
          // If it's 0 it won't exist...
          return (
            <div className="bar-tooltip">
              <div className="title">{d.data.label}</div>
              <div>
                <span>Dedicated:</span> {d.data.Dedicated || 0}
              </div>
              <div>
                <span>Hosted:</span> {d.data.Hosted || 0}
              </div>
              <div>
                <span>Total:</span>{' '}
                {formatNumber(Number(d.data.Dedicated || 0) + Number(d.data.Hosted || 0), 2)}
              </div>
            </div>
          );
        }}
      />
    </div>
  );
};

export default Bar;
