import React, { useContext, useEffect, useState } from 'react';
import { ErrorResponse } from 'react-router';
import { apiURL, isErrorResponse, useAxiosAuth, useAxiosPublic } from 'api/api';
import { Button, Col, Modal, Row, Table, Tabs, Tooltip, message } from 'antd';
import { DiscordIcon, TwitchIcon, SteamIcon, BadgeIcon, RocketIcon } from 'assets/SVGIcons/Icons';
import ServerModal from 'components/ServerTable/ServerModal';
import { GetCommunityServerResponse } from 'services/groups';
import { LiveServer, LiveVersions } from 'types/liveserver';
import ExternalLink from 'components/shared/ExternalLink';
import { DSTCharacter } from 'types/dstcharacter';
import { UserContext } from 'context/UserContext';
import { _primaryPurple } from 'lib/colors';
import { Platform } from 'types/platform';
import { Community } from 'types/group';
import { User } from 'types/user';

interface Props {
  community: Community | null;
  setGroup: React.Dispatch<React.SetStateAction<Community | null>>;

  // Props for ServerModal
  versions: LiveVersions | null;
  dstCharacters: DSTCharacter[] | null;
  platforms: Platform[] | null;
}

export default function CommunityModal(props: Props) {
  const { authUser, setAuthUser } = useContext(UserContext);
  const axiosPublic = useAxiosPublic();
  const axiosAuth = useAxiosAuth();

  const [liveServers, setLiveServers] = useState<LiveServer[] | null>(null);
  const [selectedServer, setSelectedServer] = useState<LiveServer | null>(null);

  useEffect(() => {
    fetchGroupLiveServers();
  }, [props.community]);

  const fetchGroupLiveServers = async () => {
    if (!props.community) return;

    let res = await axiosPublic.get<never, GetCommunityServerResponse | ErrorResponse>(
      apiURL(`/communities/${props.community.id}`)
    );
    if (isErrorResponse(res)) {
      message.error('Could not fetch community servers');
      return;
    }

    setLiveServers(res.liveServers);
  };

  const columns = () => {
    const columns = [
      {
        title: 'Server Name',
        key: 'name',
        dataIndex: 'name',
        width: 250,
      },
      {
        title: 'Day',
        key: 'day',
        dataIndex: 'day',
        width: 75,
      },
      {
        title: 'Season',
        key: 'season',
        dataIndex: 'season',
        width: 100,
      },
      {
        title: 'Mode',
        key: 'mode',
        dataIndex: 'mode',
        width: 100,
        searchable: {
          dbColumnName: 'mode',
        },
      },
      {
        title: 'Players',
        key: 'playerCount',
        dataIndex: 'playerCount',
        render: (playerCount: string, record: LiveServer) => `${playerCount}/${record.playerMax}`,
        width: 50,
      },
    ];

    return columns;
  };

  const getLinkIcon = (linkType: string) => {
    switch (linkType) {
      case 'Discord':
        return <DiscordIcon style={{ color: '#5865f2', fontSize: '1rem', marginLeft: '5px' }} />;
      case 'Twitch':
        return <TwitchIcon style={{ color: '#9146FF', fontSize: '1rem', marginLeft: '5px' }} />;
      case 'Steam':
        return <SteamIcon style={{ color: 'black', fontSize: '1rem', marginLeft: '5px' }} />;
    }
  };

  const tabs = [
    {
      key: 'live-servers',
      label: 'Live Servers',
      children: (
        <Table
          columns={columns()}
          dataSource={liveServers || []}
          onRow={(record: LiveServer) => {
            return {
              onClick: () => setSelectedServer(record),
            };
          }}
          pagination={{
            hideOnSinglePage: true,
            showSizeChanger: false,
          }}
        />
      ),
    },
    {
      key: 'community-links',
      label: 'Community Links',
      children: props.community && (
        <>
          <h3>Links</h3>
          {props.community.groupLinks.length === 0 ? (
            <>This community has no links 🤷‍♀️</>
          ) : (
            <Row gutter={[8, 8]}>
              {props.community.groupLinks.map((l) => (
                <Col key={l.id} span={24}>
                  <ExternalLink children={getLinkIcon(l.linkType)} url={l.link} text={l.link} />
                </Col>
              ))}
            </Row>
          )}
        </>
      ),
    },
  ];

  const handleSaveCommunity = async () => {
    if (!props.community) return;

    let res = await axiosAuth.post<never, ErrorResponse>(apiURL('/communities/save'), {
      groupID: props.community.id,
    });
    if (isErrorResponse(res)) {
      message.error(res.data);
      console.warn(res.data);
      return;
    }

    message.success('Community saved!');
    setAuthUser((prev) => {
      if (!prev) return prev;
      return {
        ...prev,
        savedCommunities: prev.savedCommunities
          ? [...prev.savedCommunities, props.community]
          : [props.community],
      } as User;
    });
  };

  const handleUnsaveCommunity = async () => {
    if (!props.community) return;

    let res = await axiosAuth.post<never, ErrorResponse>(apiURL('/communities/remove-save'), {
      groupID: props.community.id,
    });
    if (isErrorResponse(res)) {
      message.error(res.data);
      console.warn(res.data);
      return;
    }

    message.success('Saved community removed!');
    setAuthUser(
      (prev) =>
        prev && {
          ...prev,
          savedCommunities:
            prev.savedCommunities?.filter((c) => c.id !== props.community?.id) || null,
        }
    );
  };

  const footer = () => {
    if (!authUser) return null;
    const isCommunitySaved = authUser.savedCommunities?.some((c) => c.id === props.community?.id);
    console.log('isCommunitySaved', isCommunitySaved);
    return (
      <Row justify="end">
        <Col>
          <Button
            type="primary"
            danger={isCommunitySaved}
            onClick={isCommunitySaved ? handleUnsaveCommunity : handleSaveCommunity}
          >
            {isCommunitySaved ? 'Unsave Community' : 'Save Community'}
          </Button>
        </Col>
      </Row>
    );
  };

  return (
    <Modal
      className={`clickable community-modal${
        props.community?.subscriptionStatus === 'active' && ' active-subscription'
      }`}
      width={1500}
      open={!!props.community}
      onCancel={() => {
        props.setGroup(null);
        setSelectedServer(null);
      }}
      footer={footer}
    >
      <Row align="middle">
        <Col>
          <h2>{props.community?.name}</h2>
        </Col>
        {props.community?.subscriptionStatus === 'active' && (
          <Col>
            <Tooltip title="Site Supporter!" placement="top">
              <RocketIcon style={{ fontSize: '25px', color: _primaryPurple, marginLeft: '10px' }} />
            </Tooltip>
          </Col>
        )}
      </Row>
      <div className="description">{props.community?.description}</div>
      <Tabs size="small" items={tabs} />

      {props.versions && props.dstCharacters && props.platforms && (
        <ServerModal
          server={selectedServer}
          versions={props.versions}
          dstCharacters={props.dstCharacters}
          platforms={props.platforms}
          setSelectedServer={setSelectedServer}
        />
      )}
    </Modal>
  );
}
