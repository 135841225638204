import { useEffect, useState } from 'react';
import { Col, DatePicker, message, Radio, Row, Select, Space } from 'antd';
import { apiURL, isErrorResponse, useAxiosPublic } from 'api/api';
import { ServerStatistics } from 'types/serverhistory';
import { PickerMode } from 'rc-picker/lib/interface';
import NoData from 'components/shared/NoData';
import dayjs, { Dayjs } from 'dayjs';
import Bar from './Bar';
import './style.scss';

const { RangePicker } = DatePicker;
const { Option } = Select;

export default function LiveStatistics() {
  type DisplayTypes = 'live' | 'historic';

  const today = dayjs.utc();
  const [displayType, setDisplayType] = useState<DisplayTypes>('live');
  const [type, setType] = useState<PickerMode | 'custom'>('date');
  const [date, setDate] = useState<Dayjs>(today);
  const [customEndDate, setCustomEndDate] = useState<Dayjs>(today);
  const [statistics, setStatistics] = useState<ServerStatistics | null>(null);

  const axiosPublic = useAxiosPublic();

  useEffect(() => {
    fetchLiveStatisticsData();
  }, [displayType, type, date]);

  const fetchLiveStatisticsData = async () => {
    var route = '/liveservers/statistics';
    if (displayType === 'historic') {
      const s = getStartDate().format('YYYY-MM-DD');
      let e = getEndDate().format('YYYY-MM-DD');
      if (type === 'custom') e = customEndDate.format('YYYY-MM-DD');
      route = `${route}/${s}/${e}`;
    }

    let res = await axiosPublic.get<never, { statistics: ServerStatistics }>(apiURL(route));
    if (isErrorResponse(res)) {
      message.error(res.data);
      console.warn(res.data);
      return;
    }

    console.log(res);

    if (res && res.statistics) setStatistics(res.statistics);
    else setStatistics(null);
  };

  const getStartDate = () => {
    switch (type) {
      case 'week':
        return date.startOf('week');
      case 'month':
        return date.startOf('month');
      case 'quarter':
        return date.startOf('quarter');
      case 'year':
        return date.startOf('year');
      default: // case 'date' (day):
        return date;
    }
  };

  const getEndDate = () => {
    switch (type) {
      case 'week':
        return date.endOf('week');
      case 'month':
        return date.endOf('month');
      case 'quarter':
        return date.endOf('quarter');
      case 'year':
        return date.endOf('year');
      default: // case 'date' (day):
        return date;
    }
  };

  const getStatisticsInformation = () => {
    if (!statistics) return null;

    switch (displayType) {
      case 'live':
        return (
          <>
            Live statistics recorded ~{dayjs().diff(dayjs(statistics.timestamp), 'minutes')} min(s)
            ago at {dayjs(statistics.timestamp).format('lll')}.
          </>
        );
      case 'historic':
        return '';
      default:
        return '';
    }
  };

  return (
    <section className="live-statistics">
      <h1>Live Statistics</h1>
      <Row>
        <Col span={24}>
          <Space>
            <Radio.Group
              value={displayType}
              onChange={(e) => setDisplayType(e.target.value)}
              buttonStyle="solid"
              options={[
                { value: 'live', label: 'Live' },
                {
                  value: 'historic',
                  label: 'Historic',
                  disabled: true,
                  title: '🚧 Under construction ⚒️',
                },
                {
                  value: 'advanced',
                  label: 'Advanced',
                  disabled: true,
                  title: '🚧 Under construction ⚒️',
                },
              ]}
              optionType="button"
            />
            {displayType !== 'live' && (
              <Space.Compact block>
                <Select value={type} onChange={(e) => setType(e)} style={{ width: 120 }}>
                  <Option value="date">Day</Option>
                  <Option value="week">Week</Option>
                  <Option value="month">Month</Option>
                  <Option value="quarter">Quarter</Option>
                  <Option value="year">Year</Option>
                  <Option value="custom">Custom</Option>
                </Select>
                {type === 'custom' ? (
                  <RangePicker
                    maxDate={today}
                    value={[date, customEndDate]}
                    onChange={(dates) => {
                      if (dates) {
                        if (dates[0]) setDate(dates[0]);
                        if (dates[1]) setCustomEndDate(dates[1]);
                      }
                    }}
                    allowClear={false}
                  />
                ) : (
                  <DatePicker
                    value={date}
                    allowClear={false}
                    picker={type}
                    onChange={(date: Dayjs) => setDate(date)}
                    maxDate={today}
                    showNow
                  />
                )}
              </Space.Compact>
            )}
          </Space>
        </Col>
        <Col span={24} className="explanation">
          {getStatisticsInformation()}
        </Col>
        {statistics ? (
          <Col span={24}>
            <Bar statistics={statistics} />
          </Col>
        ) : (
          <NoData />
        )}
      </Row>
    </section>
  );
}
