import { useContext, useState } from 'react';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Button, Input, message, notification, Tooltip } from 'antd';
import { apiURL, isErrorResponse, useAxiosPublic } from 'api/api';
import { QuestionMarkIcon } from 'assets/SVGIcons/Icons';
import { setSelectedGroupID } from 'lib/local_storage';
import { UserContext } from 'context/UserContext';
import { ErrorResponse } from '@remix-run/router';
import { User } from 'types/user';

interface Props {
  closeMenu: () => void;
}

const LoginRegisterPanel = (props: Props) => {
  const axiosPublic = useAxiosPublic();
  const { setAuthUser } = useContext(UserContext);

  const [section, setSection] = useState<'login' | 'register' | 'forgotPassword'>('login');

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setSasswordConfirmation] = useState('');
  const [token, setToken] = useState('');

  const renderTitle = () => {
    let title = '';

    switch (section) {
      case 'login':
        title = 'Login';
        break;
      case 'register':
        title = 'Register';
        break;
      case 'forgotPassword':
        title = 'Forgot Password';
        break;
    }

    return <p className="title">{title}</p>;
  };

  const renderQuestionMark = () => {
    return (
      <Tooltip
        placement="bottomRight"
        title={'Currently an account token is required for signing up'}
      >
        <QuestionMarkIcon style={{ fontSize: '15px' }} />
      </Tooltip>
    );
  };

  const handleSubmit = async () => {
    switch (section) {
      case 'login':
        login();
        break;
      case 'register':
        register();
        break;
      case 'forgotPassword':
        requestPasswordReset();
        break;
    }
  };

  const login = async () => {
    if (username === '' || password === '') {
      message.error('please complete form');
      return;
    }

    let res = await axiosPublic.post<never, { user: User } | ErrorResponse>(apiURL(`/auth/login`), {
      username,
      password,
    });

    if (isErrorResponse(res)) {
      message.error('Incorrect username or password');
      console.warn(res.data);
      return;
    }

    setAuthUser(res.user);
    if (res.user.selectedGroup) setSelectedGroupID(res.user.selectedGroup.groupID);

    props.closeMenu();
  };

  const register = async () => {
    if (username === '' || password === '' || passwordConfirmation === '') {
      message.error('please complete form');
      return;
    }

    let res = await axiosPublic.post<never, { user: User } | ErrorResponse>(
      apiURL(`/auth/register`),
      {
        username,
        password,
        passwordConfirmation,
        token,
      }
    );

    if (isErrorResponse(res)) {
      message.error(res.data);
      console.warn(res.data);
      return;
    }

    setAuthUser(res.user);
    props.closeMenu();
  };

  const requestPasswordReset = async () => {
    if (username === '') {
      message.error('please enter your username');
      return;
    }

    let res = await axiosPublic.post(apiURL(`auth/request-password-reset`), { username });
    if (isErrorResponse(res)) {
      message.error(res.data);
      console.warn(res.data);
      return;
    }

    setUsername('');
    props.closeMenu();
    notification.success({
      message: 'Password reset requested',
      description: 'Please check your inbox (including spam folder) for a password reset link',
      duration: 10,
    });
  };

  return (
    <div className="loginRegisterPanel">
      {renderTitle()}

      <form>
        <Input
          autoFocus
          className="form-element"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          onPressEnter={handleSubmit}
        />

        {(section === 'login' || section === 'register') && (
          <Input.Password
            className="form-element"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onPressEnter={handleSubmit}
            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          />
        )}

        {section === 'register' && (
          <>
            <Input.Password
              className="form-element"
              placeholder="Confirm password"
              value={passwordConfirmation}
              onChange={(e) => setSasswordConfirmation(e.target.value)}
              onPressEnter={handleSubmit}
              iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            />
            <Input
              className="form-element"
              maxLength={6}
              placeholder="Account Token"
              value={token}
              onChange={(e) => setToken(e.target.value)}
              onPressEnter={handleSubmit}
              addonAfter={renderQuestionMark()}
            />
          </>
        )}
        <Button onClick={handleSubmit}>
          {section === 'login' ? 'Login' : section === 'register' ? 'Register' : 'Reset Password'}
        </Button>
      </form>
      <span className="options">
        {section === 'login' ? (
          <>
            <span onClick={() => setSection('register')}>Register</span> |{' '}
            <span onClick={() => setSection('forgotPassword')}>Forgot Password</span>
          </>
        ) : (
          <span onClick={() => setSection('login')}>Login</span>
        )}
      </span>
    </div>
  );
};
export default LoginRegisterPanel;
