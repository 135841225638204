import { EmptyBoxIcon } from 'assets/SVGIcons/Icons';
import { _primaryPurple } from 'lib/colors';

export default function NoData() {
  return (
    <div
      style={{
        width: '100%',
        border: `1px solid #d3d3d3`,
        borderRadius: '15px',
        margin: '4ex 10px',
        textAlign: 'center',
        padding: '2ex',
      }}
    >
      <EmptyBoxIcon style={{ fontSize: '10ex', color: _primaryPurple }} />
      <span style={{ display: 'block', marginTop: '1ex', fontSize: '1.3em', fontWeight: '600' }}>No Data Found</span>
    </div>
  );
}
