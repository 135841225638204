import { Button } from 'antd';
import { ExternalLinkIcon } from 'assets/SVGIcons/Icons';
import { ReactNode } from 'react';

interface Props {
  children?: ReactNode;
  text?: string;
  url: string;
  cssClass?: string;
}

const ExternalLink = (props: Props) => {
  return (
      <Button
        style={{ padding: '0', height: 'auto' }}
        className={props.cssClass}
        type="link"
        target="_blank"
        rel="noopener noreferrer"
        href={props.url}
      >
        {props.text}
        {props.children}
        <ExternalLinkIcon style={{ marginLeft: '5px' }} />
      </Button>
  );
};
export default ExternalLink;
